import React, {useState} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import {ArrowUpward, ArrowDownward, SwapVert} from '@mui/icons-material';
import axiosClient from '../../api';
import useMediaQuery from '@mui/material/useMediaQuery';

const ReorderProjectOrderDialog = ({projects, setProjects, language}) => {
	const [open, setOpen] = useState(false);
	const [orderedProjects, setOrderedProjects] = useState(projects);
	const isMobileScreen = useMediaQuery('(max-width:700px)');

	const handleOpen = () => {
		setOrderedProjects([...projects]);
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	const moveProject = (index, direction) => {
		const newOrder = [...orderedProjects];
		const project = newOrder[index];
		const newIndex = direction === 'up' ? index - 1 : index + 1;

		if (newIndex >= 0 && newIndex < newOrder.length) {
			newOrder.splice(index, 1);
			newOrder.splice(newIndex, 0, project);
			setOrderedProjects(newOrder);
		}
	};

	const toggleShowOnStartPage = index => {
		const newOrder = [...orderedProjects];
		newOrder[index] = {
			...newOrder[index],
			showOnStartPage: !newOrder[index].showOnStartPage,
		};
		setOrderedProjects(newOrder);
	};

	const handleSaveOrder = async () => {
		try {
			const response = await axiosClient.put('/project/reorder', {
				projects: orderedProjects.map(project => ({
					_id: project._id,
					showOnStartPage: project.showOnStartPage,
				})),
			});

			setProjects(response.data);
			handleClose();
		} catch (error) {
			console.error('Error saving project order:', error);
		}
	};

	return (
		<>
			<Button
				onClick={handleOpen}
				variant="contained"
				color="primary"
				sx={{textTransform: 'none'}}
				startIcon={<SwapVert />}
				size="large"
			>
				Ändra visningsordning
			</Button>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" fullScreen={isMobileScreen}>
				<DialogTitle>Ändra visningsordning av projekt</DialogTitle>
				<DialogContent>
					<List>
						{orderedProjects.map((project, index) => (
							<ListItem key={project._id} divider disablePadding={isMobileScreen}>
								<ListItemText
									primary={
										language === 'sv'
											? index + 1 + '. ' + project.swedishTitle
											: index + 1 + '. ' + project.englishTitle
									}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={project.showOnStartPage}
											onChange={() => toggleShowOnStartPage(index)}
											color="primary"
										/>
									}
									label={isMobileScreen ? 'Startsida' : 'Visa på startsida'}
								/>
								<IconButton onClick={() => moveProject(index, 'up')} disabled={index === 0}>
									<ArrowUpward />
								</IconButton>
								<IconButton
									onClick={() => moveProject(index, 'down')}
									disabled={index === orderedProjects.length - 1}
								>
									<ArrowDownward />
								</IconButton>
							</ListItem>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary" sx={{textTransform: 'none'}}>
						Avbryt
					</Button>
					<Button
						onClick={handleSaveOrder}
						color="primary"
						variant="contained"
						sx={{textTransform: 'none'}}
					>
						Spara
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ReorderProjectOrderDialog;

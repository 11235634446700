import React, {useContext, useEffect, useState} from 'react';
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import api from '../../api';
import {Add, CameraAlt, Check, Clear, Delete, Edit, Visibility} from '@mui/icons-material';
import {Link, useHistory} from 'react-router-dom';
import {Autocomplete, LoadingButton} from '@mui/lab';
import {useRef} from 'react';
import {AuthContext} from '../../App';
import {isAdmin} from '../../utils/Authentication';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import {useTranslation} from 'react-i18next';
import TextEditor from '../../components/TextEditor/TextEditor';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import EditToolbar from '../../components/EditToolbar/EditToolbar';
import Pictures from '../../components/Pictures';

const EditServicePage = props => {
	const history = useHistory();
	const [contactPersonSearch, setContactPersonSearch] = useState(null);
	const [contactPersonSearchInput, setContactPersonSearchInput] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const language = localStorage.getItem('i18nextLng');
	const [photoThatIsBeingEdited, setPhotoThatIsBeingEdited] = useState('');
	const inputRef = useRef();
	const [contactPersons, setContactPersons] = useState([]);
	const authContext = useContext(AuthContext);
	const [loading, isLoading] = useState(true);
	const {t} = useTranslation();

	const [data, setData] = useState({
		_id: '',
		swedishTitle: '',
		swedishText: '',
		englishTitle: '',
		englishText: '',
		showOnStartPage: false,
		published: false,
		createdBy: '',
		createdDate: '',
		editedBy: '',
		editedDate: '',
		pictureUrl: '',
		contactPersons: [],
		referenceProjects: [],
	});
	const [persons, setPersons] = useState([]);
	useEffect(() => {
		getService();
		getPersons();
	}, []);
	const deleteContactPerson = _personId => {
		api
			.delete('/service/deletecontactperson/' + props.match.params.item_id + '/' + _personId)
			.then(() => {
				const newData = contactPersons.filter(p => p._personId !== _personId);
				setContactPersons(newData);
				const newD = [...data.contactPersons].filter(p => p._personId !== _personId);
				setData({...data, contactPersons: newD});
			})
			.catch(error => console.error(error));
	};
	const deletePhoto = photo => {
		api
			.delete('/service/deletephoto/' + props.match.params.item_id + '/' + photo._id)
			.then(() => {
				const newData = data.pictures.filter(p => p._id !== photo._id);
				setData({...data, pictures: newData});
			})
			.catch(error => console.error(error));
	};
	const editContactPerson = (_personId, newValue, attributeName) => {
		const newData = contactPersons.map(object => {
			if (object._personId === _personId) {
				return {
					...object,
					[attributeName]: newValue,
				};
			} else return object;
		});
		setContactPersons(newData);
		updateService({...data, contactPersons: newData});
	};
	const getService = () => {
		api
			.get('/service/getitembyid/' + props.match.params.item_id)
			.then(response => {
				setData(response.data.item);
				isLoading(false);
				setContactPersons(response.data.contactPersons);

				document.title = 'Redigera tjänst: ' + response.data.item.swedishTitle;
			})
			.catch(error => {
				console.error(error);
				isLoading(false);
			});
	};
	const getPersons = () => {
		api
			.get('/person/persons')
			.then(response => setPersons(response.data))
			.catch(error => console.error(error));
	};
	const handleChange = event => {
		if (event.target.value !== null) {
			if (event.target.name === 'title') {
				const newUrl = event.target.value.replaceAll(' ', '-').toLowerCase();
				setData({
					...data,
					url: newUrl,
					title: event.target.value,
				});
				updateService({...data, url: newUrl, title: event.target.value});
			}
			setData({...data, [event.target.name]: event.target.value});
			updateService({...data, [event.target.name]: event.target.value});
		}
	};
	const updateService = update => {
		api.put('/service/edit/' + data._id, update).catch(error => console.error(error));
	};
	const deleteService = () => {
		api
			.delete('/service/delete/' + data._id)
			.then(() => {
				setLoadingDelete(false);
				history.push('/tjänster');
			})
			.catch(error => {
				setLoadingDelete(false);
				console.error(error);
			});
	};
	const addNewContactPerson = newContactPerson => {
		api
			.post('/service/addnewcontactperson/' + data._id, {
				_personId: newContactPerson._id,
			})
			.then(response => {
				setData(response.data.item);
				setContactPersons(response.data.contactPersons);
			})
			.catch(error => console.error(error));
	};
	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				minHeight: '50vh',
				maxWidth: '1300px',
				padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
			}}
		>
			{loading ? (
				<LoadingComponent loadingText={'Laddar...'} />
			) : (
				<React.Fragment>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: {xs: '1rem', md: '3rem'},
							flexWrap: 'wrap',
						}}
					>
						<BreadCrumb
							mainLinkUrl={'/tjänster'}
							mainLinkWord={t('topbar.services')}
							title={language === 'sv' ? data.swedishTitle : data.englishTitle}
						/>

						<EditToolbar
							deleteItem={deleteService}
							item={data}
							setItem={setData}
							setConfirmDelete={setConfirmDelete}
							updateItem={updateService}
							createdBy={data.createdBy}
							text="tjänst"
							viewUrl={'/tjänster/' + data.url}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
					</Grid>

					<Grid item xs={12}>
						<Pictures
							item={data}
							getData={getService}
							setItem={setData}
							english={true}
							attributeName={'services'}
							updateItem={updateService}
						/>
					</Grid>
					<Grid item xs={12}>
						{data.title}
					</Grid>
					<Grid item xs={12} md={6} style={{paddingRight: '1rem'}}>
						<Grid container>
							<Grid item xs={12}>
								<FormControl margin="dense" fullWidth>
									<TextField
										label="Tjänstens namn"
										type="text"
										size="small"
										value={data.swedishTitle}
										onChange={handleChange}
										name="swedishTitle"
										id="swedishTitle"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl margin="dense" fullWidth>
									<TextEditor
										setText={newText => {
											const update = {...data, swedishText: newText};
											setData(update);
											updateService(update);
										}}
										text={data.swedishText}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} md={6}>
						<Grid container>
							<Grid item xs={12}>
								<FormControl margin="dense" fullWidth>
									<TextField
										label="Service title"
										type="text"
										size="small"
										value={data.englishTitle}
										onChange={handleChange}
										name="englishTitle"
										id="englishTitle"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl margin="dense" fullWidth>
									<TextEditor
										setText={newText => {
											const update = {...data, englishText: newText};
											setData(update);
											updateService(update);
										}}
										text={data.englishText}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl margin="dense" fullWidth>
							<TextField
								placeholder="Url"
								type="text"
								size="small"
								value={data.url}
								onChange={handleChange}
								name="url"
								id="url"
								helperText="Detta är den URL som sedan används för att länka till tjänstesidan"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6} style={{paddingLeft: '1rem'}}>
						<p
							className="hover-link"
							style={{cursor: 'pointer'}}
							onClick={() =>
								window.open(
									'https://redmanagement.se/tjänster/' + data.url,
									'_blank',
									'toolbar=0,location=0,menubar=0'
								)
							}
						>
							{'https://redmanagement.se/tjänster/' + data.url}
						</p>
					</Grid>
					<Grid item xs={12}>
						<h3>Kontaktpersoner</h3>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							value={contactPersonSearch}
							onChange={(event, newValue) => {
								if (newValue !== null) {
									setContactPersonSearchInput('');
									setContactPersonSearch(null);
									addNewContactPerson(newValue);
								}
							}}
							getOptionLabel={option => option.email}
							inputValue={contactPersonSearchInput}
							onInputChange={(event, newInputValue) => {
								setContactPersonSearchInput(newInputValue);
							}}
							id="contactPersonSearch"
							options={persons}
							renderOption={(props, option, {selected}) => (
								<ListItem alignItems="flex-start" {...props} key={option.key}>
									<ListItemAvatar>
										<Avatar alt="Remy Sharp" src={option.pictureUrl} />
									</ListItemAvatar>
									<ListItemText
										primary={option.email}
										secondary={
											<React.Fragment>
												<Typography
													sx={{display: 'inline'}}
													component="span"
													variant="body2"
													color="text.primary"
												>
													{option.firstName + ' ' + option.lastName}
												</Typography>
												{` - ${option.email}`}
											</React.Fragment>
										}
									/>
								</ListItem>
							)}
							renderInput={params => (
								<TextField {...params} variant="outlined" label="Lägg till kontaktperson..." />
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							{contactPersons.length > 0 &&
								contactPersons.map((c, index) => (
									<Grid item xs={12} key={index}>
										<Card style={{border: 'solid #4cae52 1px', margin: '1rem 0'}}>
											<CardContent style={{fontSize: '0.9rem'}}>
												<p style={{lineHeight: '3px', color: '#3EAC48', fontSize: '1.2rem'}}>
													<strong>{c.email}</strong>
												</p>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<FormControl margin="dense" fullWidth>
															<TextField
																value={c.swedishRole}
																name="swedishRole"
																onChange={event =>
																	editContactPerson(c._personId, event.target.value, 'swedishRole')
																}
																multiline
																minRows={1}
																label="Titel (sv)"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={6}>
														<FormControl margin="dense" fullWidth>
															<TextField
																value={c.englishRole}
																name="englishRole"
																onChange={event =>
																	editContactPerson(c._personId, event.target.value, 'englishRole')
																}
																multiline
																minRows={1}
																label="Title (en)"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={6}>
														<FormControl margin="dense" fullWidth>
															<TextField
																value={c.swedishDescription}
																name="swedishDescription"
																onChange={event =>
																	editContactPerson(
																		c._personId,
																		event.target.value,
																		'swedishDescription'
																	)
																}
																multiline
																minRows={3}
																label="Beskrivning av ansvarsområde (sv)"
															/>
														</FormControl>
													</Grid>
													<Grid item xs={6}>
														<FormControl margin="dense" fullWidth>
															<TextField
																value={c.englishDescription}
																name="englishText"
																onChange={event =>
																	editContactPerson(
																		c._personId,
																		event.target.value,
																		'englishDescription'
																	)
																}
																multiline
																minRows={3}
																label="Description of responsibility (en)"
															/>
														</FormControl>
													</Grid>
												</Grid>
											</CardContent>
											<CardActions>
												<LoadingButton
													variant="outlined"
													startIcon={<Clear />}
													style={{
														textTransform: 'none',
													}}
													onClick={() => deleteContactPerson(c._personId)}
												>
													Radera kontaktperson från tjänsten
												</LoadingButton>
											</CardActions>
										</Card>
									</Grid>
								))}
						</Grid>
					</Grid>
				</React.Fragment>
			)}
		</Grid>
	);
};
export default EditServicePage;

import React, {useContext, useEffect, useState} from 'react';
import {
	Button,
	Card,
	Box,
	CardActions,
	CardContent,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
	FormHelperText,
	ListItem,
	InputAdornment,
	Tooltip,
} from '@mui/material';
import api from '../../api';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {
	Add,
	CameraAlt,
	Check,
	Send,
	Clear,
	Delete,
	Edit,
	Visibility,
	AddLink,
	VisibilityOff,
} from '@mui/icons-material';
import {useRef} from 'react';
import {LoadingButton} from '@mui/lab';
import {isAdmin} from '../../utils/Authentication';
import {AuthContext} from '../../App';
import TextEditor from '../../components/TextEditor/TextEditor';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Pictures from '../../components/Pictures';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import List from '@mui/material/List';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
export const getLocalDateTime = (item, mode) => {
	if (item !== null && item !== undefined) {
		if (typeof item === 'string') {
			if (mode === 'date') {
				return item.slice(0, 10);
			} else if (mode === 'dateandtime') {
				return item.slice(0, 10) + ' ' + item.slice(11, 16);
			} else if (mode === 'dateandtimeandseconds') {
				return item.slice(0, 10) + ' ' + item.slice(11, 19);
			}
		} else if (typeof item === 'object') {
			if (mode === 'date') {
				return item.toISOString().slice(0, 10);
			} else if (mode === 'dateandtime') {
				return item.toISOString().slice(0, 10) + ' ' + item.toISOString().slice(11, 16);
			} else if (mode === 'dateandtimeandseconds') {
				return item.toISOString().slice(0, 10) + ' ' + item.toISOString().slice(11, 19);
			}
		}
	} else {
		return '';
	}
};
const EditInternalPostPage = props => {
	const language = localStorage.getItem('i18nextLng');
	const authContext = useContext(AuthContext);
	const [loadingNotification, setLoadingNotification] = useState(false);
	const {t} = useTranslation();
	const history = useHistory();
	const inputRef = useRef();
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loading, setLoading] = useState(true);
	const [notAllowed, setNotAllowed] = useState(false);

	const [photoThatIsBeingEdited, setPhotoThatIsBeingEdited] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const today = new Date();
	const yearToday = today.getFullYear();
	const [to, setTo] = useState('red-adresser@redmanagement.se');
	const [cc, setCc] = useState('');
	const [bcc, setBcc] = useState('');
	const [emails, setEmails] = useState(['red-adresser@redmanagement.se']);
	const [data, setData] = useState({
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		link: '',
		linkname: '',
		date: '',
		year: '',
		emailsSent: [],
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		published: false,
		created: new Date(Date.now()),
		createdBy: '',
		edited: new Date(Date.now()),
		editedBy: '',
	});

	useEffect(() => {
		if (props.match.params.post_id === 'ny') {
			createNewPost();
		} else {
			getPost();
		}
	}, []);
	const getPost = () => {
		api
			.get('/post/internalpost/' + props.match.params.post_id)
			.then(response => {
				if (response.data.success === false) {
					document.title = 'RED Management • 404';
					setNotAllowed(true);
					setTimeout(() => {
						history.push('/');
					}, 2000);
				} else {
					setData(response.data);
					setLoading(false);
					document.title = 'Redigera nyhet: ' + response.data.swedishTitle;
				}
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleChange = event => {
		if (event.target.value !== null) {
			const newData = {...data, [event.target.name]: event.target.value};
			setData(newData);
			updatePost(newData);
		}
	};

	const createNewPost = () => {
		api
			.post('/post/post', data)
			.then(response => {
				setData(response.data);
				history.replace({pathname: `/redigeranyhet/${response.data._id}`});
			})
			.catch(error => console.error(error));
	};

	const deletePost = post => {
		api
			.delete('/post/post/' + post._id)
			.then(() => {
				history.push('/nyheter');
			})
			.catch(error => {
				console.error(error);
			});
	};
	const updatePost = newData => {
		api.put('/post/post/' + newData._id, newData).catch(error => {
			console.error(error);
		});
	};
	if (notAllowed)
		return (
			<Grid
				container
				sx={{
					margin: '0 auto',
					minHeight: '50vh',
					maxWidth: '1300px',
					padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
				}}
			>
				<Grid item xs={12} sx={{padding: '2rem 0'}}>
					Du har saknar behörighet för att komma åt denna sida. Du dirigeras om...
				</Grid>
			</Grid>
		);
	else {
		return (
			<Grid
				container
				sx={{
					margin: '0 auto',
					minHeight: '50vh',
					maxWidth: '1300px',
					padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
				}}
			>
				{loading ? (
					<Grid item xs={12} sx={{paddingTop: '5rem'}}>
						<LoadingComponent loadingText={'Laddar...'} />
					</Grid>
				) : (
					<React.Fragment>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: {xs: '1rem', md: '3rem'},
								flexWrap: 'wrap',
							}}
						>
							<BreadCrumb
								mainLinkUrl={'/internwebb'}
								mainLinkWord={'Internwebb'}
								subLinkUrl={'/internwebb'}
								subLinkWord={data.year}
								title={data.swedishTitle}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'right',
									gap: '10px',
									padding: '10px 0 0',
									flexDirection: 'row',
									flexWrap: 'wrap',
								}}
							>
								{confirmDelete ? (
									<>
										<p>Är du säker på att du vill radera denna post?</p>
										<LoadingButton
											variant="contained"
											color="primary"
											startIcon={<Check />}
											loading={loadingDelete}
											style={{
												textTransform: 'none',
												fontFamily: 'Titillium Web',
											}}
											onClick={() => {
												setLoadingDelete(true);
												deletePost(data);
											}}
										>
											Ja, radera
										</LoadingButton>
										<Button
											variant="outlined"
											startIcon={<Clear />}
											style={{
												color: 'black',
												marginLeft: '1rem',

												textTransform: 'none',
												fontFamily: 'Titillium Web',
											}}
											onClick={() => {
												setConfirmDelete(false);
											}}
										>
											Nej, avbryt
										</Button>
									</>
								) : (
									<>
										<FormControlLabel
											control={
												<Switch
													checked={data.published}
													disabled={!isAdmin(authContext.state.email)}
													onChange={() => {
														updatePost({...data, published: !data.published});
														setData({
															...data,
															published: !data.published,
														});
													}}
													name="published"
												/>
											}
											label={data.published ? 'Är publicerad' : 'Ej publicerad'}
										/>
										<Button
											startIcon={<Visibility />}
											onClick={() => history.push('/internwebb/' + data._id)}
											variant="contained"
											style={{
												background: '#4cae52',
												textTransform: 'none',
												fontFamily: 'Titillium Web',
											}}
										>
											Förhandsgranska
										</Button>
										{(isAdmin(authContext.state.email) ||
											(!data.published && authContext.state.email === data.createdBy)) && (
											<LoadingButton
												variant="contained"
												startIcon={<Delete />}
												style={{
													background: '#FFCCCB',
													color: 'black',
													textTransform: 'none',
													fontFamily: 'Titillium Web',
												}}
												onClick={() => {
													setConfirmDelete(true);
												}}
											>
												Radera
											</LoadingButton>
										)}
									</>
								)}
							</div>
						</Grid>
						<Grid item xs={12}>
							<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
						</Grid>
						<Grid item xs={12}>
							<Pictures
								item={data}
								english={false}
								getData={getPost}
								setItem={setData}
								attributeName={'posts'}
								updateItem={updatePost}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<FormControl fullWidth margin="dense">
										<TextField
											label="Titel"
											type="text"
											value={data.swedishTitle}
											onChange={handleChange}
											name="swedishTitle"
											autoComplete="off"
											id="swedishTitle"
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth margin="dense">
										<TextField
											label="Ingress"
											type="text"
											multiline
											minRows={2}
											value={data.swedishSubtitle}
											autoComplete="off"
											onChange={handleChange}
											name="swedishSubtitle"
											id="swedishSubtitle"
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth margin="dense">
										<TextEditor
											text={data.swedishText}
											setText={newText => {
												if (newText !== null) {
													const newData = {...data, swedishText: newText};
													setData(newData);
													updatePost(newData);
												}
											}}
										/>
										<FormHelperText>Brödtext</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth margin="dense">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										disableToolbar
										format="YYYY-MM-DD"
										InputProps={{
											readOnly: true,
										}}
										slotProps={{
											textField: {
												helperText: 'Ändra publiceringsdatum',
											},
										}}
										KeyboardButtonProps={{
											'aria-label': 'Välj datum',
										}}
										onChange={newValue => {
											const newData = {...data, created: newValue.toISOString()};
											setData(newData);
											updatePost(newData);
										}}
										name="created"
										id="created"
										value={dayjs(data.created)}
									/>
								</LocalizationProvider>
							</FormControl>
						</Grid>
						<Divider sx={{width: '100%', color: 'gray', margin: '1rem auto'}} />
						<Grid item xs={12}>
							<h3>Skicka notifikation</h3>
						</Grid>
						<Grid item xs={12}>
							<FormControl margin="dense" fullWidth>
								<TextField
									variant="outlined"
									name="EmailAddressTo"
									autoComplete="new-password"
									size="small"
									id="EmailAddressTo"
									label="Mottagare"
									value={to}
									onChange={event => {
										setTo(event.target.value);
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl margin="dense" fullWidth>
								<TextField
									variant="outlined"
									name="EmailAddressCC"
									autoComplete="new-password"
									label="CC"
									size="small"
									id="EmailAddressCC"
									value={cc}
									onChange={event => {
										setCc(event.target.value);
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl margin="dense" fullWidth>
								<TextField
									variant="outlined"
									name="EmailAddressBCC"
									label="BCC"
									size="small"
									autoComplete="new-password"
									id="EmailAddressBCC"
									value={bcc}
									onChange={event => {
										setBcc(event.target.value);
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sx={{padding: '1rem 0'}}>
							<LoadingButton
								variant="contained"
								disabled={!isAdmin(authContext.state.email) || loadingNotification}
								color="primary"
								sx={{textTransform: 'none'}}
								loading={loadingNotification}
								onClick={() => {
									setLoadingNotification(true);
									api
										.post('/email/intranetnotification', {
											data,
											to: to,
											cc: cc,
											bcc: bcc,
											from: JSON.parse(localStorage.getItem('email')),
										})
										.then(response => {
											setLoadingNotification(false);
											getPost();
										})
										.catch(error => {
											console.error(error);
											setLoadingNotification(false);
										});
								}}
								startIcon={<Send />}
							>
								Skicka notifikation
							</LoadingButton>
						</Grid>
						<Grid item xs={12}>
							{data.emailsSent !== null &&
								data.emailsSent !== undefined &&
								data.emailsSent.length > 0 && (
									<List style={{padding: 0, margin: 0, fontSize: 12, textAlign: 'left'}}>
										{data.emailsSent
											.sort((a, b) => new Date(b.dateSent) - new Date(a.dateSent))
											.map((x, index) => (
												<ListItem
													style={{
														margin: 0,
														padding: 0,
														textAlign: 'left',
													}}
													key={index}
												>
													{getLocalDateTime(x.dateSent, 'dateandtimeandseconds')}
													{' - '}
													{x.success &&
														'Notifikation skickad till ' + x.recipient + ' av ' + x.sentBy}
													{!x.success &&
														'Notifikation kunde inte skickas till ' +
															x.recipient +
															' av ' +
															x.sentBy}
												</ListItem>
											))}
									</List>
								)}
						</Grid>
					</React.Fragment>
				)}
			</Grid>
		);
	}
};
export default EditInternalPostPage;

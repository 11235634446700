import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import analysis from '../../assets/istockphoto-480361476-612x612.jpg';
import duediligence from '../../assets/duediligence.jpg';
import realestatedevelopment from '../../assets/projects.jpg';
import sustainability from '../../assets/contact.jpg';
import buildersrepresentative from '../../assets/about_us.jpg';
import projectmanagement from '../../assets/ledning.jpg';

import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import '../../css/normalize.css';

import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import './StartPage.css';
import {useTranslation} from 'react-i18next';
import {Alert} from '@mui/lab';
import api from '../../api';
import {useContext, useRef} from 'react';
import {AuthContext} from '../../App';
import {CameraAlt, Clear, Delete, Edit, VisibilityOff} from '@mui/icons-material';
import parse from 'html-react-parser';
function StartPage() {
	const authContext = useContext(AuthContext);

	const userEmail = localStorage.getItem('email');
	const [creatingNewCustomer, setCreatingNewCustomer] = useState(false);
	const [customerThatIsBeingEdited, setCustomerThatIsBeingEdited] = useState('');

	const [customers, setCustomers] = useState([]);
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [hasUpdated, setHasUpdated] = useState(false);
	const history = useHistory();
	const inputRef = useRef();

	const language = localStorage.getItem('i18nextLng');
	const [loading, setLoading] = useState(false);
	const [loadingCustomers, setLoadingCustomers] = useState(false);
	const [loadingNewCustomer, setLoadingNewCustomer] = useState([]);
	const [loadingNews, setLoadingNews] = useState(false);
	const [loadingProjects, setLoadingProjects] = useState(false);
	const [loadingServices, setLoadingServices] = useState(false);

	const [message, setMessage] = useState('');
	const [newCustomer, setNewCustomer] = useState({
		customerName: '',
		pictureUrl: '',
		createdBy: userEmail,
		createdDate: new Date(Date.now()),
		editedBy: userEmail,
		editedDate: new Date(Date.now()),
	});
	const [news, setNews] = useState([]);
	const [projects, setProjects] = useState([]);
	const [services, setServices] = useState([]);

	const refArray = React.useRef([]);
	const [serviceLayout, setServiceLayout] = useState('3x2');
	const settings = {
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		arrows: false,
		autoplay: true,
		slidesToScroll: 1,
	};
	const [subject, setSubject] = useState('');
	const executeScroll = index => {
		refArray.current[index].classList.toggle('highlighted');
		refArray.current[index].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
	};

	const [updateMessage, setUpdateMessage] = useState('');
	const [updateType, setUpdateType] = useState('info');
	const {t} = useTranslation();

	useEffect(() => {
		getProjects();
		getPosts();
		getServices();
		getCustomers();
		document.title = 'RED Management • Start';
	}, []);

	const getPosts = () => {
		api
			.get('/post/posts')
			.then(response => {
				setNews(response.data.filter(r => r.showOnStartPage));
				setLoadingNews(false);
			})
			.catch(error => console.error(error));
	};
	const getProjects = () => {
		api
			.get('/project/projects')
			.then(response => {
				setProjects(
					response.data.filter(r => r.showOnStartPage).sort((a, b) => a.postOrder - b.postOrder)
				);
				setLoadingProjects(false);
			})
			.catch(error => {
				console.error(error);
			});
	};
	const getCustomers = () => {
		api
			.get('/customer/getall')
			.then(response => {
				setCustomers(response.data);
				setLoadingCustomers(false);
			})
			.catch(error => {
				setLoadingCustomers(false);
				console.error(error);
			});
	};
	const deleteCustomer = customer => {
		api
			.delete('/customer/' + customer._id)
			.then(() => getCustomers())
			.catch(error => console.error(error));
	};
	const submitForm = e => {
		e.preventDefault();

		if (firstName.length === 0) {
			setUpdateMessage('Vänligen ange ditt namn');
			setUpdateType('error');
			setHasUpdated(true);
		}
		if (subject.length === 0) {
			setUpdateMessage('Vänligen skriv en ämnesrad');
			setUpdateType('error');
			setHasUpdated(true);
		}
		if (message.length === 0) {
			setUpdateMessage('Skriv ett meddelande');
			setUpdateType('error');
			setHasUpdated(true);
		}
		if (email.length === 0) {
			setUpdateMessage('Vänligen ange en giltig epostadress');
			setUpdateType('error');
			setHasUpdated(true);
		} else {
			api
				.post('/email/contactlandingpage', {
					name: firstName,
					subject: subject,
					message: message,
					email: email,
				})
				.then(response => {
					setUpdateMessage('Tack för ditt meddelande!');
					setUpdateType('success');
					setHasUpdated(true);
				})
				.catch(error => {
					setUpdateMessage('Det gick inte att skicka meddelandet');
					setUpdateType('error');
					setHasUpdated(true);
					console.error(error);
				});
		}
	};

	const getServices = () => {
		api
			.get('/service/getall')
			.then(response => {
				setServices(response.data.filter(r => r.service.showOnStartPage));
				setLoadingServices(false);
			})
			.catch(error => {
				setLoadingServices(false);
				console.error(error);
			});
	};
	return (
		<div>
			<div className="hero-mobile-container">
				<Box className="hero-mobile">
					<div style={{paddingTop: '7rem'}}>
						<div
							style={{
								backgroundColor: 'rgb(63,120,66,0.95)',
								color: 'white',
								padding: '2.5rem 1.5rem',
								alignContent: 'left',
								justifyContent: 'left',
								margin: '0 0 0 1rem',
								textAlign: 'left',
								borderRadius: '3% 0 0 3%',
								fontFamily: 'Titillium Web',
							}}
						>
							<Slider {...settings}>
								<div>
									<Typography
										variant="h5"
										style={{
											fontFamily: 'Titillium Web',
											color: '#ffffff',
											zIndex: 100,
											marginRight: '20px',
											marginLeft: '10px',
										}}
									>
										{t('startpage.title')}
									</Typography>
									<Typography
										variant="h6"
										style={{
											marginTop: '1rem',
											marginRight: '20px',
											marginLeft: '10px',
											fontFamily: 'Titillium Web',
										}}
									>
										{t('startpage.subtitle3')}
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontFamily: 'Titillium Web',
											color: '#ffffff',
											marginRight: '20px',
											marginLeft: '10px',
											zIndex: 100,
										}}
									>
										{t('startpage.title3')}
									</Typography>
									<Typography
										variant="h6"
										style={{
											marginTop: '1rem',
											marginRight: '20px',
											marginLeft: '10px',
											fontFamily: 'Titillium Web',
										}}
									>
										{t('startpage.subtitle')}
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontFamily: 'Titillium Web',
											color: '#ffffff',
											lineHeight: '31px',
											marginRight: '20px',
											marginLeft: '10px',
											zIndex: 100,
										}}
									>
										{t('startpage.title2')}
									</Typography>
									<Typography
										variant="h6"
										style={{
											marginTop: '1rem',
											marginRight: '20px',
											marginLeft: '10px',
											lineHeight: '25px',
											fontFamily: 'Titillium Web',
										}}
									>
										{t('startpage.subtitle2')}
									</Typography>
								</div>
							</Slider>
						</div>
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							marginTop: '12px',
							flexWrap: 'wrap',
							flexDirection: 'column',
							justifyContent: 'left',
						}}
					>
						<div
							className="hero-item"
							style={{
								width: 'auto',
								margin: '1px 0',
								textAlign: 'left',
								fontSize: '1.3rem',
								padding: '0.5rem 0.5rem 0.5rem 1.5rem',
								display: 'flex',
								justifyContent: 'left',
							}}
							onClick={() => executeScroll(0)}
						>
							<div>{t('startpage.analysis')}</div>
							<ArrowForwardIcon style={{color: '#4cae52', marginLeft: '1rem'}} />
						</div>
						<div
							className="hero-item"
							style={{
								width: 'auto',
								margin: '1px 0',
								textAlign: 'left',
								fontSize: '1.3rem',
								padding: '0.5rem 0.5rem 0.5rem 1.5rem',
								display: 'flex',
								justifyContent: 'left',
							}}
							onClick={() => executeScroll(1)}
						>
							<div>{t('startpage.duediligence')}</div>
							<ArrowForwardIcon style={{color: '#4cae52', marginLeft: '1rem'}} />
						</div>
						<div
							className="hero-item"
							style={{
								width: 'auto',
								margin: '1px 0',
								textAlign: 'left',
								fontSize: '1.3rem',
								padding: '0.5rem 0.5rem 0.5rem 1.5rem',
								display: 'flex',
								justifyContent: 'left',
							}}
							onClick={() => executeScroll(2)}
						>
							<div>{t('startpage.realestatedevelopment')}</div>
							<ArrowForwardIcon style={{color: '#4cae52', marginLeft: '1rem'}} />
						</div>
						<div
							className="hero-item"
							style={{
								width: 'auto',
								margin: '1px 0',
								textAlign: 'left',
								fontSize: '1.3rem',
								padding: '0.5rem 0.5rem 0.5rem 1.5rem',
								display: 'flex',
								justifyContent: 'left',
							}}
							onClick={() => executeScroll(3)}
						>
							<div> {t('startpage.sustainability')}</div>
							<ArrowForwardIcon style={{color: '#4cae52', marginLeft: '1rem'}} />
						</div>
						<div
							className="hero-item"
							style={{
								width: 'auto',
								margin: '1px 0',
								textAlign: 'left',
								fontSize: '1.3rem',
								padding: '0.5rem 0.5rem 0.5rem 1.5rem',
								display: 'flex',
								justifyContent: 'left',
							}}
							onClick={() => executeScroll(4)}
						>
							<div> {t('startpage.builddevrep')}</div>
							<ArrowForwardIcon style={{color: '#4cae52', marginLeft: '1rem'}} />
						</div>
						<div
							className="hero-item"
							style={{
								width: 'auto',
								margin: '1px 0',
								textAlign: 'left',
								fontSize: '1.3rem',
								padding: '0.5rem 0.5rem 0.5rem 1.5rem',
								display: 'flex',
								justifyContent: 'left',
							}}
							onClick={() => executeScroll(5)}
						>
							<div> {t('startpage.projectmanagement')}</div>
							<ArrowForwardIcon style={{color: '#4cae52', marginLeft: '1rem'}} />
						</div>
					</div>
				</Box>
			</div>
			<div className="hero-desktop-container">
				<div
					className="hero-desktop"
					style={{
						paddingTop: '5rem',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						sx={{
							backgroundColor: '#4cae52',
							color: 'white',
							marginLeft: '30vw',
							marginTop: '-8vh',
							padding: {
								md: '2rem 2rem',
								xl: '3rem',
							},
							width: '40vw',
							opacity: '0.94',
							fontFamily: 'Titillium Web',
						}}
					>
						<Slider {...settings}>
							<div>
								<Typography
									variant="h3"
									style={{
										fontSize: '45px',
										margin: '1rem 2rem 0 2rem',
									}}
								>
									{t('startpage.title')}
								</Typography>
								<Typography variant="h5" style={{margin: '1rem 2rem 1rem 2rem'}}>
									{t('startpage.subtitle3')}
								</Typography>
							</div>
							<div>
								<Typography
									variant="h3"
									style={{
										fontSize: '45px',
										margin: '1rem 2rem 0 2rem',
									}}
								>
									{t('startpage.title3')}
								</Typography>
								<Typography variant="h5" style={{margin: '1rem 2rem 1rem 2rem'}}>
									{t('startpage.subtitle')}
								</Typography>
							</div>
							<div>
								<Typography
									variant="h3"
									style={{
										fontSize: '45px',
										margin: '1rem 2rem 0 2rem',
									}}
								>
									{t('startpage.title2')}
								</Typography>
								<Typography variant="h5" style={{margin: '1rem 2rem 1rem 2rem'}}>
									{t('startpage.subtitle2')}
								</Typography>
							</div>
						</Slider>
					</Grid>
				</div>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						marginTop: '-14vh',
						justifyContent: 'left',
						fontFamily: 'Lato',
						flexGrow: 'stretch',
					}}
				>
					<div className="hero-item" onClick={() => executeScroll(0)} style={{marginRight: '1px'}}>
						<div>{t('startpage.analysis')}</div>
						<ArrowForwardIcon style={{color: '#4cae52'}} />
					</div>
					<div className="hero-item" onClick={() => executeScroll(1)} style={{marginRight: '1px'}}>
						<div>{t('startpage.duediligence')}</div>
						<ArrowForwardIcon style={{color: '#4cae52'}} />
					</div>
					<div className="hero-item" onClick={() => executeScroll(2)} style={{marginRight: '1px'}}>
						<div>{t('startpage.realestatedevelopment')}</div>
						<ArrowForwardIcon style={{color: '#4cae52'}} />
					</div>
					<div
						className="hero-item-bottom"
						onClick={() => executeScroll(3)}
						style={{marginRight: '1px'}}
					>
						<div>{t('startpage.sustainability')}</div>
						<ArrowForwardIcon style={{color: '#4cae52'}} />
					</div>
					<div
						className="hero-item-bottom"
						onClick={() => executeScroll(4)}
						style={{marginRight: '1px'}}
					>
						<div>{t('startpage.builddevrep')}</div>
						<ArrowForwardIcon style={{color: '#4cae52'}} />
					</div>
					<div
						className="hero-item-bottom"
						onClick={() => executeScroll(5)}
						style={{marginRight: '1px'}}
					>
						<div>{t('startpage.projectmanagement')}</div>
						<ArrowForwardIcon style={{color: '#4cae52'}} />
					</div>
				</div>
			</div>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					minHeight: {xs: '450px', sm: '60vh', md: '80vh', xl: '80vh'},
					background: '#ebebeb',
					padding: {xs: '1rem', sm: '5rem 3rem', md: '5rem 3rem', xl: '5rem 3rem'},
					justifyContent: 'center',
				}}
			>
				<Grid
					container
					style={{
						display: 'flex',
						alignItems: 'center',
						maxWidth: '1300px',
						justifyContent: 'space-around',
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							textAlign: {
								xs: 'left',
								sm: 'center',
								md: 'center',
								xl: 'center',
							},
							maxWidth: '1100px',
						}}
					>
						<Typography
							sx={{
								color: '#4cae52',
								marginBottom: '1rem',
								fontSize: {
									xs: '1.6rem',
									sm: '2rem',
									md: '2.4rem',
									xl: '3rem',
								},
								fontWeight: 600,
								fontFamily: 'Lato',
							}}
						>
							{t('startpage.introsubtitle')}
						</Typography>
						<Typography
							sx={{
								opacity: '0.7',
								padding: '1rem auto',
								fontFamily: 'Raleway',
								fontSize: {
									xs: '1.1rem',
									sm: '1.4rem',
									md: '1.4rem',
									xl: '2rem',
								},
							}}
						>
							{t('startpage.introparagraph')}
						</Typography>
					</Grid>
				</Grid>
			</Box>

			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					minHeight: '15rem',
					padding: '2rem',
					background: '#ffffff',
				}}
			>
				<Grid container spacing={2} style={{maxWidth: '1300px'}}>
					<Grid
						item
						xs={12}
						style={{
							padding: '2rem ',
							textAlign: {
								xs: 'center',
								sm: 'center',
								md: 'center',
								xl: 'center',
							},
						}}
					>
						<Typography
							variant="h4"
							style={{
								color: '#4cae52',
								marginBottom: '1rem',
								fontSize: {
									xs: '1.3rem',
									sm: '2rem',
									md: '2.3rem',
									xl: '2.5rem',
								},
								cursor: 'pointer',

								fontWeight: 600,
								fontFamily: 'Lato',
							}}
							onClick={() => history.push('/projekt')}
						>
							{t('startpage.projects')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{loadingProjects ? (
							<Grid container spacing={2}>
								<Grid item xs={12} style={{textAlign: 'center', padding: '2rem'}}>
									<CircularProgress />
								</Grid>
							</Grid>
						) : (
							<Grid container spacing={2}>
								{!loading && projects !== null && projects !== undefined && projects.length > 0 && (
									<>
										{projects?.map((item, index) => (
											<Grid
												item
												key={item._id}
												xs={12}
												md={4}
												sm={4}
												className="project-detail"
												style={{textAlign: 'left', padding: '1rem', cursor: 'pointer'}}
											>
												<Card
													key={item.id}
													onClick={() => history.push('/projekt/' + item.url)}
													style={{
														textAlign: 'left',
														boxShadow: '12px 12px 24px #d8d8d8, -12px -12px 24px #fefefe',
														borderRadius: '3px',
														backgroundColor: '#f2f0f1',
													}}
												>
													<CardMedia
														component="img"
														sx={{width: {xs: '100%'}}}
														image={item.pictureUrl}
														alt={language === 'sv' ? item.swedishTitle : item.englishTitle}
														title={language === 'sv' ? item.swedishTitle : item.englishTitle}
													></CardMedia>

													<CardContent>
														<Typography
															style={{
																color: 'rgb(76, 174, 82)',
																fontFamily: 'Titillium Web',
															}}
															gutterBottom
															variant="h5"
															component="div"
														>
															{language === 'sv' ? item.swedishTitle : item.englishTitle}
														</Typography>
														{language === 'sv' && item.swedishSubtitle.length > 0 && (
															<Typography
																style={{fontFamily: 'Titillium Web'}}
																variant="body2"
																color="text.secondary"
															>
																{item.swedishSubtitle}
															</Typography>
														)}
														{language === 'en' && item.englishSubtitle.length > 0 && (
															<Typography
																style={{fontFamily: 'Titillium Web'}}
																variant="body2"
																color="text.secondary"
															>
																{item.englishSubtitle}
															</Typography>
														)}
													</CardContent>
													<CardActions style={{textAlign: 'left', padding: '0 1rem 1rem'}}>
														<Button
															endIcon={<ArrowForwardIcon style={{color: 'white'}} />}
															style={{
																background: '#4cae52',
																transition: '.2s ease-in-out',
																textTransform: 'none',
																fontFamily: 'Titillium Web',
															}}
															variant="contained"
															onClick={() => history.push('/projekt/' + item.url)}
														>
															{t('startpage.readmore')}
														</Button>
													</CardActions>
												</Card>
											</Grid>
										))}
									</>
								)}
								<Grid item xs={12} style={{margin: '0', padding: '2rem', textAlign: 'right'}}>
									<button
										onClick={() => history.push('/projekt')}
										className="neu-btn"
										style={{
											fontSize: '19px',
											opacity: '0.9',
											fontFamily: 'Lato',
											padding: '1rem 2rem',
											textTransform: 'none',
										}}
									>
										{t('startpage.allprojects')}
									</button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: '2rem',
					justifyContent: 'center',
					background: '#ebebeb',
				}}
			>
				<Grid container spacing={2} style={{maxWidth: '1300px'}}>
					<Grid
						item
						xs={12}
						style={{
							padding: '2rem ',
							textAlign: {
								xs: 'center',
								sm: 'center',
								md: 'center',
								xl: 'center',
							},
						}}
					>
						<Typography
							variant="h4"
							onClick={() => history.push('/nyheter')}
							style={{
								color: '#4cae52',
								marginBottom: '1rem',
								fontSize: {
									xs: '1.3rem',
									sm: '2rem',
									md: '2.3rem',
									xl: '2.5rem',
								},

								fontWeight: 600,
								cursor: 'pointer',
								fontFamily: 'Lato',
							}}
						>
							{t('startpage.latestnews')}
						</Typography>
					</Grid>
					{loadingNews ? (
						<Grid container spacing={2}>
							<Grid item xs={12} style={{textAlign: 'center', padding: '2rem 10px'}}>
								<CircularProgress style={{color: '#4cae52'}} />
							</Grid>
						</Grid>
					) : (
						<Grid item xs={12}>
							<Grid container spacing={2}>
								{!loading && news !== null && news !== undefined && news.length > 0 && (
									<>
										{news?.slice(0, 6).map((item, index) => (
											<Grid
												item
												xs={12}
												md={4}
												key={item._id}
												style={{textAlign: 'left', padding: '1rem'}}
											>
												<Card
													style={{
														margin: 0,
														boxShadow: '12px 12px 24px #d8d8d8, -12px -12px 24px #fefefe',
														borderRadius: '3px',
														cursor: 'pointer',

														background: '#efefef',
													}}
													onClick={() => history.push('/nyheter/' + item._id)}
												>
													<CardMedia
														component="img"
														sx={{width: {xs: '100%'}}}
														image={item.pictureUrl}
														alt={language === 'sv' ? item.swedishTitle : item.englishTitle}
														title={language === 'sv' ? item.swedishTitle : item.englishTitle}
													></CardMedia>
													<CardContent>
														<Typography
															gutterBottom
															variant="body1"
															style={{color: '#4cae52', fontFamily: 'Titillium Web'}}
														>
															{language === 'sv' ? item.swedishTitle : item.englishTitle}
														</Typography>
														{language === 'sv' && item.swedishSubtitle.length > 0 && (
															<Typography
																style={{fontFamily: 'Titillium Web'}}
																variant="body2"
																color="text.secondary"
															>
																{item.swedishSubtitle}
															</Typography>
														)}
														{language === 'en' && item.englishSubtitle.length > 0 && (
															<Typography
																style={{fontFamily: 'Titillium Web'}}
																variant="body2"
																color="text.secondary"
															>
																{item.englishSubtitle}
															</Typography>
														)}
													</CardContent>
													<CardActions style={{textAlign: 'right', padding: '1rem'}}>
														<Button
															endIcon={<ArrowForwardIcon style={{color: 'white'}} />}
															style={{
																background: '#4cae52',
																transition: '.2s ease-in-out',
																textTransform: 'none',
																fontFamily: 'Titillium Web',
															}}
															variant="contained"
															onClick={() => history.push('/nyheter/' + item._id)}
														>
															{t('startpage.readmore')}
														</Button>
													</CardActions>
												</Card>
											</Grid>
										))}
									</>
								)}
							</Grid>
						</Grid>
					)}

					<Grid item xs={12} style={{margin: '0', padding: '2rem', textAlign: 'right'}}>
						<button
							onClick={() => history.push('/nyheter')}
							className="neu-btn"
							style={{
								fontSize: '19px',
								opacity: '0.9',
								fontFamily: 'Lato',
								padding: '1rem 2rem',
								textTransform: 'none',
							}}
						>
							{t('startpage.allnews')}
						</button>
					</Grid>
				</Grid>
			</Box>
			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: '2rem 10px 0',

					justifyContent: 'center',
				}}
			>
				<Grid item xs={12} md={12} sm={12} style={{maxWidth: '1300px', margin: '0 auto'}}>
					<Grid container>
						<Grid item xs={12} style={{padding: '2rem ', textAlign: 'left'}}>
							<Typography
								onClick={() => history.push('/om-oss')}
								variant="h4"
								style={{
									color: '#4cae52',
									marginTop: '1rem',
									fontSize: {
										xs: '1.3rem',
										sm: '2rem',
										md: '2.3rem',
										xl: '2.5rem',
									},
									fontWeight: 600,
									cursor: 'pointer',

									fontFamily: 'Lato',
								}}
							>
								{t('startpage.ourservices')}
							</Typography>
						</Grid>
						{loadingServices ? (
							<Grid container spacing={2}>
								<Grid item xs={12} style={{textAlign: 'center', padding: '2rem 10px'}}>
									<CircularProgress style={{color: '#4cae52'}} />
								</Grid>
							</Grid>
						) : (
							<Grid item xs={12} style={{padding: '1rem 0', textAlign: 'left'}}>
								<Grid container>
									{services.length > 0 &&
										services.map((item, index) => (
											<Grid
												item
												xs={12}
												md={4}
												key={item._id}
												style={{textAlign: 'left', padding: '1rem'}}
												id={item.id}
												ref={ref => {
													refArray.current[index] = ref;
												}}
											>
												<Card
													key={item.service._id}
													style={{
														boxShadow: '12px 12px 24px #d8d8d8, -12px -12px 24px #fefefe',
														backgroundColor: '#ebebeb',
														height: '100%',
														borderRadius: '3px',
													}}
												>
													<CardMedia
														component="img"
														sx={{width: {xs: '100%'}}}
														image={item.service.pictureUrl}
														alt={
															language === 'sv'
																? item.service.pictureDescriptionSwedish
																: item.service.pictureDescriptionEnglish
														}
														title={
															language === 'sv'
																? item.service.pictureDescriptionSwedish
																: item.service.pictureDescriptionEnglish
														}
													></CardMedia>
													<CardContent>
														<Typography
															variant="h6"
															style={{
																color: 'rgb(76, 174, 82)',
																fontFamily: 'Titillium Web',
															}}
														>
															{language === 'sv'
																? item.service.swedishTitle
																: item.service.englishTitle}
														</Typography>
														{language === 'sv' && (
															<Typography
																variant="body1"
																style={{
																	fontFamily: 'Titillium Web',
																	lineHeight: '20px',
																	fontSize: '1rem',
																}}
															>
																{item.service.swedishText !== null &&
																	item.service.swedishText?.length > 0 &&
																	parse(item.service.swedishText)}
															</Typography>
														)}
														{language === 'en' && (
															<Typography
																variant="body1"
																style={{
																	fontFamily: 'Titillium Web',
																	lineHeight: '20px',
																	fontSize: '1rem',
																}}
															>
																{item.service.englishText !== null &&
																	item.service.englishText?.length > 0 &&
																	parse(item.service.englishText)}
															</Typography>
														)}
													</CardContent>
													<CardActions
														style={{
															textAlign: 'left',
															padding: '2rem 0 1rem 1rem',
														}}
													>
														<button
															onClick={() => history.push('/tjänster/' + item.service.url)}
															className="neu-btn-small"
															style={{margin: '0 auto', textTransform: 'none'}}
														>
															{t('startpage.readmore')}
														</button>
													</CardActions>
												</Card>
											</Grid>
										))}
								</Grid>
							</Grid>
						)}
						<Grid item xs={12} style={{margin: '0', padding: '2rem', textAlign: 'right'}}>
							<button
								onClick={() => history.push('/tjänster')}
								className="neu-btn"
								style={{
									fontSize: '19px',
									opacity: '0.9',
									fontFamily: 'Lato',
									padding: '1rem 2rem',
									textTransform: 'none',
								}}
							>
								{t('startpage.allservices')}
							</button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Divider style={{width: '80%', background: '#4cae52', margin: '4rem auto'}} />
			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					minHeight: '15rem',
					padding: '2rem',
					justifyContent: 'center',
				}}
			>
				<Grid container spacing={2} style={{maxWidth: '1300px'}}>
					<Grid item xs={12} style={{textAlign: 'left', padding: '2rem'}}>
						<Typography
							variant="h4"
							style={{
								color: '#4cae52',
								marginBottom: '0',
								fontSize: {
									xs: '1.3rem',
									sm: '2rem',
									md: '2.3rem',
									xl: '2.5rem',
								},
								fontWeight: 600,
								fontFamily: 'Lato',
							}}
						>
							{t('startpage.ourclients')}
						</Typography>
					</Grid>
					{loadingCustomers ? (
						<Grid container spacing={2}>
							<Grid item xs={12} style={{textAlign: 'center', padding: '2rem 10px'}}>
								<CircularProgress style={{color: '#4cae52'}} />
							</Grid>
						</Grid>
					) : (
						<Grid item xs={12} style={{textAlign: 'center'}}>
							{authContext.state.isAuthenticated ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										gap: '1rem',
										flexWrap: 'wrap',
										flexDirection: 'row',
									}}
								>
									{customers?.length > 0 &&
										customers?.map(customer => (
											<Card key={customer._id}>
												{customerThatIsBeingEdited === customer._id ? (
													<>
														<div
															style={{
																width: '320px',
																height: '120px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																position: 'relative',
															}}
														>
															<img
																src={customer.pictureUrl}
																alt={customer.customerName}
																style={{
																	width: '100%',
																	height: '100%',
																	objectFit: 'contain', // Prevents stretching
																}}
															/>
															<div
																style={{
																	position: 'absolute',
																	width: '100%',
																	height: '100%',
																	backgroundColor: 'rgba(0, 0, 0, 0.4)',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	flexDirection: 'column',
																	cursor: 'pointer',
																}}
																onClick={() => inputRef.current.click()}
															>
																<CameraAlt fontSize="large" style={{fill: 'white'}} />
																<p style={{margin: '0', color: 'white', fontWeight: 'bold'}}>
																	Ladda upp bild
																</p>
															</div>
														</div>
														<input
															type="file"
															id="mainphoto"
															name="file"
															style={{display: 'none'}}
															ref={inputRef}
															onChange={event => {
																const formData = new FormData();
																formData.append('file', event.target.files[0]);
																api
																	.post(
																		`/customer/uploadmaincustomerphoto/${customer._id}`,
																		formData,
																		{
																			headers: {
																				'Content-Type': 'multipart/form-data',
																			},
																		}
																	)
																	.then(() => {
																		getCustomers();
																		setCustomerThatIsBeingEdited('');
																	})
																	.catch(error => {
																		console.error(error);
																	});
															}}
														/>
													</>
												) : (
													<div
														style={{
															width: '320px',
															height: '120px',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<img
															src={customer.pictureUrl}
															alt={customer.customerName}
															style={{
																width: '100%',
																height: '100%',
																objectFit: 'contain', // Prevents stretching
															}}
														/>
													</div>
												)}
												{customerThatIsBeingEdited === customer._id && (
													<div style={{padding: '10px'}}>
														<small>OBS! Bilden bör vara 320 x 120px för bästa resultat.</small>
													</div>
												)}
												{authContext.state.isAuthenticated && (
													<CardActions>
														{customerThatIsBeingEdited === customer._id ? (
															<Button
																style={{textTransform: 'none'}}
																onClick={() => setCustomerThatIsBeingEdited('')}
																startIcon={<Clear />}
																variant="outlined"
															>
																Avbryt
															</Button>
														) : (
															<Button
																style={{textTransform: 'none'}}
																onClick={() => setCustomerThatIsBeingEdited(customer._id)}
																startIcon={<Edit />}
																variant="contained"
															>
																Redigera
															</Button>
														)}

														{customerThatIsBeingEdited === customer._id && (
															<Button
																style={{textTransform: 'none'}}
																onClick={() => deleteCustomer(customer)}
																startIcon={<Delete />}
																variant="outlined"
															>
																Radera
															</Button>
														)}
													</CardActions>
												)}
											</Card>
										))}
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										gap: '1rem',
										flexWrap: 'wrap',
										flexDirection: 'row',
									}}
								>
									{customers?.length > 0 &&
										customers.map(customer => (
											<Tooltip title={customer.customerName} key={customer._id}>
												<img
													src={customer.pictureUrl}
													alt={customer.customerName}
													style={{
														width: '200px',
														height: 'auto',
														objectFit: 'contain', // Prevents stretching
													}}
												/>
											</Tooltip>
										))}
								</div>
							)}
						</Grid>
					)}

					{authContext.state.isAuthenticated && (
						<Grid item xs={12} style={{textAlign: 'right'}}>
							<Button
								onClick={() => {
									api
										.post('/customer/createnew', newCustomer)
										.then(() => {
											setCreatingNewCustomer(false);
											getCustomers();
										})
										.catch(error => console.error(error));
								}}
								variant="contained"
								color="primary"
								style={{textTransform: 'none'}}
							>
								Lägg till ny kund
							</Button>
						</Grid>
					)}
				</Grid>
			</Box>
			<Divider style={{width: '80%', background: '#4cae52', margin: '4rem auto'}} />
			<Box
				style={{
					flexGrow: 1,
					padding: '2rem',
					maxWidth: '700px',
					margin: '30px auto',
				}}
				id="contactUs"
			>
				<Typography
					variant="h4"
					style={{
						color: '#4cae52',
						marginBottom: '0',
						fontSize: {
							xs: '1.3rem',
							sm: '2rem',
							md: '2.3rem',
							xl: '2.5rem',
						},
						fontWeight: 600,
						fontFamily: 'Lato',
					}}
				>
					{t('start.contact.title')}
				</Typography>
				<Box
					style={{
						marginTop: '30px',
					}}
					component="form"
					noValidate
					autoComplete="off"
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<TextField
									label={t('start.contact.name')}
									variant="outlined"
									fullWidth
									value={firstName}
									onChange={e => setFirstName(e.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<TextField
									label={t('start.contact.email')}
									variant="outlined"
									required
									fullWidth
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<TextField
									label={t('start.contact.subject')}
									variant="outlined"
									fullWidth
									value={subject}
									onChange={e => setSubject(e.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<TextField
									multiline={true}
									variant="outlined"
									minRows={6}
									placeholder={t('start.contact.message')}
									value={message}
									onChange={e => setMessage(e.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<button onClick={submitForm} className="neu-btn">
								{t('start.contact.send')}
							</button>

							{hasUpdated && <Alert severity={updateType}>{updateMessage}</Alert>}
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}

export default StartPage;

import {Box, Button} from '@mui/material';
import redm_logo from '../assets/redm.png';
import en_flag from '../assets/en_flag.png';
import sv_flag from '../assets/sv_flag.png';
import React, {useContext} from 'react';
import './Topbar.css';
import i18next from 'i18next';

import {useTranslation} from 'react-i18next';
import {AuthContext} from '../App';
import {Link} from 'react-router-dom';
import {ExitToApp, Group, People, Settings} from '@mui/icons-material';
import {versionDate, versionNumber} from '../utils/Version';
export default function DesktopTopbar() {
	const {t} = useTranslation();
	const authContext = useContext(AuthContext);
	const {state, dispatch} = React.useContext(AuthContext);

	const handleChangeLanguage = newLanguage => {
		localStorage.setItem('redmanagement-language', newLanguage);
		i18next
			.changeLanguage(newLanguage.split('-')[0], (err, t) => {
				if (err) return console.log('language changing failed.', err);
				window.location.reload();
			})
			.then(r => console.log(r));
	};
	return (
		<Box
			className="desktop"
			sx={{
				width: '100vw',
				zIndex: 1000,
				boxShadow: 0,
				padding: 0,
				textAlign: 'center',
			}}
		>
			{authContext.state.isAuthenticated && (
				<Box
					sx={{
						background: '#4cae52',
						color: 'white',
						display: 'flex',
						padding: '0 1rem',
						justifyContent: 'space-between',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Box sx={{display: 'flex', justifyContent: 'left', gap: '1rem', flexDirection: 'row'}}>
						<Box style={{marginRight: '1rem'}}>{versionNumber}</Box>
						<Box style={{marginRight: '1rem'}}>{versionDate}</Box>
					</Box>
					<Box
						sx={{
							padding: '0 1rem',
							color: 'white',
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
							alignContent: 'center',
							gap: '1rem',
						}}
					>
						<Box
							className="intranav-item"
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								alignItems: 'center',
								flexDirection: 'row',
								alignContent: 'center',
							}}
						>
							<Link className="intranav-link" to="/internwebb">
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-evenly',
										alignItems: 'center',
										flexDirection: 'row',
										gap: '10px',
										alignContent: 'center',
									}}
								>
									<Group />
									<Box>Internwebb</Box>
								</Box>
							</Link>
						</Box>
						<Box
							className="intranav-item"
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								alignItems: 'center',
								flexDirection: 'row',
								alignContent: 'center',
							}}
						>
							<Link
								className="intranav-link"
								to={'/kontakt/' + JSON.parse(localStorage.getItem('email'))}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-evenly',
										alignItems: 'center',
										flexDirection: 'row',
										gap: '10px',
										alignContent: 'center',
									}}
								>
									<Settings />
									<Box>
										{JSON.parse(localStorage.getItem('firstName')) +
											' ' +
											JSON.parse(localStorage.getItem('lastName'))}
									</Box>
								</Box>
							</Link>
						</Box>
						<Box
							className="intranav-item"
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								alignItems: 'center',
								flexDirection: 'row',
								alignContent: 'center',
							}}
						>
							<Link
								className="intranav-link"
								onClick={() => {
									dispatch({type: 'LOGOUT'});
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-evenly',
										alignItems: 'center',
										flexDirection: 'row',
										gap: '10px',
										alignContent: 'center',
									}}
								>
									<ExitToApp />
									<Box>Logga ut</Box>
								</Box>
							</Link>
						</Box>
					</Box>
				</Box>
			)}
			<Box
				sx={{
					borderTop: authContext.state.isAuthenticated ? 'none' : '0.8rem solid #4cae52',
					maxWidth: '1300px',
					margin: '0 auto',
					padding: '0.8rem 0',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Link to={'/'}>
					<img
						src="https://storage.googleapis.com/redmanagement/redm_logo_xl_horizontal.png"
						alt="logo"
						style={{width: '140px', marginLeft: '1rem'}}
					/>
				</Link>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						paddingTop: '1rem',
						marginLeft: '5rem',
					}}
				>
					<div className="nav-item">
						<Link className="nav-link" to="/tjänster">
							{t('topbar.services')}
						</Link>
					</div>
					<div className="nav-divider">|</div>
					<div className="nav-item">
						<Link className="nav-link" to="/projekt">
							{t('topbar.projects')}
						</Link>
					</div>
					<div className="nav-divider">|</div>
					<div className="nav-item">
						<Link className="nav-link" to="/nyheter">
							{t('topbar.news')}
						</Link>
					</div>
					<div className="nav-divider">|</div>
					<div className="nav-item">
						<Link className="nav-link" to="/om-oss">
							{t('topbar.about')}
						</Link>
					</div>
					<div className="nav-divider">|</div>
					<div className="nav-item">
						<Link className="nav-link" to="/karriär">
							{t('topbar.career')}
						</Link>
					</div>
					<div className="nav-divider">|</div>
					<div className="nav-item">
						<Link className="nav-link" to="/kontakt">
							{t('topbar.contact')}
						</Link>
					</div>
					<div className="nav-divider">|</div>
					<div className="nav-item">
						<Link
							className="nav-link"
							onClick={() => handleChangeLanguage('sv-SE')}
							style={{marginRight: '5px'}}
						>
							<img src={sv_flag} alt="Svenska" width="16px" height="11px" />
						</Link>
						<Link className="nav-link" onClick={() => handleChangeLanguage('en-US')}>
							<img src={en_flag} alt="English" width="16px" height="11px" />
						</Link>
					</div>
					{!authContext.state.isAuthenticated && (
						<div className="nav-item">
							<Link className="nav-link" style={{color: '#4cae52'}} to="/login">
								{t('topbar.login')}
							</Link>
						</div>
					)}
				</div>
			</Box>
		</Box>
	);
}

import React from 'react';

import {BrowserRouter, Route, Switch} from 'react-router-dom';
import AboutPage from '../pages/About/AboutPage';
import CareersPage from '../pages/Careers/CareersPage';
import ContactPage from '../pages/Contact/ContactPage';
import CookiePolicyPage from '../pages/CookiePolicy/CookiePolicyPage';
import IntegrityPolicyPage from '../pages/IntegrityPolicy/IntegrityPolicyPage';
import PostsPage from '../pages/News/PostsPage';
import ServicesPage from '../pages/Services/ServicesPage';
import ProjectsPage from '../pages/Project/ProjectsPage';
import StartPage from '../pages/Start/StartPage';
import LoginPage from '../pages/Login/LoginPage';
import EditPostPage from '../pages/News/EditPostPage';
import EditProjectPage from '../pages/Project/EditProjectPage';
import ViewProjectPage from '../pages/Project/ViewProjectPage';
import ScrollToTop from '../utils/ScrollToTop';
import DesktopTopbar from './DesktopTopbar';
import MobileTopbarV2 from './MobileTopbarV2';
import Footer from './Footer';
import ViewJobDetailPage from '../pages/Careers/ViewJobDetailPage';
import {CssBaseline, Slide} from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ViewPostPage from '../pages/News/ViewPostPage';
import GoogleAnalytics from '../GoogleAnalytics';
import ContactsPage from '../pages/Contacts/ContactsPage';
import EditServicePage from '../pages/Services/EditServicePage';
import ViewServicePage from '../pages/Services/ViewServicePage';
import IntranetPage from '../pages/Intranet/IntranetPage';
import ViewInternalPostPage from '../pages/Intranet/ViewInternalPostPage';
import EditInternalPostPage from '../pages/Intranet/EditInternalPostPage';
import useMediaQuery from '@mui/material/useMediaQuery';

function HideOnScroll(props) {
	const {children, window} = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		threshold: 20,
	});
	return (
		<Slide appear={true} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}
export const Main = props => {
	const isMobileScreen = useMediaQuery('(max-width:700px)');

	return (
		<BrowserRouter>
			<ScrollToTop />
			<CssBaseline />
			{isMobileScreen ? (
				<MobileTopbarV2 />
			) : (
				<HideOnScroll {...props}>
					<nav>
						<DesktopTopbar />
					</nav>
				</HideOnScroll>
			)}

			<Switch>
				<Route path="/cookiepolicy" component={CookiePolicyPage} />
				<Route path="/integritetspolicy" component={IntegrityPolicyPage} />
				<Route path="/login" component={LoginPage} />

				<Route path="/om-oss" component={AboutPage} />
				<Route path="/karriär" component={CareersPage} />
				<Route path="/ledigajobb/:job_id" component={ViewJobDetailPage} />
				<Route exact path="/kontakt" component={ContactsPage} />
				<Route path="/kontakt/:email" component={ContactPage} />

				<Route exact path="/nyheter" component={PostsPage} />
				<Route path="/nyheter/:post_id" component={ViewPostPage} />
				<Route path="/redigeranyhet/:post_id" component={EditPostPage} />

				<Route exact path="/internwebb" component={IntranetPage} />
				<Route path="/internwebb/:post_id" component={ViewInternalPostPage} />
				<Route path="/editinternal/:post_id" component={EditInternalPostPage} />

				<Route exact path="/tjänster" component={ServicesPage} />
				<Route path="/services/:item_id" component={EditServicePage} />
				<Route path="/tjänster/:url" component={ViewServicePage} />

				<Route exact path="/projekt" component={ProjectsPage} />
				<Route path="/projects/:project_id" component={EditProjectPage} />
				<Route path="/projekt/:url" component={ViewProjectPage} />

				<Route path="/" component={StartPage} />
			</Switch>
			<GoogleAnalytics />
			<Footer />
		</BrowserRouter>
	);
};

export default Main;

import React, {useEffect, useState} from 'react';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	CircularProgress,
	Divider,
	Grid,
	ListItemText,
	MenuItem,
	MenuList,
} from '@mui/material';
import headerImg from '../../assets/highrise_detail.jpeg';
import {useTranslation} from 'react-i18next';
import {Add, Check, Clear, Delete, Edit} from '@mui/icons-material';
import api from '../../api';
import {LoadingButton} from '@mui/lab';
import {AuthContext} from '../../App';
import {useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PageHeader from '../../layout/PageHeader';
import {isAdmin} from '../../utils/Authentication';
import PageIntroTextEditor from '../../layout/PageIntroTextEditor';

export default function IntranetPage() {
	const [data, setData] = useState([]);
	const language = localStorage.getItem('i18nextLng');
	const [filteredData, setFilteredData] = useState([]);
	const today = new Date();
	const yearToday = today.getFullYear();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [loadingDeletePost, setLoadingDeletePost] = useState(false);
	const {t} = useTranslation();
	const [year, setYear] = useState(null);
	const [yearFilter, setYearFilter] = useState(false);
	const authContext = useContext(AuthContext);
	const [editing, isEditing] = useState(false);
	const [page, setPage] = useState({});
	const [notAllowed, setNotAllowed] = useState(false);
	const [newPost, setNewPost] = useState({
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		link: '',
		date: today.toLocaleDateString('sv-SE'),
		year: yearToday,
		published: true,
	});
	const baseRowData = {
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		link: '',
		date: today.toLocaleDateString('sv-SE'),
		year: yearToday,
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		published: true,
	};
	useEffect(() => {
		getPosts();
		getPage();
		document.title = 'RED Management • Internwebb';
	}, []);
	const getPosts = () => {
		api
			.get('/post/internalposts')
			.then(response => {
				if (response.data.success === false) {
					document.title = 'RED Management • 404';
					setNotAllowed(true);
					setTimeout(() => {
						history.push('/');
					}, 2000);
				} else {
					const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
					if (!isAdmin(authContext.state.email)) {
						const restrictedData = sortedData.filter(
							f => f.published || f.createdBy === authContext.state.email
						);
						setData(restrictedData);
						setFilteredData(restrictedData);
					} else {
						setData(sortedData);
						setFilteredData(sortedData);
					}

					setLoading(false);
				}
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};
	const getPage = () => {
		api
			.get('/pages/getone/' + 'internweb')
			.then(response => setPage(response.data))
			.catch(error => console.error(error));
	};

	const savePage = () => {
		api
			.put('/pages/edit/' + 'internweb', page)
			.then(response => isEditing(false))
			.catch(error => console.error(error));
	};
	const handleYear = y => {
		setYear(y);
		setYearFilter(true);
		setFilteredData(data.filter(n => n.year === y));
	};

	const deletePost = post => {
		api
			.delete('/post/post/' + post._id)
			.then(() => {
				setLoadingDeletePost(false);
				setNewPost(baseRowData);
				getPosts();
			})
			.catch(error => {
				setLoadingDeletePost(false);
				console.error(error);
			});
	};
	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={'Internwebb'} />
			{notAllowed && (
				<Grid item xs={12} sx={{padding: '2rem 0'}}>
					Du har saknar behörighet för att komma åt denna sida. Du dirigeras om...
				</Grid>
			)}
			{!notAllowed && (
				<PageIntroTextEditor
					data={page}
					setData={setPage}
					savePage={savePage}
					english={false}
					isEditing={isEditing}
					editing={editing}
				/>
			)}

			{!notAllowed && (
				<Grid item xs={12}>
					<Grid container style={{padding: 0}}>
						<Grid item xs={12} md={2} xl={2}>
							<MenuList>
								<MenuItem
									style={
										year === 2024
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2024)}
								>
									<ListItemText>2024</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2023
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2023)}
								>
									<ListItemText>2023</ListItemText>
								</MenuItem>
							</MenuList>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							xl={10}
							sx={{
								padding: {
									xs: '1rem 10px',
									sm: '0 0 0 10px',
									md: '0 0 1rem 1rem',
									xl: '0 0 1rem 1rem',
								},
							}}
						>
							<Grid container spacing={2} style={{padding: '0 10px'}}>
								{authContext.state.isAuthenticated && (
									<Grid
										item
										xs={12}
										style={{
											padding: '1rem 10px',
											textAlign: 'right',
											display: 'flex',
											justifyContent: 'right',
											alignContent: 'center',
											alignItems: 'center',
											gap: '1rem',
										}}
									>
										<Button
											startIcon={<Add />}
											onClick={() => {
												api
													.post('/post/internalpost', {
														swedishTitle: '',
														swedishSubtitle: '',
														swedishText: '',
														swedishQuote: '',
														englishTitle: '',
														englishSubtitle: '',
														englishText: '',
														englishQuote: '',
														showOnStartPage: true,
														link: '',
														date: '',
														year: '',
														pictureUrl: '',
														pictureDescriptionSwedish: '',
														pictureDescriptionEnglish: '',
														pictures: [],
														published: false,
														createdBy: authContext.state.email,
														createdDate: new Date(Date.now()),
														editedBy: authContext.state.email,
														editedDate: new Date(Date.now()),
													})
													.then(response => history.push('/editinternal/' + response.data._id))
													.catch(error => console.error(error));
											}}
											variant="contained"
											size="large"
											style={{
												background: '#4cae52',
												margin: '1rem 0 0 0',
												textTransform: 'none',
												fontFamily: 'Titillium Web',
											}}
										>
											Skapa ny
										</Button>
									</Grid>
								)}
								{loading ? (
									<LoadingComponent loadingText={t('loading.loadingNews')} />
								) : (
									<>
										{filteredData.map((d, index) => (
											<Grid
												item
												xs={12}
												key={index}
												sx={{
													padding: {
														xs: 0,
														sm: '0 0 0 10px',
														md: '0 0 1rem 1rem',
														xl: '0 0 1rem 1rem',
													},
												}}
											>
												<Card style={{color: 'black'}}>
													{d.pictureUrl?.length > 0 ? (
														<CardMedia
															component="img"
															image={d.pictureUrl}
															sx={{width: {xs: '100%', md: '50%'}}}
															alt={d._id}
															title={language === 'sv' ? d.swedishTitle : d.englishTitle}
														></CardMedia>
													) : d.pictures.length > 0 ? (
														<CardMedia
															component="img"
															image={d.pictures[0].url}
															sx={{width: {xs: '100%', md: '50%'}}}
															alt={d._id}
															title={language === 'sv' ? d.swedishTitle : d.englishTitle}
														></CardMedia>
													) : (
														<div></div>
													)}

													<CardContent
														style={{cursor: 'pointer'}}
														onClick={() => history.push('/internwebb/' + d._id)}
													>
														<Link to={'/internwebb/' + d._id}>
															<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>
																{new Date(d.date).toISOString().slice(0, 10)}
															</h2>

															{d.swedishTitle !== '' && d.swedishTitle?.length > 0 && (
																<>
																	<h2 style={{color: '#000000', margin: 0, padding: 0}}>
																		{d.swedishTitle}
																	</h2>
																	<Divider
																		style={{width: '100%', color: 'gray', margin: '10px auto 0'}}
																	/>

																	<p style={{fontSize: '1.1rem', padding: '0'}}>
																		{d.swedishSubtitle}
																	</p>
																</>
															)}
														</Link>
													</CardContent>

													{confirmDelete && newPost._id === d._id ? (
														<CardActions
															sx={{
																padding: '10px',
																display: 'flex',
																justifyContent: 'right',
																flexWrap: 'wrap',
																gap: {xs: '10px', md: '1rem'},
															}}
														>
															<h3>Är du säker på att du vill radera denna nyhet?</h3>

															<LoadingButton
																variant="contained"
																color="primary"
																startIcon={<Check />}
																loading={loadingDeletePost}
																style={{
																	marginRight: '1rem',
																	textTransform: 'none',
																	fontFamily: 'Titillium Web',
																}}
																onClick={() => {
																	setLoadingDeletePost(true);
																	deletePost(d);
																}}
															>
																Ja, radera
															</LoadingButton>
															<Button
																variant="outlined"
																startIcon={<Clear />}
																style={{
																	color: 'black',
																	textTransform: 'none',
																	fontFamily: 'Titillium Web',
																}}
																onClick={() => {
																	setConfirmDelete(false);
																}}
															>
																Nej, avbryt
															</Button>
														</CardActions>
													) : (
														<CardActions
															sx={{
																padding: '10px',
																display: 'flex',
																justifyContent: 'space-between',
																flexWrap: 'wrap',
																gap: {xs: '10px', md: '1rem'},
															}}
														>
															<Button
																variant="contained"
																style={{
																	textTransform: 'none',
																	background: '#4cae52',
																	color: 'white',
																}}
																onClick={() => history.push('/internwebb/' + d._id)}
															>
																Läs mer
															</Button>
															<Box
																sx={{
																	display: 'flex',
																	flexWrap: 'wrap',
																	gap: {xs: '10px', md: '1rem'},
																	justifyContent: 'space-between',
																}}
															>
																<Chip
																	label={
																		d.published ? 'Publicerad' : 'Enbart synlig för administratörer'
																	}
																	sx={{
																		color: d.published ? '#ffffff' : '#4cae52',
																		background: d.published ? '#4cae52' : '#ebebeb',
																	}}
																/>
																<Chip
																	label={'Skapad av ' + d.createdBy}
																	style={{
																		color: d.published ? '#ffffff' : '#4cae52',
																		background: d.published ? '#4cae52' : '#ebebeb',
																	}}
																/>
															</Box>
															<Box
																sx={{
																	display: 'flex',
																	flexWrap: 'wrap',
																	gap: {xs: '10px', md: '1rem'},
																	justifyContent: 'space-between',
																}}
															>
																{(isAdmin(authContext.state.email) ||
																	(!d.published && authContext.state.email === d.createdBy)) && (
																	<Button
																		variant="contained"
																		startIcon={<Edit />}
																		color="primary"
																		style={{
																			textTransform: 'none',
																			fontFamily: 'Titillium Web',
																		}}
																		onClick={() => {
																			history.push('/editinternal/' + d._id);
																		}}
																	>
																		Redigera
																	</Button>
																)}
																{(isAdmin(authContext.state.email) ||
																	(!d.published && authContext.state.email === d.createdBy)) && (
																	<LoadingButton
																		variant="contained"
																		startIcon={<Delete />}
																		style={{
																			background: '#FFCCCB',
																			color: 'black',
																			textTransform: 'none',
																			fontFamily: 'Titillium Web',
																		}}
																		onClick={() => {
																			setNewPost(d);
																			setConfirmDelete(true);
																		}}
																	>
																		Radera
																	</LoadingButton>
																)}
															</Box>
														</CardActions>
													)}
												</Card>
											</Grid>
										))}
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}

export function isAdmin(email) {
	return [
		'jorgen.rahm@redmanagement.se',
		'par.hammarberg@redmanagement.se',
		'sophie.lofving@redmanagement.se',
		'per.karlsten@redmanagement.se',
		'per.linder@redmanagement.se',
		'magnus.eriksson@redmanagement.se',
	].includes(email);
}

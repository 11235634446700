import React, {useEffect, useState} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	CircularProgress,
	Box,
	Divider,
	FormControl,
	Grid,
	ListItemText,
	MenuItem,
	MenuList,
	TextField,
} from '@mui/material';
import headerImg from '../../assets/highrise_detail.jpeg';
import {useTranslation} from 'react-i18next';
import {Add, Check, Clear, Delete, Edit} from '@mui/icons-material';
import api from '../../api';
import {LoadingButton} from '@mui/lab';
import {AuthContext} from '../../App';
import {useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PageHeader from '../../layout/PageHeader';
import {isAdmin} from '../../utils/Authentication';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function PostsPage() {
	const [data, setData] = useState([]);
	const language = localStorage.getItem('i18nextLng');

	const [filteredData, setFilteredData] = useState([]);
	const today = new Date();
	const yearToday = today.getFullYear();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [loadingDeletePost, setLoadingDeletePost] = useState(false);
	const {t} = useTranslation();
	const [year, setYear] = useState(null);
	const [yearFilter, setYearFilter] = useState(false);
	const authContext = useContext(AuthContext);
	const isMobileScreen = useMediaQuery('(max-width:700px)');

	const [newPost, setNewPost] = useState({
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		link: '',
		date: today.toLocaleDateString('sv-SE'),
		year: yearToday,
		published: true,
	});
	const baseRowData = {
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		link: '',
		date: today.toLocaleDateString('sv-SE'),
		year: yearToday,
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		published: true,
	};
	useEffect(() => {
		getPosts();
		document.title = 'RED Management • ' + t('topbar.news');
	}, []);
	const getPosts = () => {
		api
			.get('/post/posts')
			.then(response => {
				const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
				if (authContext.state.isAuthenticated) {
					setData(sortedData);
					setFilteredData(sortedData);
				} else {
					setData(sortedData.filter(r => r.published));
					setFilteredData(sortedData.filter(r => r.published));
				}
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleYear = y => {
		setYear(y);
		setYearFilter(true);
		setFilteredData(data.filter(n => n.year === y));
	};

	const deletePost = post => {
		api
			.delete('/post/post/' + post._id)
			.then(() => {
				setLoadingDeletePost(false);
				setNewPost(baseRowData);
				getPosts();
			})
			.catch(error => {
				setLoadingDeletePost(false);
				console.error(error);
			});
	};

	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={t('topbar.news')} />

			<Grid item xs={12}>
				<Grid container style={{padding: 0}}>
					{isMobileScreen ? (
						<Grid item xs={12} sx={{padding: '10px 1rem'}}>
							<FormControl fullWidth margin="dense">
								<TextField
									defaultValue={2024}
									disabled={false}
									InputLabelProps={{
										shrink: true,
									}}
									label={t('newspage.selectyear')}
									onChange={event => handleYear(event.target.value)}
									select
									size="small"
									value={year}
									variant="outlined"
								>
									{[
										2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
										2011,
									].map((i, index) => (
										<MenuItem key={index} value={i}>
											{i}
										</MenuItem>
									))}
								</TextField>
							</FormControl>
						</Grid>
					) : (
						<Grid item xs={12} md={2} xl={2}>
							<MenuList>
								<MenuItem
									style={
										year === 2024
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2024)}
								>
									<ListItemText>2024</ListItemText>
								</MenuItem>

								<MenuItem
									style={
										year === 2023
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2023)}
								>
									<ListItemText>2023</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2022
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2022)}
								>
									<ListItemText>2022</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2021
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2021)}
								>
									<ListItemText>2021</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2020
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2020)}
								>
									<ListItemText>2020</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2019
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2019)}
								>
									<ListItemText>2019</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2018
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2018)}
								>
									<ListItemText>2018</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2017
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2017)}
								>
									<ListItemText>2017</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2016
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2016)}
								>
									<ListItemText>2016</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2015
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2015)}
								>
									<ListItemText>2015</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2014
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2014)}
								>
									<ListItemText>2014</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2013
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2013)}
								>
									<ListItemText>2013</ListItemText>
								</MenuItem>
								<MenuItem
									style={
										year === 2012
											? {
													background: '#4cae52',
													fontWeight: 'bold',
													color: 'white',
											  }
											: {fontWeight: 'normal', color: 'black'}
									}
									onClick={() => handleYear(2012)}
								>
									<ListItemText>2012</ListItemText>
								</MenuItem>
							</MenuList>
						</Grid>
					)}

					<Grid
						item
						xs={12}
						md={10}
						xl={10}
						sx={{
							padding: {
								xs: '1rem 10px',
								sm: '0 0 0 10px',
								md: '0 0 1rem 1rem',
								xl: '0 0 1rem 1rem',
							},
						}}
					>
						<Grid container spacing={2} style={{padding: '1rem'}}>
							{authContext.state.isAuthenticated && (
								<Grid
									item
									xs={12}
									style={{
										padding: '1rem 10px',
										textAlign: 'right',
										display: 'flex',
										justifyContent: 'right',
										alignContent: 'center',
										alignItems: 'center',
										gap: '1rem',
									}}
								>
									<Button
										startIcon={<Add />}
										onClick={() => {
											api
												.post('/post/post', {
													swedishTitle: '',
													swedishSubtitle: '',
													swedishText: '',
													swedishQuote: '',
													englishTitle: '',
													englishSubtitle: '',
													englishText: '',
													englishQuote: '',
													showOnStartPage: true,
													link: '',
													date: new Date(Date.now()),
													year: '',
													pictureUrl: '',
													pictureDescriptionSwedish: '',
													pictureDescriptionEnglish: '',
													pictures: [],
													published: false,
													createdBy: authContext.state.email,
													createdDate: new Date(Date.now()),
													editedBy: authContext.state.email,
													editedDate: new Date(Date.now()),
												})
												.then(response => history.push('/redigeranyhet/' + response.data._id))
												.catch(error => console.error(error));
										}}
										variant="contained"
										size="large"
										style={{
											background: '#4cae52',
											margin: '1rem 0 0 0',
											textTransform: 'none',
											fontFamily: 'Titillium Web',
										}}
									>
										Skapa ny
									</Button>
								</Grid>
							)}
							{loading ? (
								<LoadingComponent loadingText={t('loading.loadingNews')} />
							) : (
								<>
									{filteredData.map((d, index) => (
										<Grid
											item
											xs={12}
											key={index}
											sx={{
												padding: {
													xs: 0,
													sm: '0 0 0 10px',
													md: '0 0 1rem 1rem',
													xl: '0 0 1rem 1rem',
												},
											}}
										>
											<Card style={{color: 'black'}}>
												{d.pictureUrl?.length > 0 ? (
													<CardMedia
														component="img"
														image={d.pictureUrl}
														sx={{width: {xs: '100%', md: '50%'}}}
														alt={d._id}
														title={language === 'sv' ? d.swedishTitle : d.englishTitle}
													></CardMedia>
												) : d.pictures.length > 0 ? (
													<CardMedia
														component="img"
														image={d.pictures[0].url}
														sx={{width: {xs: '100%', md: '50%'}}}
														alt={d._id}
														title={language === 'sv' ? d.swedishTitle : d.englishTitle}
													></CardMedia>
												) : (
													<div></div>
												)}

												<CardContent
													style={{cursor: 'pointer'}}
													onClick={() => history.push('/nyheter/' + d._id)}
												>
													<Link to={'/nyheter/' + d._id}>
														<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>
															{new Date(d.date).toISOString().slice(0, 10)}
														</h2>
														{language === 'sv' || authContext.state.isAuthenticated ? (
															<>
																<h2 style={{color: '#000000', margin: 0, padding: 0}}>
																	{d.swedishTitle}
																</h2>
																<Divider
																	style={{width: '100%', color: 'gray', margin: '10px auto 0'}}
																/>

																<p style={{fontSize: '1.1rem', padding: '0'}}>
																	{d.swedishSubtitle || d.swedishText.slice(0, 300)}
																</p>
															</>
														) : (
															<>
																{d.englishTitle !== '' && d.englishTitle?.length > 0 && (
																	<>
																		<h2 style={{color: '#000000', margin: 0, padding: 0}}>
																			{d.englishTitle}
																		</h2>
																		<Divider
																			style={{width: '100%', color: 'gray', margin: '10px auto 0'}}
																		/>

																		<p style={{fontSize: '1.1rem', padding: '0'}}>
																			{d.englishSubtitle}
																		</p>
																	</>
																)}
															</>
														)}
													</Link>
												</CardContent>

												{authContext.state.isAuthenticated ? (
													<>
														{confirmDelete && newPost._id === d._id ? (
															<CardActions
																sx={{
																	padding: '10px',
																	display: 'flex',
																	justifyContent: 'right',
																	flexWrap: 'wrap',
																	gap: {xs: '10px', md: '1rem'},
																}}
															>
																<h3>Är du säker på att du vill radera denna nyhet?</h3>

																<LoadingButton
																	variant="contained"
																	color="primary"
																	startIcon={<Check />}
																	loading={loadingDeletePost}
																	style={{
																		marginRight: '1rem',
																		textTransform: 'none',
																		fontFamily: 'Titillium Web',
																	}}
																	onClick={() => {
																		setLoadingDeletePost(true);
																		deletePost(d);
																	}}
																>
																	Ja, radera
																</LoadingButton>
																<Button
																	variant="outlined"
																	startIcon={<Clear />}
																	style={{
																		color: 'black',
																		textTransform: 'none',
																		fontFamily: 'Titillium Web',
																	}}
																	onClick={() => {
																		setConfirmDelete(false);
																	}}
																>
																	Nej, avbryt
																</Button>
															</CardActions>
														) : (
															<CardActions
																sx={{
																	padding: '10px',
																	display: 'flex',
																	justifyContent: 'space-between',
																	flexWrap: 'wrap',
																	gap: {xs: '10px', md: '1rem'},
																}}
															>
																<Button
																	variant="contained"
																	style={{
																		textTransform: 'none',
																		background: '#4cae52',
																		color: 'white',
																	}}
																	onClick={() => history.push('/nyheter/' + d._id)}
																>
																	Läs mer
																</Button>
																<Box
																	sx={{
																		display: 'flex',
																		flexWrap: 'wrap',
																		gap: {xs: '10px', md: '1rem'},
																		justifyContent: 'space-between',
																	}}
																>
																	<Chip
																		label={
																			d.showOnStartPage
																				? 'Visas på startsida'
																				: 'Visas ej på startsida'
																		}
																		style={{
																			color: d.showOnStartPage ? '#ffffff' : '#4cae52',
																			background: d.showOnStartPage ? '#4cae52' : '#ffffff',
																		}}
																	/>
																	<Chip
																		label={d.published ? 'Publicerad' : 'Ej publicerad'}
																		style={{
																			color: d.published ? '#ffffff' : '#4cae52',
																			background: d.published ? '#4cae52' : '#ffffff',
																		}}
																	/>
																</Box>
																<Box
																	sx={{
																		display: 'flex',
																		flexWrap: 'wrap',
																		gap: {xs: '10px', md: '1rem'},
																		justifyContent: 'space-between',
																	}}
																>
																	{(isAdmin(authContext.state.email) ||
																		(!d.published && authContext.state.email === d.createdBy)) && (
																		<Button
																			variant="contained"
																			startIcon={<Edit />}
																			color="primary"
																			style={{
																				textTransform: 'none',
																				fontFamily: 'Titillium Web',
																			}}
																			onClick={() => {
																				history.push('/redigeranyhet/' + d._id);
																			}}
																		>
																			Redigera
																		</Button>
																	)}
																	{(isAdmin(authContext.state.email) ||
																		(!d.published && authContext.state.email === d.createdBy)) && (
																		<LoadingButton
																			variant="contained"
																			startIcon={<Delete />}
																			style={{
																				background: '#FFCCCB',
																				color: 'black',
																				textTransform: 'none',
																				fontFamily: 'Titillium Web',
																			}}
																			onClick={() => {
																				setNewPost(d);
																				setConfirmDelete(true);
																			}}
																		>
																			Radera
																		</LoadingButton>
																	)}
																</Box>
															</CardActions>
														)}
													</>
												) : (
													<CardActions>
														<Button
															variant="contained"
															style={{
																textTransform: 'none',
																background: '#4cae52',
																color: 'white',
															}}
															onClick={() => history.push('/nyheter/' + d._id)}
														>
															{language === 'sv' ? 'Läs mer' : 'Read more'}
														</Button>
													</CardActions>
												)}
											</Card>
										</Grid>
									))}
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

import React, {useEffect} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import headerImg from '../../assets/contact.jpg';
import vcf from '../../assets/vcf.png';
import ics from '../../assets/ics.png';
import redm_logo from '../../assets/redm.png';
import {Delete} from '@mui/icons-material';

const VCard = require('vcard-creator').default;
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {useState} from 'react';
import api from '../../api';
import {
	Add,
	ArrowDownward,
	ArrowUpward,
	CameraAlt,
	Check,
	Clear,
	Edit,
	MailOutline,
	Phone,
	Place,
	QrCode,
	RestartAlt,
	Sort,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import {useContext} from 'react';
import {AuthContext} from '../../App';
import {LoadingButton} from '@mui/lab';
import {useRef} from 'react';
import QRCode from 'react-qr-code';
import {Link} from 'react-router-dom';
import {exportContact} from '../../services/VCardExportService';
import PageHeader from '../../layout/PageHeader';
import EditContact from '../Contact/EditContact';
import {isAdmin} from '../../utils/Authentication';
import TextEditor from '../../components/TextEditor/TextEditor';
import PageIntro from '../../layout/PageIntro';
import parse from 'html-react-parser';
export default function ContactsPage(props) {
	const authContext = useContext(AuthContext);
	const language = localStorage.getItem('i18nextLng');
	const [showQrCode, setShowQrCode] = useState(false);
	const [loadingExport, isLoadingExport] = useState(false);
	const [editText, setEditText] = useState(false);

	const {t} = useTranslation();
	const refArray = React.useRef([]);
	const [data, setData] = useState([]);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [creatingNew, setCreatingNew] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [sorterDict, setSorterDict] = useState({});
	const [sortType, setSortType] = useState('firstName');
	const [editing, setEditing] = useState(false);
	const [loadingNew, setLoadingNew] = useState(false);
	const inputRef = useRef();
	const [showPassword, setShowPassword] = useState(false);
	const [selected, setSelected] = useState({
		_id: '',
		firstName: '',
		lastName: '',
		fullName: '',
		swedishRole: '',
		englishRole: '',
		systemRole: '',
		swedishText: '',
		englishText: '',
		email: '',
		displayEmail: '',
		mailTo: '',
		phone: '',
		displayPhone: '',
		tel: '',
		pictureUrl: '',
		password: '',
	});

	const baseRowData = {
		_id: '',
		firstName: '',
		lastName: '',
		fullName: '',
		swedishRole: '',
		englishRole: '',
		systemRole: '',
		swedishText: '',
		englishText: '',
		email: '',
		displayEmail: '',
		mailTo: '',
		phone: '',
		displayPhone: '',
		tel: '',
		pictureUrl: '',
	};
	const [contactThatIsBeingExported, setContactThatIsBeingExported] = useState(false);
	const [loading, isLoading] = useState(true);
	useEffect(() => {
		getContacts();
		getPage();
		document.title = 'RED Management • ' + t('topbar.contact');
	}, []);
	const [editingText, isEditingText] = useState(false);
	const [page, setPage] = useState({});
	const resetSorter = () => {
		let newSorter = {...sorterDict};
		Object.keys(newSorter).map(n => (newSorter[n] = undefined));
		setSorterDict(newSorter);
	};

	const deleteContact = x => {
		api
			.delete('/person/person/' + x._id)
			.then(() => {
				setLoadingDelete(false);
				setSelected(baseRowData);
				getContacts();
			})
			.catch(error => {
				setLoadingDelete(false);
				console.error(error);
			});
	};
	const sortDataBy = key => {
		let newData = [...data];
		let newSorter = {...sorterDict};
		Object.keys(newSorter).map(n => (newSorter[n] = undefined));
		if (key === 'firstName') {
			setSortType('firstName');
			newData = newData.sort((a, b) => a.firstName.localeCompare(b.firstName));
		} else if (key === 'lastName') {
			setSortType('lastName');
			newData = newData.sort((a, b) => a.lastName.localeCompare(b.lastName));
		}
		if (sorterDict[key]) {
			newData = newData.reverse();
			newSorter[key] = false;
		} else if (!newSorter[key]) {
			newSorter[key] = true;
		}
		setData(newData);
		setSorterDict(newSorter);
	};

	const getArrow = key => {
		if (!(sorterDict[key] === undefined)) {
			if (!sorterDict[key]) {
				return <ArrowDownward />; // Down Arrow
			} else {
				return <ArrowUpward />; // Up Arrow
			}
		} else {
			return <Sort />;
		}
	};

	const getContacts = () => {
		api
			.get('/person/persons')
			.then(response => {
				setData(response.data.sort((a, b) => a.firstName.localeCompare(b.firstName)));
				isLoading(false);
			})
			.catch(error => {
				console.error(error);
				isLoading(false);
			});
	};
	const getPage = () => {
		api
			.get('/pages/getone/kontakt')
			.then(response => setPage(response.data))
			.catch(error => console.error(error));
	};

	const savePage = () => {
		api
			.put('/pages/edit/kontakt', page)
			.then(response => isEditing(false))
			.catch(error => console.error(error));
	};
	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={t('topbar.contact')} />
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12} md={6} xl={6} style={{alignItems: 'center', padding: '0 1rem'}}>
						<p style={{lineHeight: '7px'}}>
							<strong>{t('contactpage.address')}</strong>
						</p>
						<p style={{lineHeight: '15px'}}>
							<Tooltip title={t('footer.directionstooltip')} placement="left-start">
								<Grid
									sx={{
										display: 'flex',
										justifyContent: {xs: 'left', md: 'left', xl: 'left'},
									}}
									className="hover-link"
								>
									<Place fontSize="small" style={{marginRight: '10px'}} />
									<Typography
										variant="body2"
										style={{
											fontFamily: 'Titillium Web',
										}}
									>
										<a href="https://goo.gl/maps/sxpHZsCQoJrcaG7S7">
											Vasagatan 15-17, 111 20 Stockholm
										</a>
									</Typography>
								</Grid>
							</Tooltip>
						</p>
						<p style={{lineHeight: '7px'}}>
							<strong>{t('contactpage.phone')}</strong>
						</p>
						<p style={{lineHeight: '15px'}}>
							<Tooltip title={t('footer.callus')} placement="left-start">
								<Grid
									sx={{
										display: 'flex',
										justifyContent: {xs: 'left', md: 'left', xl: 'left'},
									}}
									className="hover-link"
								>
									<Phone fontSize="small" style={{marginRight: '10px'}} />
									<Typography
										variant="body2"
										style={{
											fontFamily: 'Titillium Web',
										}}
									>
										<a href="tel:0046730858880">{t('footer.phone')}</a>
									</Typography>
								</Grid>
							</Tooltip>
						</p>
						<p style={{lineHeight: '7px'}}>
							<strong>{t('contactpage.email')}</strong>
						</p>
						<p style={{lineHeight: '15px'}}>
							<Tooltip title={t('footer.sendemail')} placement="left-start">
								<Grid
									sx={{
										display: 'flex',
										justifyContent: {xs: 'left', md: 'left', xl: 'left'},
									}}
									className="hover-link"
								>
									<MailOutline fontSize="small" style={{marginRight: '10px'}} />
									<Typography
										variant="body2"
										style={{
											fontFamily: 'Titillium Web',
										}}
									>
										<a href="mailto:info@redmanagement.se">info[a]redmanagement.se</a>
									</Typography>
								</Grid>
							</Tooltip>
						</p>
						<Divider style={{width: '100%', color: 'gray', margin: '1rem auto 0'}} />{' '}
						<Grid container>
							{isAdmin(authContext.state.email) && (
								<React.Fragment>
									{editingText && (
										<Grid item xs={12} md={6}>
											<TextEditor
												setText={newText => setPage({...page, textSwedish: newText})}
												text={page.textSwedish}
											/>
										</Grid>
									)}
									{editingText && (
										<Grid item xs={12} md={6}>
											<TextEditor
												setText={newText => setPage({...page, textEnglish: newText})}
												text={page.textEnglish}
											/>
										</Grid>
									)}
								</React.Fragment>
							)}
							{!editingText && (
								<Grid item xs={10}>
									{language === 'sv'
										? page.textSwedish !== null &&
										  page.textSwedish?.length > 0 &&
										  parse(page.textSwedish)
										: page.textEnglish !== null &&
										  page.textEnglish?.length > 0 &&
										  parse(page.textEnglish)}
								</Grid>
							)}

							{isAdmin(authContext.state.email) && (
								<Grid item xs={1} sx={{marginTop: '1rem', textAlign: 'right'}}>
									{editingText ? (
										<Button
											sx={{textTransform: 'none'}}
											variant="outlined"
											onClick={() => {
												savePage();
												isEditingText(false);
											}}
											startIcon={<Check />}
										>
											Spara
										</Button>
									) : (
										<Button
											size="small"
											sx={{textTransform: 'none', right: 0}}
											onClick={() => isEditingText(true)}
										>
											<Edit />
										</Button>
									)}
								</Grid>
							)}
						</Grid>
					</Grid>

					<Grid item xs={12} md={6} xl={6} style={{padding: '1rem 10px'}}>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d990.2152273820193!2d18.056413215893652!3d59.33323442195076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d60d7e6ef6b%3A0x55a2385c6d5531d1!2sVasagatan%2017%2C%20111%2020%20Stockholm!5e1!3m2!1sen!2sse!4v1667669654644!5m2!1sen!2sse"
							width="100%"
							height="450"
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</Grid>
					<Divider style={{width: '100%', color: 'gray', margin: '0 auto'}} />
					<Grid
						item
						xs={12}
						md={6}
						xl={6}
						style={{
							padding: '10px',
							textAlign: 'left',
							display: 'flex',
							justifyContent: 'left',
							alignContent: 'center',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						{t('contactpage.sortby')}
						<Button
							endIcon={getArrow('firstName')}
							style={{textAlign: 'left', textTransform: 'none'}}
							onClick={() => sortDataBy('firstName')}
						>
							{t('contactpage.firstname')}
						</Button>
						<Button
							endIcon={getArrow('lastName')}
							style={{textAlign: 'left', textTransform: 'none'}}
							onClick={() => sortDataBy('lastName')}
						>
							{t('contactpage.lastname')}
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						style={{
							padding: '10px',
							textAlign: 'right',
							display: 'flex',
							justifyContent: 'right',
							alignContent: 'center',
							alignItems: 'center',
							gap: '10px',
						}}
					>
						{authContext.state.isAuthenticated && (
							<Button
								startIcon={<Add />}
								variant="contained"
								size="large"
								style={{
									background: '#4cae52',
									textTransform: 'none',
									fontFamily: 'Titillium Web',
								}}
								onClick={() => {
									api
										.post('/person/person', selected)
										.then(response => {
											setSelected(response.data);
											setEditing(true);
										})
										.catch(error => console.error(error));
								}}
							>
								Skapa ny person
							</Button>
						)}
					</Grid>
					<Grid item xs={12} md={12} xl={12} style={{padding: '1rem 10px'}}>
						{loading ? (
							<CircularProgress />
						) : (
							<Grid container spacing={2}>
								{data.map((c, index) => (
									<Grid
										item
										xs={12}
										sm={6}
										md={3}
										xl={3}
										key={c._id}
										id={c.email}
										ref={ref => {
											refArray.current[index] = ref;
										}}
									>
										<Card>
											<CardMedia
												component="img"
												image={c.pictureUrlCropped}
												alt={c.fullName}
												sx={{width: {xs: '100%'},height:'355px'}}
											></CardMedia>
											<CardContent style={{height: '355px', fontSize: '0.9rem'}}>
												<Link to={'/kontakt/' + c.email}>
													<p style={{lineHeight: '3px', color: '#3EAC48', fontSize: '1.2rem'}}>
														{sortType === 'firstName' ? (
															<strong>{c.firstName + ' ' + c.lastName}</strong>
														) : (
															<strong>{c.lastName + ' ' + c.firstName}</strong>
														)}
													</p>
												</Link>

												<p> {language === 'sv' ? c.swedishText : c.englishText}</p>
												<p style={{lineHeight: '5px'}}>
													<strong>{t('contactpage.phone')}</strong>
												</p>
												<p style={{lineHeight: '3px'}}>
													<a href={c.tel}>{c.displayPhone}</a>
												</p>
												<p style={{lineHeight: '5px'}}>
													<strong>{t('contactpage.email')}</strong>
												</p>
												<p style={{lineHeight: '3px'}}>
													<a href={'mailto:' + c.email}>{c.email.replace('@', '[a]')}</a>
												</p>
											</CardContent>
											<CardActions>
												<Grid container spacing={1}>
													{!showQrCode && (
														<Grid
															item
															xs={12}
															sx={{display: 'flex', justifyContent: 'space-between'}}
														>
															<Tooltip
																title={t('contactpage.addtocontactstooltip')}
																placement="right"
															>
																<LoadingButton
																	loading={loadingExport}
																	onClick={() => exportContact(isLoadingExport, c)}
																	color="primary"
																	variant="outlined"
																	sx={{textTransform: 'none'}}
																	startIcon={<PersonAddAltIcon />}
																>
																	{t('contactpage.addtocontacts')}
																</LoadingButton>
															</Tooltip>
															<Tooltip title={language === 'sv' ? 'Visa QR-kod' : 'Show QR code'}>
																<IconButton
																	size="large"
																	onClick={() => {
																		setSelected(c);
																		setShowQrCode(true);
																	}}
																>
																	<QrCode />
																</IconButton>
															</Tooltip>
														</Grid>
													)}

													{authContext.state.isAuthenticated && (
														<>
															<Divider
																style={{width: '100%', background: '#4cae52', margin: '1rem 0 0'}}
															></Divider>
															<Grid item xs={12}>
																{confirmDelete && selected._id === c._id && (
																	<p style={{margin: 0, padding: 0}}>
																		Är du säker på att du vill radera denna person?
																	</p>
																)}
															</Grid>
															<Grid
																item
																xs={12}
																style={{display: 'flex', justifyContent: 'right', gap: '1rem'}}
															>
																{confirmDelete && selected._id === c._id ? (
																	<>
																		<LoadingButton
																			variant="contained"
																			startIcon={<Check />}
																			loading={loadingDelete}
																			style={{
																				textTransform: 'none',
																			}}
																			color="error"
																			onClick={() => {
																				setLoadingDelete(true);
																				deleteContact(c);
																			}}
																		>
																			Ja, radera
																		</LoadingButton>
																		<Button
																			variant="outlined"
																			startIcon={<Clear />}
																			style={{
																				textTransform: 'none',
																			}}
																			color="error"
																			onClick={() => {
																				setConfirmDelete(false);
																			}}
																		>
																			Nej, avbryt
																		</Button>
																	</>
																) : (
																	<>
																		{isAdmin(authContext.state.email) && (
																			<Button
																				variant="contained"
																				startIcon={<Edit />}
																				color="primary"
																				style={{
																					textTransform: 'none',
																					fontFamily: 'Titillium Web',
																				}}
																				onClick={() => {
																					setSelected(c);
																					setEditing(true);
																				}}
																			>
																				Redigera
																			</Button>
																		)}

																		{isAdmin(authContext.state.email) && (
																			<LoadingButton
																				variant="contained"
																				startIcon={<Delete />}
																				style={{
																					background: '#FFCCCB',
																					color: 'black',
																					textTransform: 'none',
																					fontFamily: 'Titillium Web',
																				}}
																				onClick={() => {
																					setSelected(c);
																					setConfirmDelete(true);
																				}}
																			>
																				Radera
																			</LoadingButton>
																		)}
																	</>
																)}
															</Grid>
														</>
													)}
												</Grid>
											</CardActions>
										</Card>
									</Grid>
								))}
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			{(editing || creatingNew) && (
				<EditContact
					creatingNew={creatingNew}
					editing={editing}
					setEditing={setEditing}
					setCreatingNew={setCreatingNew}
					updateTable={true}
					selected={selected}
					getContacts={getContacts}
				/>
			)}

			{showQrCode && (
				<Dialog
					open={showQrCode}
					onClose={() => {
						setShowQrCode(false);
					}}
					sx={{textAlign: 'center', padding: '2rem'}}
				>
					<img src={redm_logo} width="150" style={{margin: '1rem auto 0'}} />
					<DialogTitle>{selected.firstName + ' ' + selected.lastName}</DialogTitle>

					<DialogContent sx={{textAlign: 'center', padding: '1rem'}}>
						<Typography variant="body1">{selected.email}</Typography>
						<Typography variant="body1">{selected.displayPhone}</Typography>
						<QRCode
							size={256}
							style={{height: 'auto', maxWidth: '100%', width: '100%', marginTop: '1rem'}}
							value={'https://redmanagement.se/kontakt/' + selected.email}
							viewBox={`0 0 256 256`}
						/>
					</DialogContent>
				</Dialog>
			)}
		</Grid>
	);
}

import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {Grid} from '@mui/material';
const TextEditor = ({text, setText}) => {
	return (
		<ReactQuill
			theme="snow"
			value={text}
			onChange={(text, delta, source, editor) => {
				if (source == 'user') {
					// place whatever function you want to execute when user types here:
					setText(text);
				}
			}}
		/>
	);
};
export default TextEditor;

import React, {useContext, useEffect, useState} from 'react';
import {Button, Divider, FormHelperText, Grid, TextField, FormControl} from '@mui/material';
import {useTranslation} from 'react-i18next';
import headerImg from '../../assets/concrete_windows.jpg';
import PageHeader from '../../layout/PageHeader';
import PageIntro from '../../layout/PageIntro';

import api from '../../api';
import TextEditor from '../../components/TextEditor/TextEditor';
import parse from 'html-react-parser';

export default function AboutPage() {
	const language = localStorage.getItem('i18nextLng');

	const {t} = useTranslation();
	const [data, setData] = useState({});
	const [editing, isEditing] = useState(false);
	useEffect(() => {
		document.title = 'RED Management • ' + t('topbar.about');
		getPage();
	}, []);

	const getPage = () => {
		api
			.get('/pages/getone/' + 'om-oss')
			.then(response => setData(response.data))
			.catch(error => console.error(error));
	};

	const savePage = () => {
		api
			.put('/pages/edit/' + 'om-oss', data)
			.then(response => isEditing(false))
			.catch(error => console.error(error));
	};

	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={t('topbar.about')} />
			<PageIntro
				data={data}
				setData={setData}
				editing={editing}
				isEditing={isEditing}
				savePage={savePage}
			/>
			<Grid container spacing={2} sx={{padding: '0 1rem'}}>
				{editing ? (
					<React.Fragment>
						<Grid item xs={12} md={6}>
							<TextEditor
								setText={newText => setData({...data, textSwedish: newText})}
								text={data.textSwedish}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextEditor
								setText={newText => setData({...data, textEnglish: newText})}
								text={data.textEnglish}
							/>
						</Grid>
					</React.Fragment>
				) : (
					<Grid item xs={12}>
						{language === 'sv' ? (
							<React.Fragment>
								{data.textSwedish !== null &&
									data.textSwedish?.length > 0 &&
									parse(data.textSwedish)}
							</React.Fragment>
						) : (
							<React.Fragment>
								{data.textEnglish !== null &&
									data.textEnglish?.length > 0 &&
									parse(data.textEnglish)}
							</React.Fragment>
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}

import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useContext, useState} from 'react';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import {
	AccountBalanceOutlined,
	AccountBoxOutlined,
	Add,
	AddShoppingCartOutlined,
	AttachMoneyOutlined,
	Business,
	DescriptionOutlined,
	ExitToApp,
	ShoppingBag,
	LocalShipping,
	FmdBad,
	KeyboardDoubleArrowUp,
	MenuOutlined,
	Person,
	StorefrontOutlined,
	WidgetsOutlined,
	TripOrigin,
	ArrowRight,
	Group,
	Settings,
	Lock,
} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {AuthContext} from '../App';
import redm_logo from '../assets/redm.png';
import {Grid} from '@mui/material';
import sv_flag from '../assets/sv_flag.png';
import en_flag from '../assets/en_flag.png';
import i18next from 'i18next';

export default function MobileTopbarV2() {
	const [open, setOpen] = React.useState(false);

	const {t} = useTranslation();
	const authContext = useContext(AuthContext);
	const {state, dispatch} = React.useContext(AuthContext);

	const toggleDrawer = newValue => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setOpen(newValue);
	};
	const handleChangeLanguage = newLanguage => {
		localStorage.setItem('redmanagement-language', newLanguage);
		i18next
			.changeLanguage(newLanguage.split('-')[0], (err, t) => {
				if (err) return console.log('language changing failed.', err);
				window.location.reload();
			})
			.then(r => console.log(r));
	};
	return (
		<Box sx={{padding: '10px 2rem 10px 1rem', width: '100%'}} className="mobile">
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					alignContent: 'center',
				}}
			>
				<Button onClick={toggleDrawer(true)} sx={{color: '#4cae52'}}>
					<MenuOutlined />
				</Button>
				<Link to="/">
					<img src={redm_logo} alt="logo" width="100" />
				</Link>
				<Box
					sx={{
						padding: '10px 10px',
						textAlign: 'center',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						alignContent: 'center',
					}}
				>
					<Link
						className="nav-link"
						onClick={() => handleChangeLanguage('sv-SE')}
						style={{marginRight: '1rem'}}
					>
						<img src={sv_flag} alt="Svenska" width="16px" height="11px" />
					</Link>
					<Link className="nav-link" onClick={() => handleChangeLanguage('en-US')}>
						<img src={en_flag} alt="English" width="16px" height="11px" />
					</Link>
				</Box>
			</Box>

			<SwipeableDrawer
				anchor="top"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
			>
				<Box
					role="presentation"
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
					sx={{width: '100%'}}
				>
					<List sx={{width: '100%'}}>
						<ListItem
							onClick={toggleDrawer(false)}
							sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
						>
							<ListItemButton>
								<ListItemIcon>
									<KeyboardDoubleArrowUp />
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<Link to="/tjänster">
								<ListItemButton>
									<ListItemIcon>
										<ArrowRight />
									</ListItemIcon>
									<ListItemText primary={t('topbar.services')} />
								</ListItemButton>
							</Link>
						</ListItem>
						<ListItem disablePadding>
							<Link to="/projekt">
								<ListItemButton>
									<ListItemIcon>
										<ArrowRight />
									</ListItemIcon>
									<ListItemText primary={t('topbar.projects')} />
								</ListItemButton>
							</Link>
						</ListItem>
						<ListItem disablePadding>
							<Link to="/nyheter">
								<ListItemButton>
									<ListItemIcon>
										<ArrowRight />
									</ListItemIcon>
									<ListItemText primary={t('topbar.news')} />
								</ListItemButton>
							</Link>
						</ListItem>
						<ListItem disablePadding>
							<Link to="/om-oss">
								<ListItemButton>
									<ListItemIcon>
										<ArrowRight />
									</ListItemIcon>
									<ListItemText primary={t('topbar.about')} />
								</ListItemButton>
							</Link>
						</ListItem>
						<ListItem disablePadding>
							<Link to="/karriär">
								<ListItemButton>
									<ListItemIcon>
										<ArrowRight />
									</ListItemIcon>
									<ListItemText primary={t('topbar.career')} />
								</ListItemButton>
							</Link>
						</ListItem>
						<ListItem disablePadding>
							<Link to="/kontakt">
								<ListItemButton>
									<ListItemIcon>
										<ArrowRight />
									</ListItemIcon>
									<ListItemText primary={t('topbar.contact')} />
								</ListItemButton>
							</Link>
						</ListItem>

						{!authContext.state.isAuthenticated && (
							<React.Fragment>
								<Divider sx={{width: '100%'}} />
								<ListItem disablePadding>
									<Link to="/login">
										<ListItemButton>
											<ListItemIcon>
												<Lock />
											</ListItemIcon>
											<ListItemText primary={t('topbar.login')} />
										</ListItemButton>
									</Link>
								</ListItem>
							</React.Fragment>
						)}
						{authContext.state.isAuthenticated && (
							<React.Fragment>
								<Divider sx={{width: '100%'}} />
								<ListItem disablePadding>
									<Link to="/internwebb">
										<ListItemButton>
											<ListItemIcon>
												<Group />
											</ListItemIcon>
											<ListItemText primary="Internwebb" />
										</ListItemButton>
									</Link>
								</ListItem>
								<ListItem disablePadding>
									<Link to={'/kontakt/' + JSON.parse(localStorage.getItem('email'))}>
										<ListItemButton>
											<ListItemIcon>
												<Settings />
											</ListItemIcon>
											<ListItemText
												primary={
													JSON.parse(localStorage.getItem('firstName')) +
													' ' +
													JSON.parse(localStorage.getItem('lastName'))
												}
											/>
										</ListItemButton>
									</Link>
								</ListItem>
								<ListItem disablePadding>
									<Link
										onClick={() => {
											dispatch({type: 'LOGOUT'});
										}}
									>
										<ListItemButton>
											<ListItemIcon>
												<ExitToApp />
											</ListItemIcon>
											<ListItemText primary="Logga ut" />
										</ListItemButton>
									</Link>
								</ListItem>
							</React.Fragment>
						)}
					</List>
				</Box>
			</SwipeableDrawer>
		</Box>
	);
}

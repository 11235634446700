import {isAdmin} from '../../utils/Authentication';
import {LoadingButton} from '@mui/lab';
import {Check, Clear, Delete, Visibility} from '@mui/icons-material';
import React, {useContext, useState} from 'react';
import {AuthContext} from '../../App';
import {Button, FormControlLabel, Switch} from '@mui/material';
import {useHistory} from 'react-router-dom';

const EditToolbar = ({item, deleteItem, text, setItem, createdBy, updateItem, viewUrl}) => {
	const authContext = useContext(AuthContext);
	const history = useHistory();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'right',
				gap: '10px',
				padding: '10px 0 0',
				flexDirection: 'row',
				flexWrap: 'wrap',
			}}
		>
			{confirmDelete && (
				<>
					<p>Är du säker på att du vill radera denna {text}?</p>
					<LoadingButton
						variant="contained"
						color="primary"
						startIcon={<Check />}
						loading={loadingDelete}
						style={{
							textTransform: 'none',
							fontFamily: 'Titillium Web',
						}}
						onClick={() => {
							setLoadingDelete(true);
							deleteItem();
						}}
					>
						Ja, radera
					</LoadingButton>
					<Button
						variant="outlined"
						startIcon={<Clear />}
						style={{
							color: 'black',
							marginLeft: '1rem',

							textTransform: 'none',
							fontFamily: 'Titillium Web',
						}}
						onClick={() => {
							setConfirmDelete(false);
						}}
					>
						Nej, avbryt
					</Button>
				</>
			)}
			{!confirmDelete && (
				<>
					<FormControlLabel
						control={
							<Switch
								checked={item.showOnStartPage}
								disabled={!isAdmin(authContext.state.email)}
								onChange={() => {
									setItem({
										...item,
										showOnStartPage: !item.showOnStartPage,
									});
									updateItem({...item, showOnStartPage: !item.showOnStartPage});
								}}
								name="showOnStartPage"
							/>
						}
						label="Visa på startsida"
					/>
					<FormControlLabel
						control={
							<Switch
								checked={item.published}
								disabled={!isAdmin(authContext.state.email)}
								onChange={() => {
									setItem({
										...item,
										published: !item.published,
									});
									updateItem({...item, published: !item.published});
								}}
								name="published"
							/>
						}
						label="Är publicerad"
					/>
					{(isAdmin(authContext.state.email) ||
						(!item.published && authContext.state.email === createdBy)) && (
						<LoadingButton
							variant="contained"
							startIcon={<Delete />}
							style={{
								background: '#FFCCCB',
								color: 'black',
								textTransform: 'none',
								fontFamily: 'Titillium Web',
							}}
							onClick={() => {
								setConfirmDelete(true);
							}}
						>
							Radera
						</LoadingButton>
					)}
					<Button
						startIcon={<Visibility />}
						onClick={() => history.push(viewUrl)}
						variant="contained"
						style={{
							background: '#4cae52',
							textTransform: 'none',
							fontFamily: 'Titillium Web',
						}}
					>
						Förhandsgranska
					</Button>
				</>
			)}
		</div>
	);
};
export default EditToolbar;

import {Divider, Grid} from '@mui/material';
import React from 'react';

const PageHeader = ({pageTitle, headerImg}) => {
	return (
		<Grid
			item
			xs={12}
			sx={{
				margin: '0 auto',
				maxWidth: '1300px',
				padding: {
					xs: '5rem 0 0 0 ',
					sm: '8rem 0 0 0 ',
					md: '8rem 0 0 0 ',
					xl: '8rem 0 0 0 ',
				},
			}}
		>
			<Grid container sx={{maxWidth: '1300px', margin: '0 auto'}}>
				<Grid
					item
					sx={{
						backgroundColor: '#4cae52',
						color: 'white',
						paddingBottom: '10px',
						paddingLeft: '10px',
						textAlign: 'left',
						flexWrap: 'wrap',
						display: 'flex',
						alignItems: 'left',
						justifyContent: 'left',
						alignContent: 'flex-end',
					}}
					xs={12}
					sm={4}
					md={4}
					xl={4}
				>
					<h1
						style={{
							fontWeight: 'normal',
							fontFamily: 'Lato',
							padding: '12px 10px 0',
							margin: 0,
						}}
					>
						{pageTitle}
					</h1>
				</Grid>
				<Grid
					item
					sx={{
						backgroundColor: '#f2f0f1',
					}}
					xs={12}
					sm={8}
					md={8}
					xl={8}
				>
					<img src={headerImg} alt="header-img" style={{maxWidth: '100%', height: '100%'}} />
				</Grid>
			</Grid>
		</Grid>
	);
};
export default PageHeader;

import React, {useEffect, useState} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	CircularProgress,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import api from '../../api';
import {
	Add,
	ArrowDownward,
	ArrowUpward,
	Check,
	Clear,
	Delete,
	Edit,
	Sort,
	Refresh,
} from '@mui/icons-material';
import {Link, useHistory} from 'react-router-dom';
import {useContext} from 'react';
import {AuthContext} from '../../App';
import {LoadingButton} from '@mui/lab';
import headerImg from '../../assets/projects.jpg';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PageHeader from '../../layout/PageHeader';
import {isAdmin} from '../../utils/Authentication';
import PageIntro from '../../layout/PageIntro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReorderProjectsDialog from './ReorderProjectOrderDialog';

export default function ProjectsPage() {
	const language = localStorage.getItem('i18nextLng');
	const authContext = useContext(AuthContext);
	const [loading, isLoading] = useState(true);
	const {t} = useTranslation();
	const [data, setData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const history = useHistory();
	const [editing, isEditing] = useState(false);
	const [page, setPage] = useState({});
	const [sorterDict, setSorterDict] = useState({});

	useEffect(() => {
		getProjects();
		getPage();
		document.title = 'RED Management • ' + t('topbar.projects');
	}, []);

	const getPage = () => {
		api
			.get('/pages/getone/' + 'projekt')
			.then(response => setPage(response.data))
			.catch(error => console.error(error));
	};

	const savePage = () => {
		api
			.put('/pages/edit/' + 'projekt', page)
			.then(response => isEditing(false))
			.catch(error => console.error(error));
	};

	const getProjects = () => {
		api
			.get('/project/projects')
			.then(response => {
				let sortedData;
				if (authContext.state.isAuthenticated) {
					sortedData = response.data.sort((a, b) => a.postOrder - b.postOrder);
				} else {
					sortedData = response.data
						.filter(r => r.published)
						.sort((a, b) => a.postOrder - b.postOrder);
				}
				setData(sortedData);
				setOriginalData(sortedData);
				isLoading(false);
			})
			.catch(error => {
				console.error(error);
				isLoading(false);
			});
	};

	const resetSorter = () => {
		setData([...originalData]);
		setSorterDict({});
	};

	const sortDataBy = key => {
		let newData = [...data];
		let newSorter = {...sorterDict};
		Object.keys(newSorter).map(n => (newSorter[n] = undefined));

		if (key === 'swedishTitle') {
			newData = newData.sort((a, b) => a.swedishTitle.localeCompare(b.swedishTitle));
		} else if (key === 'postOrder') {
			newData = newData.sort((a, b) => a.postOrder - b.postOrder);
		}

		if (sorterDict[key]) {
			newData = newData.reverse();
			newSorter[key] = false;
		} else if (!newSorter[key]) {
			newSorter[key] = true;
		}
		setData(newData);
		setSorterDict(newSorter);
	};

	const getArrow = key => {
		if (!(sorterDict[key] === undefined)) {
			if (!sorterDict[key]) {
				return <ArrowDownward />;
			} else {
				return <ArrowUpward />;
			}
		} else {
			return <Sort />;
		}
	};

	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={t('topbar.projects')} />
			<PageIntro
				data={page}
				setData={setPage}
				savePage={savePage}
				isEditing={isEditing}
				editing={editing}
			/>
			{loading ? (
				<LoadingComponent loadingText={t('loading.loadingProjects')} />
			) : (
				<Grid item xs={12}>
					<Grid container>
						<Grid
							item
							xs={12}
							md={6}
							xl={6}
							style={{
								padding: '1rem 10px',
								textAlign: 'left',
								display: 'flex',
								justifyContent: 'left',
								alignContent: 'center',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							{t('contactpage.sortby')}
							<Button
								endIcon={getArrow('swedishTitle')}
								style={{textAlign: 'left', textTransform: 'none'}}
								onClick={() => sortDataBy('swedishTitle')}
							>
								{t('projectpage.projectname')}
							</Button>
							<Button
								startIcon={<Refresh />}
								style={{textAlign: 'left', textTransform: 'none'}}
								onClick={resetSorter}
							>
								{t('contactpage.reset')}
							</Button>
						</Grid>
						{authContext.state.isAuthenticated && (
							<Grid
								item
								xs={12}
								md={6}
								style={{
									padding: '1rem 10px',
									textAlign: 'right',
									display: 'flex',
									justifyContent: 'right',
									alignContent: 'center',
									alignItems: 'center',
									gap: '1rem',
								}}
							>
								<Button
									startIcon={<Add />}
									onClick={() => {
										api
											.post('/project/createnewproject')
											.then(response => history.push('/projects/' + response.data._id))
											.catch(error => console.error(error));
									}}
									size="large"
									variant="contained"
									style={{
										background: '#4cae52',
										textTransform: 'none',
										fontFamily: 'Titillium Web',
									}}
								>
									Skapa nytt projekt
								</Button>
								<ReorderProjectsDialog projects={data} setProjects={setData} />
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid container spacing={2}>
								{!loading && data !== null && data !== undefined && data.length > 0 && (
									<>
										{data?.map((item, index) => (
											<Grid
												item
												key={item._id}
												xs={12}
												md={4}
												sm={4}
												className="project-detail"
												style={{textAlign: 'left', padding: '1rem', cursor: 'pointer'}}
											>
												<Card
													key={item.id}
													onClick={() => history.push('/projekt/' + item.url)}
													style={{
														textAlign: 'left',
														boxShadow: '12px 12px 24px #d8d8d8, -12px -12px 24px #fefefe',
														borderRadius: '3px',
														backgroundColor: '#f2f0f1',
													}}
												>
													<CardMedia
														component="img"
														sx={{width: {xs: '100%'}}}
														image={item.pictureUrl}
														alt={language === 'sv' ? item.swedishTitle : item.englishTitle}
														title={language === 'sv' ? item.swedishTitle : item.englishTitle}
													/>
													<CardContent>
														<h3>{language === 'sv' ? item.swedishTitle : item.englishTitle}</h3>
														{language === 'sv' && item.swedishSubtitle.length > 0 && (
															<Typography
																style={{fontFamily: 'Titillium Web'}}
																variant="body2"
																color="text.secondary"
															>
																{item.swedishSubtitle}
															</Typography>
														)}
														{language === 'en' && item.englishSubtitle.length > 0 && (
															<Typography
																style={{fontFamily: 'Titillium Web'}}
																variant="body2"
																color="text.secondary"
															>
																{item.englishSubtitle}
															</Typography>
														)}
													</CardContent>
													{authContext.state.isAuthenticated && (
														<CardActions>
															<Grid container>
																<Grid
																	item
																	xs={12}
																	style={{
																		padding: '10px 0',
																		display: 'flex',
																		justifyContent: 'right',
																		gap: '1rem',
																	}}
																>
																	<Chip
																		label={
																			item.showOnStartPage
																				? 'Visas på startsida'
																				: 'Visas ej på startsida'
																		}
																		style={{
																			fontWeight: item.showOnStartPage ? 'regular' : 'bold',
																			color: item.showOnStartPage ? '#ffffff' : 'red',
																			background: item.showOnStartPage ? '#4cae52' : '#ffffff',
																		}}
																	/>
																	<Chip
																		label={item.published ? 'Publicerad' : 'Ej publicerad'}
																		style={{
																			color: item.published ? '#ffffff' : 'red',
																			fontWeight: item.published ? 'regular' : 'bold',
																			background: item.published ? '#4cae52' : '#ffffff',
																		}}
																	/>
																	<Button
																		startIcon={<Edit />}
																		variant="contained"
																		color="primary"
																		onClick={e => {
																			e.stopPropagation();
																			window.open('/projects/' + item._id);
																		}}
																		style={{
																			textTransform: 'none',
																			fontFamily: 'Titillium Web',
																		}}
																	>
																		Redigera
																	</Button>
																</Grid>
															</Grid>
														</CardActions>
													)}
													{!authContext.state.isAuthenticated && (
														<CardActions style={{textAlign: 'right', padding: '1rem'}}>
															<Button
																endIcon={<ArrowForwardIcon style={{color: 'white'}} />}
																style={{
																	background: '#4cae52',
																	transition: '.2s ease-in-out',
																	textTransform: 'none',
																	fontFamily: 'Titillium Web',
																}}
																variant="contained"
																onClick={e => {
																	e.stopPropagation();
																	history.push('/projekt/' + item.url);
																}}
															>
																{t('startpage.readmore')}
															</Button>
														</CardActions>
													)}
												</Card>
											</Grid>
										))}
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}

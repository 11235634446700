import React, {useState, useRef, useCallback} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Grid} from '@mui/material';
import {CameraAlt, Crop, Delete, PhotoLibrary} from '@mui/icons-material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import api from '../../api';

const DEFAULT_IMAGE_URL = 'https://storage.googleapis.com/redm/default_image.jpg';

const PhotoUploader = ({data, setData, personId}) => {
	const [src, setSrc] = useState(null);
	const [crop, setCrop] = useState({unit: '%', width: 30, aspect: 300 / 355});
	const [completedCrop, setCompletedCrop] = useState(null);
	const [showCropDialog, setShowCropDialog] = useState(false);
	const inputRef = useRef();
	const imageRef = useRef(null);
	const [showExistingCropDialog, setShowExistingCropDialog] = useState(false);
	const [deleting, isDeleting] = useState(false);
	const [existingImageCrop, setExistingImageCrop] = useState({
		unit: '%',
		width: 30,
		aspect: 300 / 355,
	});
	const handleCropExisting = () => {
		setSrc(data.pictureUrl);
		setShowExistingCropDialog(true);
	};
	const handleImageUpload = event => {
		if (event.target.files && event.target.files.length > 0) {
			handleSaveOriginalImage(event);
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setSrc(reader.result);
				setShowCropDialog(true);
			});
			reader.readAsDataURL(file);
		}
	};

	const handleCropComplete = (crop, percentCrop) => {
		setCompletedCrop(crop);
	};

	const getCroppedImg = useCallback((image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					reject(new Error('Canvas is empty'));
					return;
				}
				blob.name = fileName;
				resolve(blob);
			}, 'image/jpeg');
		});
	}, []);
	const handleSaveOriginalImage = async event => {
		const formData = new FormData();
		formData.append('file', event.target.files[0]);
		api
			.post(`/person/uploadprofilephoto/${data._id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(response => {
				setData({...data, pictureUrl: response.data});
			})
			.catch(error => {
				console.error(error);
			});
	};
	const handleSaveCroppedImage = useCallback(async () => {
		if (inputRef.current) {
			try {
				const croppedImageBlob = await getCroppedImg(
					imageRef.current,
					completedCrop,
					'cropped.jpg'
				);
				const formData = new FormData();
				formData.append('file', croppedImageBlob, 'cropped.jpg');

				const response = await api.post(`/person/uploadcroppedprofilephoto/${personId}`, formData, {
					headers: {'Content-Type': 'multipart/form-data'},
				});

				setData({...data, pictureUrlCropped: response.data});
				setShowCropDialog(false);
			} catch (error) {
				console.error('Error saving cropped image:', error);
			}
		}
	}, [completedCrop, data, personId, setData, getCroppedImg]);
	const handleSaveExistingCroppedImage = useCallback(async () => {
		if (existingImageCrop) {
			try {
				const response = await api.post(
					`/person/cropexistingprofilephoto/${personId}`,
					existingImageCrop
				);

				setData({...data, pictureUrlCropped: response.data});
				setShowExistingCropDialog(false);
			} catch (error) {
				console.error('Error saving cropped image:', error);
			}
		}
	}, [existingImageCrop, data, personId, setData]);
	const handleDeleteImage = () => {
		api
			.delete(`/person/deleteprofilephoto/${personId}`)
			.then(() => {
				setData({...data, pictureUrl: DEFAULT_IMAGE_URL, pictureUrlCropped: DEFAULT_IMAGE_URL});
			})
			.catch(error => {
				console.error('Error deleting image:', error);
			});
	};

	return (
		<div>
			<div
				style={{
					backgroundImage: `url('${data.pictureUrlCropped || data.pictureUrl}')`,
					width: '300px',
					margin: '0 auto',
					height: '355px',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>

			<Grid container spacing={2} justifyContent="center" style={{marginTop: '10px'}}>
				{data.pictureUrlCropped === DEFAULT_IMAGE_URL ? (
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							startIcon={<CameraAlt />}
							onClick={() => inputRef.current.click()}
						>
							Ladda upp bild
						</Button>
					</Grid>
				) : (
					<>
						<Grid item>
							<Button
								variant="outlined"
								sx={{textTransform: 'none'}}
								startIcon={<Crop />}
								onClick={handleCropExisting}
							>
								Beskär
							</Button>
						</Grid>

						<Grid item>
							<Button
								variant="outlined"
								sx={{textTransform: 'none'}}
								startIcon={<PhotoLibrary />}
								onClick={() => inputRef.current.click()}
							>
								Ladda upp ny bild
							</Button>
						</Grid>
						<Grid item>
							{deleting ? (
								<React.Fragment>
									<p>Är du säker på att du vill radera bilden?</p>
									<Button
										variant="contained"
										sx={{textTransform: 'none', marginRight: '1rem'}}
										onClick={handleDeleteImage}
									>
										Ja, radera bilden
									</Button>
									<Button
										variant="outlined"
										sx={{textTransform: 'none'}}
										onClick={() => isDeleting(false)}
									>
										Nej, avbryt
									</Button>
								</React.Fragment>
							) : (
								<Button
									variant="outlined"
									sx={{textTransform: 'none'}}
									startIcon={<Delete />}
									onClick={() => isDeleting(true)}
								>
									Radera bild
								</Button>
							)}
						</Grid>
					</>
				)}
			</Grid>

			<input
				type="file"
				id="mainphoto"
				name="file"
				style={{display: 'none'}}
				ref={inputRef}
				onChange={handleImageUpload}
			/>
			{showCropDialog && (
				<Dialog open={showCropDialog} onClose={() => setShowCropDialog(false)} maxWidth="md">
					<DialogContent>
						<ReactCrop
							src={src}
							crop={crop}
							onChange={c => setCrop(c)}
							onComplete={handleCropComplete}
							aspect={300 / 355}
						>
							<img ref={imageRef} src={src} alt="Crop me" style={{maxWidth: '100%'}} />
						</ReactCrop>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setShowCropDialog(false)}>Avbryt</Button>
						<Button onClick={handleSaveCroppedImage} color="primary">
							Spara beskuren bild
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{showExistingCropDialog && (
				<Dialog
					open={showExistingCropDialog}
					onClose={() => setShowExistingCropDialog(false)}
					maxWidth="md"
				>
					<DialogContent>
						<ReactCrop
							src={src}
							crop={existingImageCrop}
							onChange={c => setExistingImageCrop(c)}
							aspect={300 / 355}
						>
							<img src={src} alt="Crop existing" style={{maxWidth: '100%'}} />
						</ReactCrop>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setShowExistingCropDialog(false)}>Avbryt</Button>
						<Button onClick={handleSaveExistingCroppedImage} color="primary">
							Spara beskuren bild
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default PhotoUploader;

import React, {useContext, useEffect, useState} from 'react';
import fenix from '../../assets/IMG_20220511_185505.jpg';

import {
	Button,
	Card,
	CircularProgress,
	FormControl,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import {AuthContext} from '../../App';
import {useHistory} from 'react-router-dom';
import api from '../../api';
import {Alert} from '@mui/lab';
import {useTranslation} from 'react-i18next';

const LoginPage = () => {
	const {dispatch} = useContext(AuthContext);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const {t} = useTranslation();

	const [hasUpdated, setHasUpdated] = useState(false);
	const [updateMessage, setUpdateMessage] = useState('');
	const [updateType, setUpdateType] = useState('info');
	const [errorType, setErrorType] = useState('');

	const [data, setData] = useState({
		email: '',
		password: '',
		isSubmitting: false,
		errorMessage: null,
	});

	const handleInputChange = event => {
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
	};
	useEffect(() => {
		document.title = 'RED Management • ' + t('topbar.login');
	}, []);
	const handleFormSubmit = event => {
		event.preventDefault();
		setLoading(true);
		setData({
			...data,
			isSubmitting: true,
			errorMessage: null,
		});

		const loginRequest = {
			email: data.email,
			password: data.password,
		};

		api
			.post('/auth/login', loginRequest)
			.then(response => {
				setData({
					...data,
					isSubmitting: false,
					errorMessage: null,
				});
				history.push('/');
				dispatch({
					type: 'LOGIN',
					payload: response.data,
				});
			})
			.catch(error => {
				setData({
					...data,
					isSubmitting: false,
					errorMessage: error.response.data.errorMessage,
				});
				setErrorType(error.response.data.errorType);
				setLoading(false);
				setUpdateMessage(error.response.data.errorMessage);
				setUpdateType('error');
				setHasUpdated(true);
				setTimeout(() => {
					setUpdateMessage('');
					setHasUpdated(false);
					setUpdateType('info');
				}, 2000);
			});
	};

	const hasPasswordError =
		hasUpdated && updateType === 'error' && errorType === 'incorrect-password';
	const hasUserNameError = hasUpdated && updateType === 'error' && errorType === 'no-user-found';
	const hasUnknownError = hasUpdated && updateType === 'error' && errorType === 'unknown-error';
	return (
		<Grid
			container
			style={{
				height: '100vh',
				backgroundOrigin: 'bottom',
				backgroundPosition: 'center',
				backgroundImage: `url(${fenix})`,
				backgroundSize: 'cover',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				alignContent: 'center',
			}}
		>
			<Card
				sx={{
					padding: '2rem',
					marginTop: '10%',
					marginLeft: {
						md: '400px',
						xl: '400px',
					},
					width: {
						xs: '92%',
						sm: '92%',
						md: '400px',
						xl: '400px',
					},
					textAlign: 'center',
				}}
			>
				<form onSubmit={handleFormSubmit}>
					{loading ? (
						<Grid container>
							<Grid item xs={12} style={{padding: '3rem', textAlign: 'center'}}>
								<CircularProgress style={{margin: '2rem auto'}} />
							</Grid>
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12}>
								<FormControl margin="dense" style={{minWidth: '300px'}}>
									<TextField
										error={hasUserNameError}
										helperText={hasUserNameError && updateMessage}
										id="email"
										label="Användarnamn"
										name="email"
										onChange={handleInputChange}
										type="text"
										value={data.email}
										variant="outlined"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} style={{height: '6rem'}}>
								<FormControl margin="dense" style={{minWidth: '300px'}}>
									<TextField
										error={hasPasswordError}
										helperText={hasPasswordError && updateMessage}
										id="password"
										label="Lösenord"
										name="password"
										onChange={handleInputChange}
										type="password"
										value={data.password}
										variant="outlined"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl margin="dense" style={{minWidth: '300px'}}>
									<Button
										className="login-button"
										disabled={data.isSubmitting}
										onClick={handleFormSubmit}
										size="large"
										style={{
											background: '#4cae52',
										}}
										type="submit"
										variant="contained"
									>
										Logga in
									</Button>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								{hasUnknownError && <Alert severity={updateType}>{updateMessage}</Alert>}
							</Grid>
						</Grid>
					)}
				</form>
			</Card>
		</Grid>
	);
};
export default LoginPage;

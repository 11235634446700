import React, {useRef, useState} from 'react';
import axiosClient from '../api';
import {
	Card,
	CircularProgress,
	FormControl,
	Grid,
	CardActions,
	CardContent,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import {Check, Delete, UploadFile} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Compressor from 'compressorjs';
import './Pictures.css';
import api from '../api';
const Pictures = ({item, setItem, attributeName, updateItem, english = true}) => {
	const inputRef = useRef(null);
	const [files, setFiles] = React.useState([]);
	const [uploading, isUploading] = useState(false);

	const handleUpload = selectedFiles => {
		isUploading(true);
		[...selectedFiles].forEach(f => {
			new Compressor(f, {
				quality: 0.6,
				success(result) {
					const formData = new FormData();
					formData.append('file', result, result.name);

					axiosClient
						.post('/file/attachments/' + attributeName + '/pictures/' + item._id, formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						})
						.then(response => {
							setItem({...item, pictures: response.data});
						})
						.catch(error => console.error(error));
				},
				error(error) {
					console.error(error.message);
				},
			});
		});
		isUploading(false);
	};

	const deletePhoto = photo => {
		axiosClient
			.post('/file/delete/' + attributeName + '/pictures/' + item._id + '/' + photo._id, {
				path: photo.url,
				name: photo.description,
			})
			.then(() => {
				const newData = item.pictures.filter(p => p._id !== photo._id);
				setItem({...item, pictures: newData});
			})
			.catch(error => {
				console.error(error);
			});
	};
	return (
		<Grid container spacing={2}>
			{item.pictures.length > 0 &&
				item.pictures.map((p, index) => (
					<Grid item xs={12} md={6}>
						<Card key={p._id} sx={{boxShadow: 1}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
									{item.pictures.length > 1 && (
										<div>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															defaultChecked
															checked={p.url === item.pictureUrl}
															onChange={() => {
																const update = {
																	...item,
																	pictureUrl: p.url,
																	pictureDescriptionSwedish: p.swedishDescription,
																	pictureDescriptionEnglish: p.englishDescription,
																};
																setItem(update);
																updateItem(update);
															}}
														/>
													}
													label={p.url === item.pictureUrl ? 'Huvudbild' : 'Sätt som huvudbild'}
												/>
											</FormGroup>
										</div>
									)}

									<div>
										<h3 style={{padding: 0, margin: 0}}>{p.description}</h3>
									</div>
									<div>
										<Button
											onClick={() => deletePhoto(p)}
											variant="contained"
											startIcon={<Delete />}
											style={{
												textTransform: 'none',
												margin: '10px',
												background: '#FFCCCB',
												color: 'black',
											}}
										>
											Radera
										</Button>
									</div>
								</Grid>
								<Grid item xs={12}>
									<img src={p.url} width={'100%'} />
								</Grid>
								<Grid item xs={12} md={english ? 6 : 12} sx={{padding: '0 0 1rem 1rem'}}>
									<FormControl margin="dense" fullWidth>
										<TextField
											value={p.swedishDescription}
											name="swedishDescription"
											autoComplete="off"
											id="swedishDescription"
											size="small"
											label="Beskrivning (sv)"
											onChange={event => {
												const newData = [...item.pictures].map(item => {
													if (item._id === p._id) {
														return {
															...item,
															swedishDescription: event.target.value,
														};
													} else return item;
												});
												setItem({...item, pictures: newData});
												api
													.put(
														'/file/editdescription/' +
															attributeName +
															'/pictures/' +
															item._id +
															'/' +
															p._id,
														{
															swedishDescription: event.target.value,
															englishDescription: p.englishDescription,
														}
													)
													.catch(error => console.error(error));
											}}
										/>
									</FormControl>
								</Grid>
								{english && (
									<Grid item xs={12} md={6} sx={{padding: '0 1rem 1rem 0'}}>
										<FormControl margin="dense" fullWidth>
											<TextField
												value={p.englishDescription}
												name="englishDescription"
												size="small"
												id="englishDescription"
												autoComplete="off"
												label="Description (en)"
												onChange={event => {
													const newData = [...item.pictures].map(item => {
														if (item._id === p._id) {
															return {
																...item,
																englishDescription: event.target.value,
															};
														} else return item;
													});
													setItem({...item, pictures: newData});
													api
														.put(
															'/file/editdescription/' +
																attributeName +
																'/pictures/' +
																item._id +
																'/' +
																p._id,
															{
																swedishDescription: p.swedishDescription,
																englishDescription: event.target.value,
															}
														)
														.catch(error => console.error(error));
												}}
											/>
										</FormControl>
									</Grid>
								)}
							</Grid>
						</Card>
					</Grid>
				))}

			<Grid item xs={12}>
				{uploading ? (
					<label
						htmlFor="file"
						className="file-style"
						style={{cursor: 'pointer', textTransform: 'none'}}
					>
						<CircularProgress size="2rem" />
						<p> Laddar upp...</p>
					</label>
				) : (
					<label
						htmlFor="file"
						className="file-style"
						style={{cursor: 'pointer', textTransform: 'none'}}
					>
						<UploadFile />
						<p> Ladda upp bilder</p>
					</label>
				)}

				<input
					type="file"
					id="file"
					accept="image/*"
					name="file"
					multiple
					onChange={e => {
						setFiles(e.target.files);
						handleUpload(e.target.files);
					}}
				/>
			</Grid>
		</Grid>
	);
};
export default Pictures;

import React from 'react';
import './css/normalize.css';
import './css/App.css';
import Main from './layout/Main';
import ReactGA from 'react-ga';
import {useTranslation} from 'react-i18next';
import {AppBar, Box, Button, Toolbar, Typography} from '@mui/material';
import {HelpOutline} from '@mui/icons-material';
const TRACKING_ID = 'G-B2B0X7RBSG';

const initializeGA = () => {
	ReactGA.initialize(TRACKING_ID);
};

export const AuthContext = React.createContext();
const initialState = {
	isAuthenticated: false,
	_userId: null,
	cid: null,
	firstName: null,
	lastName: null,
	tid: null,
	email: null,
};
const reducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN':
			localStorage.setItem('_userId', JSON.stringify(action.payload._userId));
			localStorage.setItem('firstName', JSON.stringify(action.payload.firstName));
			localStorage.setItem('lastName', JSON.stringify(action.payload.lastName));
			localStorage.setItem('tid', JSON.stringify(action.payload.tid));
			localStorage.setItem('email', JSON.stringify(action.payload.email));
			return {
				...state,
				isAuthenticated: true,

				_userId: action.payload._userId,
				firstName: action.payload.firstName,
				lastName: action.payload.lastName,
				tid: action.payload.tid,
				email: action.payload.email,
			};
		case 'LOGOUT':
			localStorage.clear();
			return {
				...state,
				isAuthenticated: false,
				_userId: null,
				firstName: null,
				lastName: null,
				tid: null,
				email: null,
			};
		default:
			return state;
	}
};

function App() {
	const {t} = useTranslation();
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const [cookiesAccepted, setCookiesAccepted] = React.useState(() => {
		// Retrieve the user's choice from local storage, null if not set
		const storedValue = localStorage.getItem('cookiesAccepted');
		return storedValue === 'true' ? true : storedValue === 'false' ? false : null;
	});

	React.useEffect(() => {
		const _userId = JSON.parse(localStorage.getItem('_userId') || null);
		const firstName = JSON.parse(localStorage.getItem('firstName') || null);
		const lastName = JSON.parse(localStorage.getItem('lastName') || null);
		const tid = JSON.parse(localStorage.getItem('tid') || null);
		const email = JSON.parse(localStorage.getItem('email') || null);

		if (email && tid) {
			dispatch({
				type: 'LOGIN',
				payload: {
					_userId,
					firstName,
					lastName,
					tid,
					email,
				},
			});
		}
	}, []);

	React.useEffect(() => {
		if (cookiesAccepted) {
			initializeGA();
		}
	}, [cookiesAccepted]);

	const handleAccept = () => {
		localStorage.setItem('cookiesAccepted', 'true');
		setCookiesAccepted(true);
		initializeGA();
	};

	const handleDecline = () => {
		localStorage.setItem('cookiesAccepted', 'false');
		setCookiesAccepted(false);
		// Optional: Remove GA script if already loaded
		removeGAScripts();
	};

	const removeGAScripts = () => {
		// Remove GA scripts from the document
		const gaScript = document.querySelector(`script[src*='https://www.google-analytics.com']`);
		if (gaScript) {
			gaScript.remove();
		}
		// Clear GA cookies
		document.cookie = '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		document.cookie = '_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		document.cookie = '_gat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	};

	return (
		<div>
			<AuthContext.Provider
				value={{
					state,
					dispatch,
				}}
			>
				<main className="main">
					<Main />
				</main>
				{cookiesAccepted === null && (
					<AppBar position="fixed" sx={{top: 'auto', bottom: 0}}>
						<Toolbar
							sx={{
								justifyContent: 'space-between',
								padding: '10px',
								flexWrap: {xs: 'wrap', md: 'no-wrap'},
								rowGap: {xs: '10px'},
								background: 'rgba(63, 120, 66, 0.95)',
							}}
						>
							<Typography variant="body1" color="inherit">
								{t('cookiebanner.text')}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'right',
									gap: '1rem',
									alignItems: 'center',
									alignContent: 'center',
								}}
							>
								<Button
									color="inherit"
									onClick={handleDecline}
									variant="outlined"
									sx={{textTransform: 'none'}}
								>
									{t('cookiebanner.decline')}
								</Button>
								<Button
									color="inherit"
									onClick={handleAccept}
									variant="contained"
									sx={{textTransform: 'none', color: 'rgba(63, 120, 66, 0.95)'}}
								>
									{t('cookiebanner.accept')}
								</Button>
								<Button
									color="inherit"
									onClick={() => window.open('/cookiepolicy')}
									sx={{textTransform: 'none'}}
									endIcon={<HelpOutline />}
								>
									{t('cookiebanner.readmore')}
								</Button>
							</Box>
						</Toolbar>
					</AppBar>
				)}
			</AuthContext.Provider>
		</div>
	);
}

export default App;

import {Link} from 'react-router-dom';
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const BreadCrumb = ({mainLinkUrl, mainLinkWord, subLinkUrl, subLinkWord, title}) => {
	return (
		<Breadcrumbs aria-label="breadcrumb">
			<Link to={mainLinkUrl} style={{cursor: 'pointer'}}>
				<h3 style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}>
					{mainLinkWord}
				</h3>
			</Link>
			{subLinkUrl && (
				<Link to={subLinkUrl} style={{cursor: 'pointer'}}>
					<h3 style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}>
						{subLinkWord}
					</h3>
				</Link>
			)}

			<Typography color="text.primary"> {title}</Typography>
		</Breadcrumbs>
	);
};
export default BreadCrumb;

import {Box, Grid, Tooltip, Typography} from '@mui/material';
import redm_logo from '../assets/redm.png';
import {ArrowUpward, MailOutline, Phone, Place} from '@mui/icons-material';
import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {versionDate, versionNumber} from '../utils/Version';

const Footer = () => {
	const {t} = useTranslation();
	return (
		<footer style={{backgroundColor: '#ffffff', borderTop: '1px solid #4cae52', marginTop: '2rem'}}>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',

					alignItems: {sx: 'left', sm: 'left', md: 'center', xl: 'center'},
					miHeight: '10vh',
					padding: '2rem',
					justifyContent: {sx: 'left', sm: 'left', md: 'center', xl: 'center'},
					flexDirection: 'row',
				}}
			>
				<div
					style={{
						color: 'gray',
						textAlign: 'left',
					}}
				>
					<img
						src="https://storage.googleapis.com/redmanagement/redm_logo_xl_horizontal.png"
						width="200"
						alt="Logo"
					/>
					<Typography
						variant="h6"
						style={{
							color: '#4cae52',
							margin: 'auto 0',
							paddingBottom: '10px',
							fontWeight: 600,
							fontFamily: 'Titillium Web',
						}}
					>
						RED Management AB
					</Typography>

					<Typography
						variant="body2"
						style={{
							fontFamily: 'Titillium Web',
						}}
					>
						{t('footer.cid')}
					</Typography>

					<Typography
						variant="body2"
						style={{
							fontFamily: 'Titillium Web',
						}}
					>
						Vasagatan 15-17
					</Typography>
					<Typography
						variant="body2"
						style={{
							fontFamily: 'Titillium Web',
						}}
					>
						111 20 Stockholm
					</Typography>
				</div>
				<Grid
					sx={{
						padding: {xs: '0', sm: '2rem 4rem', md: '2rem 4rem', xl: '2rem 4rem'},
						textAlign: {xs: 'left', md: 'left', xl: 'left'},
						width: {xs: '200px', md: '250px', xl: '250px'},
					}}
				>
					<h3 style={{color: '#4cae52', margin: '1rem 0'}}>Sitemap</h3>
					<ul
						style={{
							textDecoration: 'none',
							fontSize: '0.8rem',
							listStyle: 'none',
							margin: 0,
							padding: 0,
						}}
					>
						<li className="footer-list-item">
							<Link to="/om-oss" className="link">
								{t('footer.about')}
							</Link>
						</li>
						<li className="footer-list-item">
							<Link to="/tjänster" className="link">
								{t('footer.services')}
							</Link>
						</li>
						<li className="footer-list-item">
							<Link to="/kontakt" className="link">
								{t('footer.contact')}
							</Link>
						</li>
						<li className="footer-list-item">
							<Link to="/nyheter" className="link">
								{t('footer.news')}
							</Link>
						</li>
						<li className="footer-list-item">
							<Link to="/projekt" className="link">
								{t('footer.projects')}
							</Link>
						</li>
						<li className="footer-list-item">
							<Link to="/karriär" className="link">
								{t('footer.careers')}
							</Link>
						</li>
					</ul>
				</Grid>
				<div style={{padding: '1rem 0'}}>
					<Grid container>
						<Grid item xs={12} sx={{textAlign: {xs: 'left', md: 'left', xl: 'left'}}}>
							<Tooltip title={t('footer.followlinkedin')} placement="top-start">
								<div>
									<ul className="social-network social-circle">
										<li>
											<a
												href="https://www.linkedin.com/company/red-management"
												className="icoLinkedin"
												title="Linkedin"
											>
												<i className="fa fa-linkedin"></i>
											</a>
										</li>
									</ul>
								</div>
							</Tooltip>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								textAlign: {xs: 'center', md: 'left', xl: 'left'},
								padding: '2rem 0 0 0',
							}}
						>
							<Tooltip title={t('footer.callus')} placement="top-start">
								<Grid
									sx={{
										display: 'flex',
										justifyContent: {xs: 'left', md: 'left', xl: 'left'},
									}}
									className="hover-link"
								>
									<Phone fontSize="small" style={{marginRight: '10px'}} />
									<Typography
										variant="body2"
										style={{
											fontFamily: 'Titillium Web',
										}}
									>
										<a href="tel:0046730858880">{t('footer.phone')}</a>
									</Typography>
								</Grid>
							</Tooltip>
						</Grid>
						<Grid item xs={12} style={{padding: '1rem 0 0 0'}}>
							<Tooltip title={t('footer.sendemail')} placement="bottom">
								<Grid
									sx={{
										display: 'flex',
										justifyContent: {xs: 'left', md: 'left', xl: 'left'},
									}}
									className="hover-link"
								>
									<MailOutline fontSize="small" style={{marginRight: '10px'}} />
									<Typography
										variant="body2"
										style={{
											fontFamily: 'Titillium Web',
										}}
									>
										<a href="mailto:info@redmanagement.se">info[a]redmanagement.se</a>
									</Typography>
								</Grid>
							</Tooltip>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: {xs: 'left', md: 'left', xl: 'left'},
								padding: '1rem 0 0 0',
							}}
						>
							<Tooltip title={t('footer.directionstooltip')} placement="bottom-start">
								<Grid
									sx={{
										display: 'flex',
										justifyContent: {xs: 'center', md: 'left', xl: 'left'},
									}}
									className="hover-link"
								>
									<Place fontSize="small" style={{marginRight: '10px'}} />
									<Typography
										variant="body2"
										style={{
											fontFamily: 'Titillium Web',
										}}
									>
										<a href="https://goo.gl/maps/sxpHZsCQoJrcaG7S7">{t('footer.directions')}</a>
									</Typography>
								</Grid>
							</Tooltip>
						</Grid>
					</Grid>
				</div>
			</Box>
			<Box
				sx={{
					backgroundColor: 'rgb(76, 174, 82)',
					textAlign: {xs: 'left', md: 'left', xl: 'left'},
					display: 'flex',
					flexWrap: 'wrap',
					flexDirection: 'row',
					justifyContent: {xs: 'left', md: 'space-evenly', xl: 'space-evenly'},
					gap: '1rem',
					color: 'white',
					padding: '2rem',
				}}
			>
				<div>2024 © Red Management AB</div>
				<div>
					<div style={{marginRight: '1rem'}}>{versionNumber}</div>
					<div style={{marginRight: '1rem'}}>{versionDate}</div>
				</div>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: '1rem',

						flexDirection: 'row',
						justifyContent: {xs: 'left', md: 'space-evenly', xl: 'space-evenly'},
					}}
				>
					<Link className="bottom-footer-link" to="/integritetspolicy">
						{t('footer.integritypolicy')}
					</Link>
					<Link className="bottom-footer-link" to="/cookiepolicy">
						{t('footer.cookiepolicy')}
					</Link>
				</Box>
				<button
					className="scroll-to-top-button"
					onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
				>
					<ArrowUpward style={{marginRight: '10px'}} />
					{t('footer.tothetop')}
				</button>
			</Box>
		</footer>
	);
};
export default Footer;

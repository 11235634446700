import React, {useEffect, useState} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import headerImg from '../../assets/concrete_corner.jpeg';
import api from '../../api';
import {Add, Check, Clear, Delete, Edit} from '@mui/icons-material';
import {useContext} from 'react';
import {AuthContext} from '../../App';
import {Link, useHistory} from 'react-router-dom';
import {Alert, LoadingButton} from '@mui/lab';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import PageHeader from '../../layout/PageHeader';
import {isAdmin} from '../../utils/Authentication';
import PageIntro from '../../layout/PageIntro';
import TextEditor from '../../components/TextEditor/TextEditor';
import parse from 'html-react-parser';

export default function CareersPage() {
	const {t} = useTranslation();
	const authContext = useContext(AuthContext);
	const history = useHistory();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDeleteJob, setLoadingDeleteJob] = useState(false);
	const [data, setData] = useState([]);
	const [creatingNewJob, setCreatingNewJob] = useState(false);
	const [editingJob, setEditingJob] = useState(false);
	const [loadingNewJob, setLoadingNewJob] = useState(false);
	const [loadingNewSubscription, setLoadingNewSubscription] = useState(false);
	const [creatingNewSubscription, setCreatingNewSubscription] = useState(false);
	const [hasUpdated, setHasUpdated] = useState(false);
	const [updateMessage, setUpdateMessage] = useState('');
	const [updateType, setUpdateType] = useState('info');
	const [subscriptions, setSubscriptions] = useState([]);
	const [editing, isEditing] = useState(false);
	const [page, setPage] = useState({});
	const language = localStorage.getItem('i18nextLng');

	const [selectedJob, setSelectedJob] = useState({
		_id: '',
		title: '',
		subtitle: '',
		quote: '',
		text: '',
	});
	const baseRowData = {
		_id: '',
		title: '',
		subtitle: '',
		quote: '',
		text: '',
	};
	const [jobInterest, setJobInterest] = useState({
		email: '',
		analysis: false,
		duediligence: false,
		realestatedevelopment: false,
		sustainability: false,
		builddevrep: false,
		projectmanagement: false,
	});
	useEffect(() => {
		getJobs();
		getSubscriptions();
		getPage();

		document.title = 'RED Management • ' + t('topbar.career');
	}, []);
	const handleChange = event => {
		if (event.target.value !== null) {
			setSelectedJob({...selectedJob, [event.target.name]: event.target.value});
		}
	};
	const updateJob = update => {
		api
			.put('/job/job/' + update._id, update)
			.then(() => {
				setEditingJob(false);
				setLoadingNewJob(false);
				getJobs();
			})
			.catch(error => console.error(error));
	};
	const deleteJob = job => {
		api
			.delete('/job/job/' + job._id)
			.then(() => {
				setLoadingDeleteJob(false);
				setSelectedJob(baseRowData);
				getJobs();
			})
			.catch(error => {
				setLoadingDeleteJob(false);
				console.error(error);
			});
	};
	const getJobs = () => {
		api
			.get('/job/jobs')
			.then(response => {
				setData(response.data);
			})
			.catch(error => console.error(error));
	};
	const getSubscriptions = () => {
		api
			.get('/subscription/getall')
			.then(response => {
				setSubscriptions(response.data);
			})
			.catch(error => console.error(error));
	};
	const createNewJob = () => {
		api
			.post('/job/job', selectedJob)
			.then(response => {
				setLoadingNewJob(false);
				setCreatingNewJob(false);
				getJobs();
				setSelectedJob(baseRowData);
			})
			.catch(error => console.error(error));
	};
	const createNewSubscription = () => {
		api
			.post('/email/registerinterest', jobInterest)
			.then(response => {
				setLoadingNewSubscription(false);
				setUpdateMessage(
					'Tack för din intresseanmälan! Du kommer att notifieras via e-post när en ny tjänst läggs ut.'
				);
				setUpdateType('success');
				setHasUpdated(true);
				setTimeout(() => {
					setUpdateMessage('');
					setUpdateType('');
					setHasUpdated(false);
					setCreatingNewSubscription(false);
				}, 5000);
			})
			.catch(error => {
				console.error(error);
				setLoadingNewSubscription(false);
				setUpdateMessage('Okänt fel - det gick inte att skapa en bevakning.');
				setUpdateType('error');
				setHasUpdated(true);
				setTimeout(() => {
					setUpdateMessage('');
					setUpdateType('');
					setHasUpdated(false);
					setCreatingNewSubscription(false);
				}, 5000);
			});
	};
	const getPage = () => {
		api
			.get('/pages/getone/' + 'karriär')
			.then(response => setPage(response.data))
			.catch(error => console.error(error));
	};

	const savePage = () => {
		api
			.put('/pages/edit/' + 'karriär', page)
			.then(response => isEditing(false))
			.catch(error => console.error(error));
	};
	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={t('topbar.career')} />
			<PageIntro
				data={page}
				setData={setPage}
				savePage={savePage}
				isEditing={isEditing}
				editing={editing}
			/>
			<Grid
				item
				xs={12}
				md={12}
				sx={{
					maxWidth: '1300px',
				}}
			>
				<Grid container spacing={2} sx={{padding: '0 1rem'}}>
					{editing ? (
						<React.Fragment>
							<Grid item xs={12} md={6}>
								<TextEditor
									setText={newText => setPage({...page, textSwedish: newText})}
									text={page.textSwedish}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextEditor
									setText={newText => setPage({...page, textEnglish: newText})}
									text={page.textEnglish}
								/>
							</Grid>
						</React.Fragment>
					) : (
						<Grid item xs={12}>
							{language === 'sv' ? (
								<React.Fragment>
									{data.textSwedish !== null &&
										data.textSwedish?.length > 0 &&
										parse(data.textSwedish)}
								</React.Fragment>
							) : (
								<React.Fragment>
									{data.textEnglish !== null &&
										data.textEnglish?.length > 0 &&
										parse(data.textEnglish)}
								</React.Fragment>
							)}
						</Grid>
					)}

					<Grid item xs={12} sx={{padding: '0 10px'}}>
						<div>
							<p style={{fontSize: '1.4rem'}}>{t('careerspage.availablejobs')}</p>
						</div>
						<div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
							<div>
								<Tooltip title="Meddela mig när en ny tjänst läggs upp">
									<Button
										startIcon={<NotificationAddIcon />}
										onClick={() => {
											setCreatingNewSubscription(true);
										}}
										size="large"
										variant="contained"
										style={{
											background: '#4cae52',
											textTransform: 'none',
											fontFamily: 'Titillium Web',
										}}
									>
										Bevaka
									</Button>
								</Tooltip>
							</div>
							<div>
								{authContext.state.isAuthenticated && isAdmin(authContext.state.email) && (
									<Button
										startIcon={<Add />}
										onClick={() => {
											setCreatingNewJob(true);
										}}
										size="large"
										variant="contained"
										style={{
											background: '#4cae52',
											textTransform: 'none',
											fontFamily: 'Titillium Web',
										}}
									>
										Lägg upp ny tjänst
									</Button>
								)}
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						style={{margin: '0 auto', maxWidth: '1300px', padding: '1rem'}}
					>
						{data.length === 0 ? (
							<p>{t('careerspage.nojobsavailable')}</p>
						) : (
							<>
								{data.map((job, index) => (
									<Card key={index} style={{padding: '1rem 0', margin: '0'}}>
										<CardContent>
											<Link to={'/ledigajobb/' + job._id}>
												<h3>{job.title}</h3>
												<p>{job.subtitle}</p>
												<small>Annons-ID: {job._id}</small>{' '}
											</Link>
										</CardContent>

										{authContext.state.isAuthenticated && isAdmin(authContext.state.email) && (
											<CardActions style={{display: 'flex', justifyContent: 'right', gap: '1rem'}}>
												<Button
													variant="contained"
													startIcon={<Edit />}
													style={{
														background: '#4cae52',
														textTransform: 'none',
														opacity: '0.8',
														fontFamily: 'Titillium Web',
													}}
													onClick={() => {
														setSelectedJob(job);
														setEditingJob(true);
													}}
												>
													Redigera
												</Button>
												{isAdmin(authContext.state.email) && (
													<React.Fragment>
														{' '}
														{confirmDelete && selectedJob._id === job._id ? (
															<>
																<p>Är du säker på att du vill radera denna tjänst?</p>
																<LoadingButton
																	variant="contained"
																	startIcon={<Check />}
																	loading={loadingDeleteJob}
																	style={{
																		background: '#FFCCCB',
																		color: 'black',
																		textTransform: 'none',
																		fontFamily: 'Titillium Web',
																	}}
																	onClick={() => {
																		setLoadingDeleteJob(true);
																		deleteJob(job);
																	}}
																>
																	Ja, radera
																</LoadingButton>
																<Button
																	variant="outlined"
																	startIcon={<Clear />}
																	style={{
																		color: 'black',
																		textTransform: 'none',
																		fontFamily: 'Titillium Web',
																	}}
																	onClick={() => {
																		setConfirmDelete(false);
																	}}
																>
																	Nej, avbryt
																</Button>
															</>
														) : (
															<LoadingButton
																variant="contained"
																startIcon={<Delete />}
																style={{
																	background: '#FFCCCB',
																	color: 'black',
																	textTransform: 'none',
																	fontFamily: 'Titillium Web',
																}}
																onClick={() => {
																	setSelectedJob(job);
																	setConfirmDelete(true);
																}}
															>
																Radera
															</LoadingButton>
														)}
													</React.Fragment>
												)}
											</CardActions>
										)}
									</Card>
								))}
							</>
						)}
					</Grid>
					{authContext.state.isAuthenticated && (
						<Grid item xs={12}>
							<Table>
								<TableBody>
									{subscriptions.map((x, index) => (
										<React.Fragment key={index}>
											<TableRow>
												<TableCell>{x.email}</TableCell>

												<TableCell>{x.createdDate}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell colSpan={2}>
													{[
														{name: 'Analys', value: x.analysis},
														{name: 'Due Diligence', value: x.duediligence},
														{name: 'Fastighetsutveckling', value: x.realestatedevelopment},
														{name: 'Hållbarhet', value: x.sustainability},
														{name: 'Byggherreombud', value: x.builddevrep},
														{name: 'Projektledning', value: x.projectmanagement},
													].map(y => y.value && y.name + ' - ')}
												</TableCell>
											</TableRow>
										</React.Fragment>
									))}
								</TableBody>
							</Table>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Dialog
				open={creatingNewJob || editingJob}
				onClose={() => {
					setEditingJob(false);
					setCreatingNewJob(false);
				}}
				maxWidth="xl"
			>
				<DialogTitle>{editingJob ? 'Redigera tjänst' : 'Lägg till ny tjänst'}</DialogTitle>
				<DialogContent>
					<Grid container style={{padding: '2rem'}}>
						<Grid item xs={12}>
							<FormControl margin="dense" fullWidth>
								<TextField
									label="Titel"
									type="text"
									size="small"
									onChange={handleChange}
									value={selectedJob.title}
									name="title"
									id="title"
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<FormControl margin="dense" fullWidth>
								<TextEditor
									setText={newText => setSelectedJob({...selectedJob, text: newText})}
									text={selectedJob.text}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{editingJob ? (
						<LoadingButton
							loading={loadingNewJob}
							color="primary"
							size="large"
							variant="contained"
							onClick={() => {
								setLoadingNewJob(true);
								updateJob(selectedJob);
							}}
						>
							Spara
						</LoadingButton>
					) : (
						<LoadingButton
							loading={loadingNewJob}
							color="primary"
							size="large"
							variant="contained"
							onClick={() => {
								setLoadingNewJob(true);
								createNewJob();
							}}
						>
							Spara
						</LoadingButton>
					)}
				</DialogActions>
			</Dialog>
			<Dialog
				open={creatingNewSubscription}
				onClose={() => {
					setCreatingNewSubscription(false);
				}}
				maxWidth="md"
			>
				{hasUpdated && (
					<DialogContent style={{height: '300px', alignContent: 'center', alignItems: 'center'}}>
						<Alert severity={updateType} style={{margin: '5rem auto'}}>
							{updateMessage}
						</Alert>
					</DialogContent>
				)}
				{!hasUpdated && (
					<DialogContent style={{height: '450px'}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<p>Meddela mig när en ny tjänst läggs ut</p>
								<Divider style={{width: '100%', color: 'gray', margin: '0'}} />

								<p>Bevakningar</p>

								<FormControl component="fieldset" style={{margin: 0, padding: 0}}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={jobInterest.analysis}
													onChange={event => {
														setJobInterest({
															...jobInterest,
															analysis: !jobInterest.analysis,
														});
													}}
													name="analysis"
												/>
											}
											label="Analys"
										/>
									</FormGroup>
								</FormControl>
								<FormControl component="fieldset" style={{margin: 0, padding: 0}}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={jobInterest.duediligence}
													onChange={event => {
														setJobInterest({
															...jobInterest,
															duediligence: !jobInterest.duediligence,
														});
													}}
													name="duediligence"
												/>
											}
											label="Due Diligence"
										/>
									</FormGroup>
								</FormControl>
								<FormControl component="fieldset" style={{margin: 0, padding: 0}}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={jobInterest.sustainability}
													onChange={event => {
														setJobInterest({
															...jobInterest,
															sustainability: !jobInterest.sustainability,
														});
													}}
													name="sustainability"
												/>
											}
											label="Hållbarhet"
										/>
									</FormGroup>
								</FormControl>
								<FormControl component="fieldset" style={{margin: 0, padding: 0}}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={jobInterest.realestatedevelopment}
													onChange={event => {
														setJobInterest({
															...jobInterest,
															realestatedevelopment: !jobInterest.realestatedevelopment,
														});
													}}
													name="analysis"
												/>
											}
											label="Fastighetsutveckling"
										/>
									</FormGroup>
								</FormControl>
								<FormControl component="fieldset" style={{margin: 0, padding: 0}}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={jobInterest.builddevrep}
													onChange={event => {
														setJobInterest({
															...jobInterest,
															builddevrep: !jobInterest.builddevrep,
														});
													}}
													name="builddevrep"
												/>
											}
											label="Byggherreombud"
										/>
									</FormGroup>
								</FormControl>
								<FormControl component="fieldset" style={{margin: 0, padding: 0}}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={jobInterest.projectmanagement}
													onChange={event => {
														setJobInterest({
															...jobInterest,
															projectmanagement: !jobInterest.projectmanagement,
														});
													}}
													name="analysis"
												/>
											}
											label="Projektledning"
										/>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl margin="dense" fullWidth>
									<TextField
										label="Email"
										type="text"
										size="small"
										onChange={event => setJobInterest({...jobInterest, email: event.target.value})}
										value={jobInterest.email}
										name="email"
										id="email"
									/>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
				)}
				<DialogActions>
					{!hasUpdated && (
						<LoadingButton
							loading={loadingNewSubscription}
							color="primary"
							size="large"
							variant="contained"
							style={{color: 'white', textTransform: 'none'}}
							onClick={() => {
								setLoadingNewSubscription(true);
								createNewSubscription();
							}}
						>
							Skapa bevakning
						</LoadingButton>
					)}
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

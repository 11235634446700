import React, {useContext, useEffect} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CircularProgress,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import {useState} from 'react';
import api from '../../api';
import {Edit, GetApp} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../../App';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';
import {getBaseUrl} from '../../api';
import Slider from 'react-slick';
import parse from 'html-react-parser';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
const ViewProjectPage = props => {
	const language = localStorage.getItem('i18nextLng');
	const history = useHistory();
	const {t} = useTranslation();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loadingPdf, setLoadingPdf] = useState(false);
	const [loadingPrint, setLoadingPrint] = useState(false);
	const [contactPersons, setContactPersons] = useState([]);

	const [contactThatIsBeingExported, setContactThatIsBeingExported] = useState(false);
	const [loading, isLoading] = useState(true);
	const settings = {
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		arrows: false,
		autoplay: true,
		slidesToScroll: 1,
	};
	const authContext = useContext(AuthContext);
	const [data, setData] = useState({
		_id: '',
		title: '',
		address: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		swedishStatus: '',
		swedishPropertyOwner: '',
		swedishProjectRole: '',
		swedishProjectType: '',
		swedishSize: '',
		swedishProjectTime: '',
		sustainabilitySwedish: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		englishStatus: '',
		englishPropertyOwner: '',
		englishProjectRole: '',
		englishProjectType: '',
		englishSize: '',
		englishProjectTime: '',
		sustainabilityEnglish: '',
		size: 0,
		showOnStartPage: false,
		link: '',
		date: '',
		pictureUrl: '',
		pictures: [],
		url: '',
		tags: '',
		contactPersons: [],
		createdBy: '',
		createdDate: '',
		editedBy: '',
		editedDate: '',
	});
	useEffect(() => {
		getProject();
	}, []);
	const getProject = () => {
		api
			.get('/project/project/' + props.match.params.url)
			.then(response => {
				setData(response.data.project);
				setContactPersons(response.data.contactPersons);
				if (language === 'sv') {
					document.title = response.data.project.swedishTitle;
				} else {
					document.title = response.data.project.englishTitle;
				}

				isLoading(false);
			})
			.catch(error => console.error(error));
	};
	const deleteProject = () => {
		api
			.delete('/project/project/' + data._id)
			.then(() => {
				setLoadingDelete(false);
				history.push('/projekt');
			})
			.catch(error => {
				setLoadingDelete(false);
				console.error(error);
			});
	};
	const downloadProjectDescription = lang => {
		setLoadingPdf(true);
		let fileName;
		if (language === 'en') {
			fileName = data.swedishTitle.length > 0 ? data.swedishTitle : data.url || data._id;
		} else {
			fileName = data.englishTitle.length > 0 ? data.swedishTitle : data.url || data._id;
		}

		api
			.post('/generatepdfreport', {
				project_id: data._id,
				fileName: fileName,
				project: data,
				language: lang,
			})
			.then(() => {
				setLoadingPdf(false);
				const link = document.createElement('a');
				link.download = data._id + '.pdf';
				link.href = getBaseUrl() + '/downloadprojectdescription/' + fileName;
				link.click();
			})

			.catch(error => {
				setLoadingPdf(false);
				console.error(error);
			});
	};
	const printProjectDescription = lang => {
		setLoadingPrint(true);
		api
			.post('/generatepdfreport', {project_id: data._id, project: data, language: lang})
			.then(() => {
				api
					.get(getBaseUrl() + '/downloadprojectdescription/' + data._id, {
						responseType: 'blob',
					})
					.then(response => {
						const file = new Blob([response.data], {type: 'application/pdf'});
						const fileURL = URL.createObjectURL(file);
						const pdfWindow = window.open();
						pdfWindow.location.href = fileURL;
						setLoadingPrint(false);
					})
					.catch(error => {
						console.error(error);
						setLoadingPrint(false);
					});
			})
			.catch(error => {
				console.error(error);
				setLoadingPrint(false);
			});
	};
	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				maxWidth: '1300px',
				minHeight: '50vh',
				padding: {xs: '4rem 1rem 0', sm: '8rem 1rem 0', md: '8rem 1rem 0', xl: '8rem 1rem 0'},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: {xs: '1rem', md: '3rem'},
					flexWrap: 'wrap',
				}}
			>
				<BreadCrumb
					mainLinkUrl={'/projekt'}
					mainLinkWord={t('topbar.projects')}
					title={language === 'sv' ? data.swedishTitle : data.englishTitle}
				/>

				{authContext.state.isAuthenticated && (
					<div>
						<Grid container>
							<Grid
								item
								xs={12}
								style={{
									padding: '0',
									display: 'flex',
									justifyContent: 'space-between',
									gap: '1rem',
								}}
							>
								{authContext.state.isAuthenticated && (
									<Button
										startIcon={<Edit />}
										onClick={() => history.push('/projects/' + data._id)}
										variant="contained"
										style={{
											background: '#4cae52',
											textTransform: 'none',
											fontFamily: 'Titillium Web',
										}}
									>
										Redigera
									</Button>
								)}
							</Grid>
						</Grid>
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
			</Grid>
			<Grid item xs={12} md={7}>
				<Grid container>
					<Grid item xs={12} style={{paddingBottom: '1rem'}}>
						<Slider {...settings}>
							<div>
								<img
									src={data.pictureUrl}
									style={{maxWidth: '90%', cursor: 'pointer'}}
									alt={data.title}
									onClick={() => window.open(data.pictureUrl, '_blank')}
								/>
								<p>
									{language === 'sv'
										? data.pictureDescriptionSwedish
										: data.pictureDescriptionEnglish}
								</p>
							</div>
							{data.pictures.length > 0 &&
								data.pictures.map(p => (
									<div key={p._id}>
										<img
											src={p.url}
											style={{maxWidth: '90%', cursor: 'pointer'}}
											alt={data.title}
											onClick={() => window.open(data.pictureUrl, '_blank')}
										/>
										<div style={{padding: '10px 0'}}>
											<Typography variant="body1">
												{language === 'sv' ? p.swedishDescription : p.englishDescription}
											</Typography>
										</div>
									</div>
								))}
						</Slider>
					</Grid>
					{language === 'sv' && (
						<Grid item xs={12} style={{padding: '0 1rem 0 0'}}>
							<h2>{data.title}</h2>
							<Typography variant="body1">{data.swedishSubtitle}</Typography>
							<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />

							{data.swedishText !== null && data.swedishText?.length > 0 && parse(data.swedishText)}

							<p style={{color: '#4cae52', fontSize: '1.3rem'}}>
								<i>{data.swedishQuote}</i>
							</p>
						</Grid>
					)}
					{language === 'en' && (
						<Grid item xs={12} style={{padding: '0 1rem 0 0'}}>
							<h2 style={{marginTop: '0'}}>{data.title}</h2>
							{data.englishSubtitle !== null && data.englishSubtitle?.length > 0 && (
								<Typography variant="body1">{data.englishSubtitle}</Typography>
							)}
							{data.englishText !== null && data.englishText?.length > 0 && parse(data.englishText)}
							<p style={{color: '#4cae52', fontSize: '1.3rem'}}>
								<i>{data.englishQuote}</i>
							</p>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12} md={5} style={{padding: '0 1rem 0 0', margin: '0'}}>
				{language === 'sv' ? (
					<Card style={{background: '#d3d3d3', padding: '1rem', margin: 0}}>
						<h3>Fakta</h3>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Status</p>
						<p style={{lineHeight: '22px'}}>{data.swedishStatus}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Byggherre/Beställare</p>
						<p style={{lineHeight: '22px'}}>{data.swedishPropertyOwner}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Projektroll</p>
						<p style={{lineHeight: '22px'}}>{data.swedishProjectRole}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Projekttyp</p>
						<p style={{lineHeight: '22px'}}>{data.swedishProjectType}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Adress</p>
						<p style={{lineHeight: '22px'}}>{data.address}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Yta</p>
						<p style={{lineHeight: '22px'}}>{data.swedishSize}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Projekttid</p>
						<p style={{lineHeight: '22px'}}>{data.swedishProjectTime}</p>

						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Hållbarhet</p>
						<p style={{lineHeight: '15px'}}>{data.sustainabilitySwedish}</p>
						<CardActions>
							<LoadingButton
								onClick={() => downloadProjectDescription('sv')}
								variant="contained"
								loading={loadingPdf}
								startIcon={<GetApp />}
								style={{
									background: '#4cae52',
									textTransform: 'none',
									fontFamily: 'Titillium Web',
								}}
							>
								Ladda ned projektbeskrivning
							</LoadingButton>
							{/*
								<LoadingButton
									onClick={() => printProjectDescription('sv')}
									variant="contained"
									loading={loadingPrint}
									startIcon={<Print />}
									style={{
										background: '#4cae52',
										textTransform: 'none',
										fontFamily: 'Titillium Web',
									}}
								>
									Skriv ut
								</LoadingButton>*/}
						</CardActions>
					</Card>
				) : (
					<Card style={{background: '#d3d3d3', padding: '1rem'}}>
						<h3>Facts</h3>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Status</p>
						<p style={{lineHeight: '22px'}}>{data.englishStatus}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Client/Property Owner</p>
						<p style={{lineHeight: '22px'}}>{data.englishPropertyOwner}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Project role</p>
						<p style={{lineHeight: '22px'}}>{data.englishProjectRole}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Project type</p>
						<p style={{lineHeight: '22px'}}>{data.englishProjectType}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Address</p>
						<p style={{lineHeight: '22px'}}>{data.address}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Size</p>
						<p style={{lineHeight: '22px'}}>{data.englishSize}</p>
						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Project time</p>
						<p style={{lineHeight: '22px'}}>{data.englishProjectTime}</p>

						<p style={{fontWeight: 'bold', lineHeight: '7px'}}>Sustainability</p>
						<p style={{lineHeight: '7px'}}>{data.sustainabilityEnglish}</p>
						<CardActions>
							<LoadingButton
								onClick={() => downloadProjectDescription('en')}
								startIcon={<GetApp />}
								loading={loadingPdf}
								variant="contained"
								style={{
									background: '#4cae52',
									textTransform: 'none',
									fontFamily: 'Titillium Web',
								}}
							>
								Download project description
							</LoadingButton>
							{/*
								<LoadingButton
									onClick={() => printProjectDescription('en')}
									variant="contained"
									loading={loadingPrint}
									startIcon={<Print />}
									style={{
										background: '#4cae52',
										textTransform: 'none',
										fontFamily: 'Titillium Web',
									}}
								>
									Print
								</LoadingButton>*/}
						</CardActions>
					</Card>
				)}
			</Grid>
			{contactPersons.length > 0 && (
				<Grid item xs={12} md={12} xl={12} style={{padding: '1rem 10px'}}>
					<h3>Kontaktpersoner</h3>
					{loading ? (
						<CircularProgress />
					) : (
						<Grid container spacing={2}>
							{contactPersons.map((c, index) => (
								<Grid item xs={12} sm={6} md={3} xl={3} key={c._id} id={c._id}>
									<Card>
										<CardMedia component="img" image={c.pictureUrl} alt="Paella dish"></CardMedia>
										<CardContent style={{fontSize: '0.9rem'}}>
											<p style={{lineHeight: '3px', color: '#3EAC48', fontSize: '1.2rem'}}>
												<strong>{c.fullName}</strong>
											</p>
											<p>
												<strong>{t('viewprojectpage.roleinproject')}: </strong>
												{language === 'sv' ? c.swedishRole : c.englishRole}
											</p>
											<p> {language === 'sv' ? c.swedishDescription : c.englishDescription}</p>
											<p>
												<strong>{t('contactpage.phone')}</strong>
											</p>
											<p>
												<a href={c.tel}>{c.displayPhone}</a>
											</p>
											<p>
												<strong>{t('contactpage.email')}</strong>
											</p>
											<p>
												<a href={'mailto:' + c.email}>{c.email.replace('@', '[a]')}</a>
											</p>
										</CardContent>
									</Card>
								</Grid>
							))}
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	);
};
export default ViewProjectPage;

export const exportContact = (isLoading, data) => {
	isLoading(true);
	const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${data.firstName} ${data.lastName}
N;CHARSET=UTF-8:${data.lastName};${data.firstName}
TEL;TYPE=WORK:${data.phone}
TEL;TYPE=CELL:${data.phone}
EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${data.email}
ORG:RED Management
LABEL;CHARSET=UTF-8;TYPE=WORK:Jobb
ADR;CHARSET=UTF-8;TYPE=WORK:;;Vasagatan 15-17;Stockholm;Stockholms Län;11120;Sverige
TITLE;CHARSET=UTF-8:${data.swedishRole}
ROLE;CHARSET=UTF-8:${data.swedishRole}
ORG;CHARSET=UTF-8:RED Management AB
URL;type=Personal Profile page;CHARSET=UTF-8:https://redmanagement.se/kontakt/${data.email}
URL;type=Company website;CHARSET=UTF-8:https://redmanagement.se/
X-SOCIALPROFILE;TYPE=linkedin:https://www.linkedin.com/company/red-management
END:VCARD
    `;

	const blob = new Blob([vCardData], {type: 'text/vcard'});
	const vCardURL = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = vCardURL;
	a.download = `${data.firstName}_${data.lastName}.vcf`;
	a.click();
	URL.revokeObjectURL(vCardURL);
	isLoading(false);
};

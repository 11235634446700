import React, {useEffect} from 'react';
import {Divider, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import headerImg from '../../assets/about_us.jpg';

export default function IntegrityPolicyPage() {
	const {t} = useTranslation();
	useEffect(() => {
		document.title = t('footer.integritypolicy');
	}, []);
	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				maxWidth: '1300px',
				minHeight: '50vh',
				padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
			}}
		>
			<Grid item xs={12} md={12} style={{margin: '0 auto', maxWidth: '1300px'}}>
				<h1 style={{fontWeight: 'normal', margin: 0, color: '#4cae52'}}>
					{t('integritypolicypage.title')}
				</h1>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto 0'}} />

				<p style={{fontSize: '1.4rem'}}>{t('integritypolicypage.section1text')}</p>
				<p style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
					{t('integritypolicypage.section2title')}
				</p>
				<p>{t('integritypolicypage.section2text')}</p>
				<p style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
					{t('integritypolicypage.section3title')}
				</p>
				<p>{t('integritypolicypage.section3text')}</p>
				<p style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
					{t('integritypolicypage.section4title')}
				</p>
				<p>{t('integritypolicypage.section4text')}</p>
				<p style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
					{t('integritypolicypage.section5title')}
				</p>
				<p>{t('integritypolicypage.section5text1')}</p>
				<p>{t('integritypolicypage.section5text2')}</p>
				<p style={{fontSize: '1.4rem', fontWeight: 'bold'}}>
					{t('integritypolicypage.contacttitle')}
				</p>
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.name')}</p>
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.phone')}</p>
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.email')}</p>
				<br />
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.companyname')}</p>
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.street')}</p>
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.postalcodeandcity')}</p>
				<p style={{lineHeight: '3px'}}>{t('integritypolicypage.corporateidentitynumber')}</p>
			</Grid>
		</Grid>
	);
}

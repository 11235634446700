import React, {useContext, useEffect, useState} from 'react';
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import api from '../../api';
import {Add, CameraAlt, Check, Clear, Delete, Edit, Visibility} from '@mui/icons-material';
import {Link, useHistory} from 'react-router-dom';
import {Autocomplete, LoadingButton} from '@mui/lab';
import {useRef} from 'react';
import {isAdmin} from '../../utils/Authentication';
import {AuthContext} from '../../App';
import TextEditor from '../../components/TextEditor/TextEditor';
import Pictures from '../../components/Pictures';

const EditProjectPage = props => {
	const history = useHistory();
	const [contactPersonSearch, setContactPersonSearch] = useState(null);
	const [contactPersonSearchInput, setContactPersonSearchInput] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const inputRef = useRef();
	const [photoThatIsBeingEdited, setPhotoThatIsBeingEdited] = useState('');
	const [contactPersons, setContactPersons] = useState([]);
	const authContext = useContext(AuthContext);

	const [data, setData] = useState({
		_id: '',
		title: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		swedishStatus: '',
		swedishPropertyOwner: '',
		swedishProjectRole: '',
		swedishProjectType: '',
		swedishSize: '',
		swedishProjectTime: '',
		sustainabilitySwedish: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		englishStatus: '',
		englishPropertyOwner: '',
		englishProjectRole: '',
		englishProjectType: '',
		englishSize: '',
		englishProjectTime: '',
		sustainabilityEnglish: '',
		size: 0,
		showOnStartPage: false,
		published: false,
		address: '',
		link: '',
		date: '',
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		url: '',
		tags: '',
		contactPersons: [],
		createdBy: '',
		createdDate: '',
		editedBy: '',
		editedDate: '',
	});
	const [persons, setPersons] = useState([]);
	useEffect(() => {
		getProject();
		getPersons();
	}, []);
	const deleteContactPerson = _personId => {
		api
			.delete('/project/deletecontactperson/' + data._id + '/' + _personId)
			.then(() => {
				const newData = contactPersons.filter(p => p._personId !== _personId);
				setContactPersons(newData);
				const newD = [...data.contactPersons].filter(p => p._personId !== _personId);
				setData({...data, contactPersons: newD});
			})
			.catch(error => console.error(error));
	};
	const deletePhoto = photo => {
		api
			.delete('/project/deletephoto/' + data._id + '/' + photo._id)
			.then(() => {
				const newData = data.pictures.filter(p => p._id !== photo._id);
				setData({...data, pictures: newData});
			})
			.catch(error => console.error(error));
	};
	const editContactPerson = (_personId, newValue, attributeName) => {
		const newData = contactPersons.map(object => {
			if (object._personId === _personId) {
				return {
					...object,
					[attributeName]: newValue,
				};
			} else return object;
		});
		setContactPersons(newData);
		updateProject({...data, contactPersons: newData});
	};
	const getProject = () => {
		api
			.get('/project/projects/' + props.match.params.project_id)
			.then(response => {
				setData(response.data.project);
				setContactPersons(response.data.contactPersons);

				document.title = 'Redigera projekt: ' + response.data.swedishTitle;
			})
			.catch(error => console.error(error));
	};
	const getPersons = () => {
		api
			.get('/person/persons')
			.then(response => setPersons(response.data))
			.catch(error => console.error(error));
	};
	const handleChange = event => {
		if (event.target.value !== null) {
			if (event.target.name === 'title') {
				const newUrl = event.target.value.replaceAll(' ', '-').toLowerCase();
				setData({
					...data,
					url: newUrl,
					title: event.target.value,
				});
				updateProject({...data, url: newUrl, title: event.target.value});
			}
			setData({...data, [event.target.name]: event.target.value});
			updateProject({...data, [event.target.name]: event.target.value});
		}
	};
	const updateProject = update => {
		api.put('/project/editproject/' + data._id, update).catch(error => console.error(error));
	};
	const deleteProject = () => {
		api
			.delete('/project/project/' + data._id)
			.then(() => {
				setLoadingDelete(false);
				history.push('/projekt');
			})
			.catch(error => {
				setLoadingDelete(false);
				console.error(error);
			});
	};
	const addNewContactPerson = newContactPerson => {
		api
			.post('/project/addnewcontactperson/' + data._id, {
				_personId: newContactPerson._id,
			})
			.then(response => {
				setData(response.data.item);
				setContactPersons(response.data.contactPersons);
			})
			.catch(error => console.error(error));
	};
	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				minHeight: '50vh',
				maxWidth: '1300px',
				padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: {xs: '1rem', md: '3rem'},
					flexWrap: 'wrap',
				}}
			>
				<div>
					<Link to={'/projekt'} style={{cursor: 'pointer'}}>
						<h3 style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}>
							Projekt
						</h3>
					</Link>
					<div style={{padding: '0 1rem', display: 'inline'}}>/</div>

					<h3 style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}>
						{data.swedishTitle}
					</h3>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'right',
						gap: '10px',
						flexDirection: 'row',
						flexWrap: 'wrap',
					}}
				>
					{confirmDelete && (
						<div>
							<p style={{margin: '0 1rem 0 0', padding: 0}}>
								Är du säker på att du vill radera detta projekt?
							</p>
						</div>
					)}
					{confirmDelete ? (
						<>
							<LoadingButton
								variant="contained"
								color="primary"
								startIcon={<Check />}
								loading={loadingDelete}
								style={{
									textTransform: 'none',
									fontFamily: 'Titillium Web',
								}}
								onClick={() => {
									setLoadingDelete(true);
									deleteProject(data);
								}}
							>
								Ja, radera
							</LoadingButton>
							<Button
								variant="outlined"
								startIcon={<Clear />}
								style={{
									color: 'black',
									marginLeft: '1rem',

									textTransform: 'none',
									fontFamily: 'Titillium Web',
								}}
								onClick={() => {
									setConfirmDelete(false);
								}}
							>
								Nej, avbryt
							</Button>
						</>
					) : (
						<>
							<FormControlLabel
								control={
									<Switch
										checked={data.showOnStartPage}
										disabled={!isAdmin(authContext.state.email)}
										onChange={() => {
											updateProject({...data, showOnStartPage: !data.showOnStartPage});
											setData({
												...data,
												showOnStartPage: !data.showOnStartPage,
											});
										}}
										name="showOnStartPage"
									/>
								}
								label={data.showOnStartPage ? 'Visas på startsida' : 'Visas ej på startsida'}
							/>
							<FormControlLabel
								control={
									<Switch
										checked={data.published}
										disabled={!isAdmin(authContext.state.email)}
										onChange={() => {
											updateProject({...data, published: !data.published});
											setData({
												...data,
												published: !data.published,
											});
										}}
										name="published"
									/>
								}
								label={data.published ? 'Är publicerad' : 'Är ej publicerad'}
							/>
							<Button
								startIcon={<Visibility />}
								onClick={() => history.push('/projekt/' + data.url)}
								variant="contained"
								style={{
									background: '#4cae52',
									textTransform: 'none',
									fontFamily: 'Titillium Web',
								}}
							>
								Förhandsgranska
							</Button>
							{isAdmin(authContext.state.email) && (
								<LoadingButton
									variant="contained"
									startIcon={<Delete />}
									style={{
										background: '#FFCCCB',
										color: 'black',
										textTransform: 'none',
										fontFamily: 'Titillium Web',
									}}
									onClick={() => {
										setConfirmDelete(true);
									}}
								>
									Radera projekt
								</LoadingButton>
							)}
						</>
					)}
				</div>
			</Grid>
			<Grid item xs={12}>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
			</Grid>{' '}
			<Grid item xs={12}>
				<Pictures
					item={data}
					setItem={setData}
					getData={getProject}
					attributeName="projects"
					english={true}
					updateItem={updateProject}
				/>
			</Grid>
			<Grid item xs={12}>
				{data.title}
			</Grid>
			<Grid item xs={12} md={6} style={{paddingRight: '1rem'}}>
				<Grid container>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Projektnamn"
								type="text"
								size="small"
								value={data.swedishTitle}
								onChange={handleChange}
								name="swedishTitle"
								id="swedishTitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Ingress"
								type="text"
								size="small"
								multiline
								minRows={2}
								value={data.swedishSubtitle}
								onChange={handleChange}
								name="swedishSubtitle"
								id="swedishSubtitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Citat"
								type="text"
								size="small"
								multiline
								minRows={2}
								value={data.swedishQuote}
								onChange={handleChange}
								name="swedishQuote"
								id="swedishQuote"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextEditor
								setText={newText => {
									const update = {...data, swedishText: newText};
									updateProject(update);
								}}
								text={data.swedishText}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Status"
								type="text"
								size="small"
								value={data.swedishStatus}
								onChange={handleChange}
								name="swedishStatus"
								id="swedishStatus"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Byggherre/Beställare"
								type="text"
								size="small"
								value={data.swedishPropertyOwner}
								onChange={handleChange}
								name="swedishPropertyOwner"
								id="swedishPropertyOwner"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Projektroll"
								type="text"
								size="small"
								value={data.swedishProjectRole}
								onChange={handleChange}
								name="swedishProjectRole"
								id="swedishProjectRole"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Projekttyp"
								type="text"
								size="small"
								value={data.swedishProjectType}
								onChange={handleChange}
								name="swedishProjectType"
								id="swedishProjectType"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Yta"
								type="text"
								size="small"
								value={data.swedishSize}
								onChange={handleChange}
								name="swedishSize"
								id="swedishSize"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Projekttid"
								type="text"
								size="small"
								value={data.swedishProjectTime}
								onChange={handleChange}
								name="swedishProjectTime"
								id="swedishProjectTime"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Hållbarhet"
								type="text"
								size="small"
								multiline
								minRows={2}
								value={data.sustainabilitySwedish}
								onChange={handleChange}
								name="sustainabilitySwedish"
								id="sustainabilitySwedish"
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid container>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Project title"
								type="text"
								size="small"
								value={data.englishTitle}
								onChange={handleChange}
								name="englishTitle"
								id="englishTitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Project subtitle"
								type="text"
								multiline
								minRows={2}
								size="small"
								value={data.englishSubtitle}
								onChange={handleChange}
								name="englishSubtitle"
								id="englishSubtitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Quote"
								type="text"
								multiline
								minRows={2}
								size="small"
								value={data.englishQuote}
								onChange={handleChange}
								name="englishQuote"
								id="englishQuote"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextEditor
								setText={newText => {
									const update = {...data, englishText: newText};
									updateProject(update);
								}}
								text={data.englishText}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Status"
								type="text"
								size="small"
								value={data.englishStatus}
								onChange={handleChange}
								name="englishStatus"
								id="englishStatus"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Client/property owner"
								type="text"
								size="small"
								value={data.englishPropertyOwner}
								onChange={handleChange}
								name="englishPropertyOwner"
								id="englishPropertyOwner"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Project role"
								type="text"
								size="small"
								value={data.englishProjectRole}
								onChange={handleChange}
								name="englishProjectRole"
								id="englishProjectRole"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Project type"
								type="text"
								size="small"
								value={data.englishProjectType}
								onChange={handleChange}
								name="englishProjectType"
								id="englishProjectType"
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Size"
								type="text"
								size="small"
								value={data.englishSize}
								onChange={handleChange}
								name="englishSize"
								id="englishSize"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Project time"
								type="text"
								size="small"
								value={data.englishProjectTime}
								onChange={handleChange}
								name="englishProjectTime"
								id="englishProjectTime"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								label="Sustainability"
								type="text"
								size="small"
								value={data.sustainabilityEnglish}
								onChange={handleChange}
								multiline
								minRows={2}
								name="sustainabilityEnglish"
								id="sustainabilityEnglish"
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormControl margin="dense" fullWidth>
					<TextField
						label="Adress/plats"
						type="text"
						size="small"
						value={data.address}
						onChange={handleChange}
						name="address"
						id="address"
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControl margin="dense" fullWidth>
					<TextField
						label="Url"
						type="text"
						size="small"
						value={data.url}
						onChange={handleChange}
						name="url"
						id="url"
						helperText="Detta är den URL som sedan används för att länka till projektet"
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6} style={{paddingLeft: '1rem'}}>
				<p
					className="hover-link"
					style={{cursor: 'pointer'}}
					onClick={() =>
						window.open(
							'https://redmanagement.se/projekt/' + data.url,
							'_blank',
							'toolbar=0,location=0,menubar=0'
						)
					}
				>
					{'https://redmanagement.se/projekt/' + data.url}
				</p>
			</Grid>
			<Grid item xs={12}>
				<h3>Kontaktpersoner</h3>
			</Grid>
			<Grid item xs={12}>
				<Autocomplete
					value={contactPersonSearch}
					onChange={(event, newValue) => {
						if (newValue !== null) {
							setContactPersonSearchInput('');
							setContactPersonSearch(null);
							addNewContactPerson(newValue);
						}
					}}
					getOptionLabel={option => option.email}
					inputValue={contactPersonSearchInput}
					onInputChange={(event, newInputValue) => {
						setContactPersonSearchInput(newInputValue);
					}}
					id="contactPersonSearch"
					options={persons}
					renderOption={(props, option, {selected}) => (
						<ListItem alignItems="flex-start" {...props} key={option.key}>
							<ListItemAvatar>
								<Avatar alt="Remy Sharp" src={option.pictureUrl} />
							</ListItemAvatar>
							<ListItemText
								primary={option.email}
								secondary={
									<React.Fragment>
										<Typography
											sx={{display: 'inline'}}
											component="span"
											variant="body2"
											color="text.primary"
										>
											{option.firstName + ' ' + option.lastName}
										</Typography>
										{` - ${option.email}`}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
					renderInput={params => (
						<TextField {...params} variant="outlined" label="Lägg till kontaktperson..." />
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{contactPersons.length > 0 &&
						contactPersons.map((c, index) => (
							<Grid item xs={12} key={index}>
								<Card style={{border: 'solid #4cae52 1px', margin: '1rem 0'}}>
									<CardContent style={{fontSize: '0.9rem'}}>
										<p style={{lineHeight: '3px', color: '#3EAC48', fontSize: '1.2rem'}}>
											<strong>{c.email}</strong>
										</p>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<FormControl margin="dense" fullWidth>
													<TextField
														value={c.swedishRole}
														name="swedishRole"
														onChange={event =>
															editContactPerson(c._personId, event.target.value, 'swedishRole')
														}
														multiline
														minRows={1}
														label="Roll i projektet (sv)"
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl margin="dense" fullWidth>
													<TextField
														value={c.englishRole}
														name="englishRole"
														onChange={event =>
															editContactPerson(c._personId, event.target.value, 'englishRole')
														}
														multiline
														minRows={1}
														label="Role in project (en)"
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl margin="dense" fullWidth>
													<TextField
														value={c.swedishDescription}
														name="swedishDescription"
														onChange={event =>
															editContactPerson(
																c._personId,
																event.target.value,
																'swedishDescription'
															)
														}
														multiline
														minRows={3}
														label="Beskrivning av ansvarsområde (sv)"
													/>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												<FormControl margin="dense" fullWidth>
													<TextField
														value={c.englishDescription}
														name="englishText"
														onChange={event =>
															editContactPerson(
																c._personId,
																event.target.value,
																'englishDescription'
															)
														}
														multiline
														minRows={3}
														label="Description of responsibility(en)"
													/>
												</FormControl>
											</Grid>
										</Grid>
									</CardContent>
									<CardActions>
										<LoadingButton
											variant="outlined"
											startIcon={<Clear />}
											style={{
												textTransform: 'none',
											}}
											onClick={() => deleteContactPerson(c._personId)}
										>
											Radera kontaktperson från projektet
										</LoadingButton>
									</CardActions>
								</Card>
							</Grid>
						))}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default EditProjectPage;

import React, {useContext, useEffect, useState} from 'react';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	Divider,
	Grid,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import headerImg from '../../assets/offering.jpg';
import api from '../../api';
import PageHeader from '../../layout/PageHeader';
import {Add, Check, Clear, Delete, Edit} from '@mui/icons-material';
import {AuthContext} from '../../App';
import {Link, useHistory} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import {LoadingButton} from '@mui/lab';
import {isAdmin} from '../../utils/Authentication';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageIntro from '../../layout/PageIntro';
import parse from 'html-react-parser';

export default function ServicesPage() {
	const {t} = useTranslation();
	const isMobileScreen = useMediaQuery('(max-width:700px)');

	const [data, setData] = useState([]);
	const authContext = useContext(AuthContext);
	const history = useHistory();
	const language = localStorage.getItem('i18nextLng');
	const [loading, setLoading] = useState(true);
	const [editing, isEditing] = useState(false);
	const [page, setPage] = useState({});

	useEffect(() => {
		document.title = 'RED Management • ' + t('topbar.services');
		getData();
		getPage();
	}, []);
	const getData = () => {
		api
			.get('/service/getall')
			.then(response => {
				if (authContext.state.isAuthenticated) {
					setData(response.data);
				} else {
					setData(response.data.filter(r => r.service.published));
				}
				setLoading(false);
			})

			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const getPage = () => {
		api
			.get('/pages/getone/' + 'tjänster')
			.then(response => setPage(response.data))
			.catch(error => console.error(error));
	};

	const savePage = () => {
		api
			.put('/pages/edit/' + 'tjänster', page)
			.then(response => isEditing(false))
			.catch(error => console.error(error));
	};
	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={t('topbar.services')} />
			<PageIntro
				data={page}
				setData={setPage}
				savePage={savePage}
				isEditing={isEditing}
				editing={editing}
			/>
			{authContext.state.isAuthenticated && (
				<Grid
					item
					xs={12}
					style={{
						padding: '1rem 10px',
						textAlign: 'right',
						display: 'flex',
						justifyContent: 'right',
						alignContent: 'center',
						alignItems: 'center',
						gap: '1rem',
					}}
				>
					<Button
						startIcon={<Add />}
						onClick={() => {
							api
								.post('/service/createnew', {email: authContext.state.email})
								.then(response => {
									history.push('/services/' + response.data._id);
								})
								.catch(error => console.error(error));
						}}
						size="large"
						variant="contained"
						style={{
							background: '#4cae52',
							textTransform: 'none',
							fontFamily: 'Titillium Web',
						}}
					>
						Skapa ny tjänst
					</Button>
				</Grid>
			)}
			<Grid item xs={12} sx={{padding: '0 1.5rem'}}>
				{loading ? (
					<LoadingComponent loadingText={t('loading.loadingServices')} />
				) : (
					<Grid container spacing={2}>
						{data.map(item => (
							<Grid
								item
								xs={12}
								sm={6}
								key={item.id}
								style={{
									padding: '1rem 10px',
									borderRadius: '10px',
								}}
							>
								<Link to={'/tjänster/' + item.service.url}>
									<h2>
										{language === 'sv' ? item.service.swedishTitle : item.service.englishTitle}
									</h2>
								</Link>

								<p>
									{language === 'en'
										? item.service.englishText !== null &&
										  item.service.englishText?.length > 0 &&
										  parse(item.service.englishText)
										: item.service.swedishText !== null &&
										  item.service.swedishText?.length > 0 &&
										  parse(item.service.swedishText)}
								</p>

								<h4 style={{margin: 0, padding: 0}}>{item.contact}</h4>

								{item.contactPersons.length > 0 && (
									<React.Fragment>
										<a
											href={'/kontakt/' + item.contactPersons[0].email}
											style={{margin: '0.7rem 0', padding: 0, cursor: 'pointer'}}
											className="hover-link"
										>
											{item.contactPersons[0].fullName}
										</a>

										<p style={{lineHeight: '5px'}}>
											<strong>{t('contactpage.phone')}</strong>
										</p>
										<p style={{lineHeight: '3px'}}>
											<a href={item.contactPersons[0].tel}>{item.contactPersons[0].displayPhone}</a>
										</p>
										<p style={{lineHeight: '5px'}}>
											<strong>{t('contactpage.email')}</strong>
										</p>
										<p style={{lineHeight: '3px'}}>
											<a href={'mailto:' + item.contactPersons[0].email}>
												{item.contactPersons[0].email}
											</a>
										</p>
									</React.Fragment>
								)}

								{authContext.state.isAuthenticated ? (
									<CardActions
										sx={{
											display: 'flex',
											padding: {xs: '5px', md: '10px'},
											flexWrap: 'wrap',
											gap: {xs: '10px', md: '1rem'},
											justifyContent: 'space-between',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: {xs: '10px', md: '1rem'},
												justifyContent: 'space-between',
											}}
										>
											<Chip
												label={
													item.service.showOnStartPage
														? 'Visas på startsida'
														: 'Visas ej på startsida'
												}
												style={{
													color: item.service.showOnStartPage ? '#ffffff' : '#4cae52',
													background: item.service.showOnStartPage ? '#4cae52' : '#ffffff',
												}}
											/>
											<Chip
												label={item.service.published ? 'Publicerad' : 'Ej publicerad'}
												style={{
													color: item.service.published ? '#ffffff' : '#4cae52',
													background: item.service.published ? '#4cae52' : '#ffffff',
												}}
											/>
										</Box>
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: {xs: '10px', md: '1rem'},
												justifyContent: 'space-between',
											}}
										>
											<Button
												variant="contained"
												style={{
													textTransform: 'none',
													background: '#4cae52',
													color: 'white',
												}}
												onClick={() => history.push('/tjänster/' + item.service.url)}
											>
												Läs mer
											</Button>
											{(isAdmin(authContext.state.email) ||
												(!item.published && authContext.state.email === item.createdBy)) && (
												<Button
													variant="contained"
													startIcon={<Edit />}
													color="primary"
													style={{
														textTransform: 'none',
														fontFamily: 'Titillium Web',
													}}
													onClick={() => {
														history.push('/services/' + item.service._id);
													}}
												>
													Redigera
												</Button>
											)}
										</Box>
									</CardActions>
								) : (
									<Box sx={{textAlign: 'right', paddingRight: '1rem'}}>
										<Button
											variant="contained"
											size="large"
											sx={{
												textTransform: 'none',
												background: '#4cae52',
												color: 'white',
											}}
											onClick={() => history.push('/tjänster/' + item.service.url)}
										>
											{language === 'sv' ? 'Läs mer' : 'Read more'}
										</Button>
									</Box>
								)}
							</Grid>
						))}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}

import {Button, Divider, FormControl, Grid, TextField} from '@mui/material';
import React, {useContext} from 'react';
import {Check, Edit} from '@mui/icons-material';
import {AuthContext} from '../App';
import {isAdmin} from '../utils/Authentication';

const PageIntro = ({data, setData, editing, isEditing, savePage, english = true}) => {
	const language = localStorage.getItem('i18nextLng');
	const authContext = useContext(AuthContext);

	if (editing) {
		return (
			<React.Fragment>
				<Divider sx={{width: '100%', color: 'gray', margin: '1rem auto 0'}} />
				<Grid container spacing={2}>
					<Grid item xs={12} md={english ? 6 : 12}>
						<FormControl margin="dense" fullWidth>
							<TextField
								multiline
								helperText="Ingress (sv)"
								value={data.subtitleSwedish}
								onChange={event => {
									setData({...data, subtitleSwedish: event.target.value});
								}}
							/>
						</FormControl>
					</Grid>
					{english && (
						<Grid item xs={12} md={6}>
							<FormControl margin="dense" fullWidth>
								<TextField
									multiline
									helperText="Ingress (en)"
									value={data.subtitleEnglish}
									onChange={event => {
										setData({...data, subtitleEnglish: event.target.value});
									}}
								/>
							</FormControl>
						</Grid>
					)}

					<Grid item xs={12} sx={{textAlign: 'right'}}>
						<Button
							sx={{textTransform: 'none'}}
							variant="outlined"
							onClick={() => {
								savePage();
								isEditing(false);
							}}
							startIcon={<Check />}
						>
							Spara
						</Button>
					</Grid>
				</Grid>{' '}
				<Divider sx={{width: '100%', color: 'gray', margin: '0.5rem auto 0'}} />
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<Divider sx={{width: '100%', color: 'gray', margin: '1rem auto 0'}} />
				<Grid
					item
					xs={12}
					md={isAdmin(authContext.state.email) ? 11 : 12}
					sx={{
						padding: {xs: '0 10px'},
						md: '0 10px',
					}}
				>
					<p style={{fontSize: '1.4rem'}}>
						{language === 'sv' ? data?.subtitleSwedish : data?.subtitleEnglish}
					</p>
				</Grid>
				{isAdmin(authContext.state.email) && (
					<Grid item xs={12} md={1} sx={{marginTop: '1rem', textAlign: 'right'}}>
						<Button
							size="small"
							sx={{textTransform: 'none', right: 0}}
							onClick={() => isEditing(true)}
						>
							<Edit />
						</Button>
					</Grid>
				)}

				<Divider sx={{width: '100%', color: 'gray', margin: '0.5rem auto 0'}} />
			</React.Fragment>
		);
	}
};
export default PageIntro;

import React, {useContext, useEffect} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CircularProgress,
	Divider,
	Grid,
	Tooltip,
	Typography,
} from '@mui/material';
import {useState} from 'react';
import api from '../../api';
import {Check, Clear, Delete, Edit, GetApp, Print} from '@mui/icons-material';
import {Link, useHistory} from 'react-router-dom';
import {AuthContext} from '../../App';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';
import ics from '../../assets/ics.png';
import vcf from '../../assets/vcf.png';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import Slider from 'react-slick';
import {isAdmin} from '../../utils/Authentication';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import parse from 'html-react-parser';
const ViewServicePage = props => {
	const language = localStorage.getItem('i18nextLng');
	const history = useHistory();
	const {t} = useTranslation();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loadingPdf, setLoadingPdf] = useState(false);
	const [loadingPrint, setLoadingPrint] = useState(false);
	const [contactPersons, setContactPersons] = useState([]);
	const [contactThatIsBeingExported, setContactThatIsBeingExported] = useState(false);
	const [loading, isLoading] = useState(true);
	const settings = {
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		arrows: false,
		autoplay: true,
		slidesToScroll: 1,
	};
	const authContext = useContext(AuthContext);
	const [data, setData] = useState({});
	useEffect(() => {
		getData();
	}, []);
	const getData = () => {
		api
			.get('/service/getitembyurl/' + props.match.params.url)
			.then(response => {
				setData(response.data.item);
				setContactPersons(response.data.contactPersons);
				document.title = response.data.item.swedishTitle;

				isLoading(false);
			})
			.catch(error => console.error(error));
	};

	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				maxWidth: '1300px',
				minHeight: '50vh',
				padding: {xs: '4rem 1rem 0', sm: '8rem 1rem 0', md: '8rem 1rem 0', xl: '8rem 1rem 0'},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: {xs: '1rem', md: '3rem'},
					flexWrap: 'wrap',
				}}
			>
				<BreadCrumb
					mainLinkUrl={'/tjänster'}
					mainLinkWord={t('topbar.services')}
					title={language === 'sv' ? data.swedishTitle : data.englishTitle}
				/>

				{(isAdmin(authContext.state.email) ||
					(!data.published && authContext.state.email === data.createdBy)) && (
					<div>
						<Grid container>
							<Grid
								item
								xs={12}
								style={{
									padding: '0',
									display: 'flex',
									justifyContent: 'space-between',
									gap: '1rem',
								}}
							>
								<Button
									startIcon={<Edit />}
									onClick={() => history.push('/services/' + data._id)}
									variant="contained"
									style={{
										background: '#4cae52',
										textTransform: 'none',
										fontFamily: 'Titillium Web',
									}}
								>
									Redigera
								</Button>
							</Grid>
						</Grid>
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
			</Grid>
			<Grid item xs={12} md={6} style={{paddingBottom: '1rem'}}>
				<Slider {...settings}>
					<div>
						<img
							src={data.pictureUrl}
							style={{maxWidth: '90%', cursor: 'pointer'}}
							alt={data.title}
							onClick={() => window.open(data.pictureUrl, '_blank')}
						/>{' '}
						<p>
							{language === 'sv' ? data.pictureDescriptionSwedish : data.pictureDescriptionEnglish}
						</p>
					</div>
					{data?.pictures?.length > 0 &&
						data?.pictures?.map(p => (
							<div key={p._id}>
								<img
									src={p.url}
									style={{maxWidth: '90%', cursor: 'pointer'}}
									alt={data.title}
									onClick={() => window.open(data.url, '_blank')}
								/>
								<div style={{padding: '10px 0'}}>
									<Typography variant="body1">
										{language === 'sv' ? p.swedishDescription : p.englishDescription}
									</Typography>
								</div>
							</div>
						))}
				</Slider>
			</Grid>

			<Grid item xs={12} md={6} style={{padding: '10px'}}>
				{language === 'sv' ? (
					<div style={{margin: 0, padding: 0}}>
						<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>{data.date}</h2>
						{data.swedishTitle !== '' && data.swedishTitle?.length > 0 && (
							<>
								<h3>{data.swedishTitle}</h3>
								<p style={{fontSize: '1.1rem'}}>{data.swedishSubtitle}</p>
							</>
						)}
						{data.swedishText !== null && data.swedishText?.length > 0 && parse(data.swedishText)}
					</div>
				) : (
					<div style={{margin: 0, padding: 0}}>
						<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>{data.date}</h2>
						{data.englishTitle !== '' && data.englishTitle?.length > 0 && (
							<>
								<h3>{data.englishTitle}</h3>
								<p style={{fontSize: '1.1rem'}}>{data.englishSubtitle}</p>
							</>
						)}
						{data.englishText !== null && data.englishText?.length > 0 && parse(data.englishText)}
					</div>
				)}
			</Grid>
			{contactPersons.length > 0 && (
				<Grid item xs={12} md={12} xl={12} style={{padding: '1rem 10px'}}>
					<h3>Kontaktpersoner</h3>
					{loading ? (
						<CircularProgress />
					) : (
						<Grid container spacing={2}>
							{contactPersons.map((c, index) => (
								<Grid item xs={12} sm={6} md={3} xl={3} key={c._id} id={c._id}>
									<Card>
										<CardMedia component="img" image={c.pictureUrl} alt="Paella dish"></CardMedia>
										<CardContent style={{fontSize: '0.9rem'}}>
											<p style={{lineHeight: '3px', color: '#3EAC48', fontSize: '1.2rem'}}>
												<strong>{c.fullName}</strong>
											</p>
											<p>{language === 'sv' ? c.swedishRole : c.englishRole}</p>
											<p>{language === 'sv' ? c.swedishDescription : c.englishDescription}</p>
											<p>
												<strong>{t('contactpage.phone')}</strong>
											</p>
											<p>
												<a href={c.tel}>{c.displayPhone}</a>
											</p>
											<p>
												<strong>{t('contactpage.email')}</strong>
											</p>
											<p>
												<a href={'mailto:' + c.email}>{c.email.replace('@', '[a]')}</a>
											</p>
										</CardContent>
										<CardActions></CardActions>
									</Card>
								</Grid>
							))}
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	);
};
export default ViewServicePage;

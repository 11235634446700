import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_lang from './en.json';
import sv_lang from './sv.json';
const resources = {
	en: {
		translation: en_lang,
	},
	sv: {
		translation: sv_lang,
	},
};
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		keySeparator: '.',
		resources,
	});

export default i18n;

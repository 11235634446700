import {
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from '@mui/material';
import {CameraAlt, Visibility, VisibilityOff} from '@mui/icons-material';
import api from '../../api';
import {LoadingButton} from '@mui/lab';
import React, {useState, useEffect, useRef, useContext} from 'react';
import {isAdmin} from '../../utils/Authentication';
import {AuthContext} from '../../App';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PhotoUploader from './PhotoUploader';

const EditContact = ({
	creatingNew,
	editing,
	setEditing,
	setCreatingNew,
	selected,
	updateTable,
	getContacts,
}) => {
	const [data, setData] = useState({});
	const [loading, isLoading] = useState(true);
	const [loadingNew, setLoadingNew] = useState(false);
	const inputRef = useRef();
	const [showPassword, setShowPassword] = useState(false);
	const authContext = useContext(AuthContext);
	const [systemRole, setSystemRole] = useState('viewer');
	const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 300 / 355 });
	const [completedCrop, setCompletedCrop] = useState(null);
	const [showCropDialog, setShowCropDialog] = useState(false);
	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		api
			.get('/person/person/' + selected._id)
			.then(response => {
				setData(response.data);
				setSystemRole(response.data.systemRole);
				isLoading(false);
			})
			.catch(error => {
				console.error(error);
				isLoading(false);
			});
	};
	const create = () => {
		api
			.post('/person/person', data)
			.then(response => {
				setLoadingNew(false);
				setCreatingNew(false);
				getContacts();
			})
			.catch(error => console.error(error));
	};
	const handleChange = event => {
		if (event.target.value !== null) {
			setData({...data, [event.target.name]: event.target.value});
		}
	};
	const update = () => {
		api
			.put('/person/person/' + data._id, data)
			.then(() => {
				setEditing(false);
				setLoadingNew(false);
				if (updateTable) {
					getContacts();
				}
			})
			.catch(error => console.error(error));
	};
	return (
		<Dialog
			open={creatingNew || editing}
			onClose={() => {
				setEditing(false);
				getContacts()
			}}
			maxWidth="xl"
		>
			{loading ? (
				<LoadingComponent loadingText={''} />
			) : (
				<React.Fragment>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<h3>{editing ? 'Redigera profil' : 'Skapa ny profil'}</h3>
									</Grid>
									<Grid item xs={12} style={{textAlign: 'center'}}>
										<PhotoUploader data={data} setData={setData} personId={data._id} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={8}>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Förnamn"
												type="text"
												size="small"
												onChange={handleChange}
												value={data.firstName}
												name="firstName"
												id="firstName"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Efternamn"
												type="text"
												size="small"
												onChange={handleChange}
												value={data.lastName}
												name="lastName"
												id="lastName"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Epost"
												type="text"
												size="small"
												onChange={handleChange}
												value={data.email}
												name="email"
												id="email"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Lösenord"
												type={showPassword ? 'text' : 'password'}
												size="small"
												onChange={handleChange}
												value={data.password}
												name="password"
												id="password"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															{showPassword ? (
																<Tooltip title="´Dölj">
																	<VisibilityOff
																		onClick={() => setShowPassword(false)}
																		style={{cursor: 'pointer'}}
																	/>
																</Tooltip>
															) : (
																<Tooltip title="Visa lösenord">
																	<Visibility
																		onClick={() => setShowPassword(true)}
																		style={{cursor: 'pointer'}}
																	/>
																</Tooltip>
															)}
														</InputAdornment>
													),
												}}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Titel (sv)"
												type="text"
												size="small"
												onChange={handleChange}
												value={data.swedishRole}
												name="swedishRole"
												id="swedishRole"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Titel (en)"
												type="text"
												size="small"
												onChange={handleChange}
												value={data.englishRole}
												name="englishRole"
												id="englishRole"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Presentation (sv)"
												type="text"
												size="small"
												multiline
												minRows={3}
												onChange={handleChange}
												value={data.swedishText}
												name="swedishText"
												id="swedishText"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Presentation (en)"
												type="text"
												size="small"
												multiline
												minRows={3}
												onChange={handleChange}
												value={data.englishText}
												name="englishText"
												id="englishText"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Telefon (visningsformat)"
												type="text"
												size="small"
												helperText="Exempel: 0737 1337 97 - Detta är telefonnumret som visas upp på hemsidan"
												onChange={handleChange}
												value={data.displayPhone}
												name="displayPhone"
												id="displayPhone"
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl margin="dense" fullWidth>
											<TextField
												placeholder="Telefon (maskinvänligt format)"
												type="text"
												size="small"
												helperText="Exempel: 0046737133797 - Telefonnummer i detta format behövs för att Visitkort och Ring direkt-knappen ska fungera"
												onChange={handleChange}
												value={data.phone}
												name="phone"
												id="phone"
											/>
										</FormControl>
									</Grid>
									{isAdmin(authContext.state.email) && (
										<Grid item xs={12}>
											<FormControl margin="dense" fullWidth>
												<TextField
													defaultValue={''}
													label="Behörighet"
													onChange={event => {
														setSystemRole(event.target.value);
														setData({...data, systemRole: event.target.value});
													}}
													select
													size="small"
													value={systemRole}
													variant="outlined"
												>
													<MenuItem value="admin">Administratör</MenuItem>
													<MenuItem value="viewer">Vanlig användare</MenuItem>
												</TextField>
											</FormControl>
											{data.systemRole}
										</Grid>
									)}
								</Grid>
							</Grid>

						</Grid>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							loading={loadingNew}
							color="primary"
							size="large"
							variant="contained"
							style={{textTransform: 'none'}}
							onClick={() => {
								setLoadingNew(true);
								if (editing) {
									update();
								} else {
									create();
								}
							}}
						>
							Spara
						</LoadingButton>
					</DialogActions>
				</React.Fragment>
			)}
		</Dialog>
	);
};
export default EditContact;

import React, {useContext, useEffect} from 'react';
import {Grid} from '@mui/material';
import {useState} from 'react';
import api from '../../api';
import {Link, useHistory} from 'react-router-dom';
import {AuthContext} from '../../App';
import PageHeader from '../../layout/PageHeader';
import headerImg from '../../assets/concrete_corner.jpeg';
import {useTranslation} from 'react-i18next';
import parse from 'html-react-parser';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';

const ViewJobDetailPage = props => {
	const {t} = useTranslation();

	const [data, setData] = useState({
		_id: '',
		title: '',
		subtitle: '',
		quote: '',
		text: '',
	});
	useEffect(() => {
		getJob();
	}, []);
	const getJob = () => {
		api
			.get('/job/job/' + props.match.params.job_id)
			.then(response => {
				setData(response.data);
				document.title = response.data.title;
			})
			.catch(error => console.error(error));
	};

	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				maxWidth: '1300px',
				minHeight: '50vh',
				padding: {xs: '4rem 1rem 0', sm: '8rem 1rem 0', md: '8rem 1rem 0', xl: '8rem 1rem 0'},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: {xs: '1rem', md: '1rem', xl: '1rem'},
				}}
			>
				<BreadCrumb mainLinkUrl={'/karriär'} mainLinkWord={t('topbar.career')} title={data.title} />
			</Grid>
			<Grid item xs={12} md={7} style={{padding: '1rem 1rem 0 0'}}>
				<h2>{data.title}</h2>
				{data.text !== null && data.text?.length > 0 && parse(data.text)}
			</Grid>
		</Grid>
	);
};
export default ViewJobDetailPage;

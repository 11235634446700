import axios from 'axios';

export const getBaseUrl = () => {
	if (window.location.host === 'web.redmanagement.se') {
		return 'https://api.web.redmanagement.se';
	} else if (window.location.host === 'redmanagement-dcqmkcxssq-ez.a.run.app') {
		return 'https://redmanagement-api-dcqmkcxssq-ez.a.run.app';
	} else if (window.location.host === 'redmanagement.se') {
		return 'https://api.web.redmanagement.se';
	} else if (window.location.host === 'www.redmanagement.se') {
		return 'https://api.web.redmanagement.se';
	} else if (window.location.host === 'localhost:3000') {
		return 'http://localhost:8000';
	}
};

const axiosClient = axios.create({
	baseURL: getBaseUrl(),
	'Content-Encoding': 'gzip',
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosClient.interceptors.request.use(
	config => configure(config),
	error => Promise.reject(error)
);

const configure = config => {
	const tid = JSON.parse(localStorage.getItem('tid') || null);

	if (tid != null) {
		config.headers.Authorization = tid;
	}
	if (config.method === 'get') {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
	}
	return config;
};

export default axiosClient;
